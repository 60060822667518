import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';
import { DocumentType } from './document-type';

export class ReportProtocolDocumentLink {
    constructor(
        public url: string,
        public fileName: string,
        public protocolId: string,
        public documentType: DocumentType
    ) { }
}

@Injectable()
export class ReportProtocolDocumentLinkAdapter implements Adapter<ReportProtocolDocumentLink>{
    adapt(item: any): ReportProtocolDocumentLink {
        return new ReportProtocolDocumentLink(
            item.url,
            item.fileName,
            item.protocolId,
            item.documentType
        );
    }
}