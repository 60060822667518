import { Injectable } from "@angular/core";
import { Adapter } from "./interfaces/adapter.interface";

export class ProtocolRecommendationElement {
    constructor(
        public id: string,
        public recommendation: string,
        public priorityNumber: number
    ) {

    }
}

@Injectable()
export class ProtocolRecommendationElementAdapter implements Adapter<ProtocolRecommendationElement>{
    adapt(item: any): ProtocolRecommendationElement {
        return new ProtocolRecommendationElement(
            item.id,
            item.recommendation,
            item.priorityNumber
        );
    }    
}