import { WorkObjectAdministrator, WorkObjectAdministratorAdapter } from './work-object-administrator';
import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { PreviousPostControlRecommendation, PreviousPostControlRecommendationAdapter } from './previous-post-control-recommendation';

export class WorkObjectProtocolDetails {
    constructor(
        public caseId: string,
        public sapId: string,
        public inventoryId: string,
        public jnDesignation: string,
        public previousHalfYearControlDate: Date,
        public previousAnnualControlDate: Date,
        public previousQuinquennialControlDate: Date,
        public postControlRecommendations: string,
        public administrators: WorkObjectAdministrator[],
        public previousPostControlRecommendations: PreviousPostControlRecommendation[],
        public additionalNotes: string,
        public mainPhoto: string,
        public canAddPreviousPostControlRecommendations: boolean,
        public remainingTime?: number
    ) { }
}

@Injectable()
export class WorkProtocolDetailAdapter implements Adapter<WorkObjectProtocolDetails>{
    constructor(private workObjectAdministratorAdapter: WorkObjectAdministratorAdapter,
        private previousPostControlRecommendationAdapter: PreviousPostControlRecommendationAdapter) { }

    adapt(item: any): WorkObjectProtocolDetails {
        var administrators: WorkObjectAdministrator[] = [];
        var recommendations: PreviousPostControlRecommendation[] = [];

        if (item.administrators) {
            item.administrators.forEach(administrator => {
                administrators.push(this.workObjectAdministratorAdapter.adapt(administrator));
            });
        }

        if (item.previousPostControlRecommendations) {
            item.previousPostControlRecommendations.forEach(recommendation => {
                recommendations.push(this.previousPostControlRecommendationAdapter.adapt(recommendation));
            });
        }

        return new WorkObjectProtocolDetails(
            item.caseId,
            item.sapId,
            item.inventoryId,
            item.jnDesignation,
            item.previousHalfYearControlDate,
            item.previousAnnualControlDate,
            item.previousQuinquennialControlDate,
            item.postControlRecommendations,
            administrators,
            recommendations,
            item.additionalNotes,
            item.mainPhoto,
            item.canAddPreviousPostControlRecommendations,
            item.remainingTime ? item.remainingTime : null
        );
    }
}