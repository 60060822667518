import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { EngineerSignature, EngineerSignatureAdapter } from 'src/app/models/engineer-signature';
import { ProtocolSignature, ProtocolSignatureAdapter } from 'src/app/models/protocol-signature';
import { BookStatusRating, BookStatusRatingAdapter } from '../models/book-status-rating';
import { BuildingSafetyRating, BuildingSafetyRatingAdapter } from '../models/building-safety-rating';
import { BuildingStateRating, BuildingStateRatingAdapter } from '../models/building-state-rating';
import { DeletePhotos } from '../models/delete-photos';
import { DocumentationStatusRating, DocumentationStatusRatingAdapter } from '../models/documentation-status-rating';
import { DocumentsForProtocol, DocumentsForProtocolAdapter } from '../models/documents-for-protocol';
import { ElementStateRating, ElementStateRatingAdapter } from '../models/element-state-rating';
import { FullAnnualProtocol, FullAnnualProtocolAdapter } from '../models/full-annual-protocol';
import { FullChimneyProtocol, FullChimneyProtocolAdapter } from '../models/full-chimney-protocol';
import { FullDocumentationControlProtocolAdapter } from '../models/full-documentation-control-protocol';
import { FullGasProtocol, FullGasProtocolAdapter } from '../models/full-gas-protocol';
import { FullHalfYearProtocol, FullHalfYearProtocolAdapter } from '../models/full-halfyear-protocol';
import { FullMskAnnualProtocolAdapter } from '../models/full-msk-annual-protocol';
import { FullMskHalfYearProtocolAdapter } from '../models/full-msk-halfyear-protocol';
import { FullMskQuinquennialProtocolAdapter } from '../models/full-msk-quinquennial-protocol';
import { FullQuinquennialProtocol, FullQuinquennialProtocolAdapter } from '../models/full-quinquennial-protocol';
import { GetReportProtocolDocumentLink } from '../models/get-report-protocol-document-link';
import { MainPhoto } from '../models/main-photo';
import { ManagerChangeStatus } from '../models/manager-change-status';
import { PostControlRecommendationPriority, PostControlRecommendationPriorityAdapter } from '../models/post-control-recommendation-priority';
import { ProtocolBuildingElementAdapter } from '../models/protocol-building-element';
import { ProtocolForSpecificTask, ProtocolForSpecificTaskAdapter } from '../models/protocol-for-specific-task';
import { ProtocolListItem, ProtocolListItemAdapter } from '../models/protocol-list-item';
import { ProtocolSignatureWithCaseIds } from "../models/protocol-signature-with-case-ids";
import { ProtocolTemplate } from '../models/protocol-template';
import { ProtocolTemplateSimple, ProtocolTemplateSimpleAdapter } from '../models/protocol-template-simple';
import { ProtocolTypeListItem, ProtocolTypeListItemAdapter } from '../models/protocol-type-list-item';
import { RemoveProtocol } from '../models/remove-protocol';
import { ReportDetailsAdapter } from '../models/report-details';
import { ReportProtocolDocumentAdapter } from '../models/report-protocol-document';
import { ReportProtocolDocumentLinkAdapter } from '../models/report-protocol-document-link';
import { SuitabilityForExploitationRating, SuitabilityForExploitationRatingAdapter } from '../models/suitability-for-exploitation-rating';
import { TaskFromProtocolDashboard, TaskFromProtocolDashboardAdapter } from '../models/task-from-protocol-dashboard';
import { WorkObjectPhoto, WorkObjectPhotoAdapter } from '../models/work-object-photo';
import { WorkObjectPhotoExtra, WorkObjectPhotoExtraAdapter } from '../models/work-object-photo-extra';

@Injectable()
export class ProtocolsService {
    constructor(
        private http: HttpClient,
        private protocolTypeListItemAdapter: ProtocolTypeListItemAdapter,
        private buildingStateRatingAdapter: BuildingStateRatingAdapter,
        private suitabilityForExploitationRatingAdapter: SuitabilityForExploitationRatingAdapter,
        private bookStatusRatingAdapter: BookStatusRatingAdapter,
        private documentationStatusRatingAdapter: DocumentationStatusRatingAdapter,
        private buildingSafetyRatingAdapter: BuildingSafetyRatingAdapter,
        private elementStateRatingAdapter: ElementStateRatingAdapter,
        private postControlRecommendationPriority: PostControlRecommendationPriorityAdapter,
        private workObjectPhotoAdapter: WorkObjectPhotoAdapter,
        private workObjectPhotoExtraAdapter: WorkObjectPhotoExtraAdapter,
        private protocolListItemAdapter: ProtocolListItemAdapter,
        private fullAnnualProtocolAdapter: FullAnnualProtocolAdapter,
        private fullMskAnnualProtocolAdapter: FullMskAnnualProtocolAdapter,
        private fullQuinquennialProtocolAdapter: FullQuinquennialProtocolAdapter,
        private fullChimneyProtocolAdapter: FullChimneyProtocolAdapter,
        private reportDetailsAdapter: ReportDetailsAdapter,
        private reportProtocolDocumentAdapter: ReportProtocolDocumentAdapter,
        private protocolBuildingElementAdapter: ProtocolBuildingElementAdapter,
        private fullMskHalfYearProtocolAdapter: FullMskHalfYearProtocolAdapter,
        private fullHalfYearProtocolAdapter: FullHalfYearProtocolAdapter,
        private fullGasProtocolAdapter: FullGasProtocolAdapter,
        private fullDocumentationControlProtocolAdapter: FullDocumentationControlProtocolAdapter,
        private fullMskQuinquennialProtocolAdapter: FullMskQuinquennialProtocolAdapter,
        private protocolTemplateSimpleAdapter: ProtocolTemplateSimpleAdapter,
        private taskFromProtocolDashboardAdapter: TaskFromProtocolDashboardAdapter,
        private protocolForSpecificTaskAdapter: ProtocolForSpecificTaskAdapter,
        private documentsForProtocolAdapter: DocumentsForProtocolAdapter,
        private engineerSignatureAdapter: EngineerSignatureAdapter,
        private protocolSignatureAdapter: ProtocolSignatureAdapter,
        private reportProtocolDocumentLinkAdapter: ReportProtocolDocumentLinkAdapter
    ) { }

    public prepareProtocolTypes(data: any)  {
        if (!data || !data.protocolTypes)
            return null;

        var protocolTypes: ProtocolTypeListItem[] = [];

        data.protocolTypes.forEach(protocolType => {
            protocolTypes.push(this.protocolTypeListItemAdapter.adapt(protocolType));
        });
        return protocolTypes; 
    }

    public prepareBuildingStateRatings(data: any)   {
        if (!data && !data.buildingStateRatings)
            return null;

        var dictionary: BuildingStateRating[] = [];

        data.buildingStateRatings.forEach(rating => {
            dictionary.push(this.buildingStateRatingAdapter.adapt(rating));
        });
        return dictionary;
    }

    public prepareSuitabilityForExploitationRatings(data: any) {
        if (!data && !data.suitabilityForExploitationRatings)
            return null;

        var dictionary: SuitabilityForExploitationRating[] = [];

        data.suitabilityForExploitationRatings.forEach(rating => {
            dictionary.push(this.suitabilityForExploitationRatingAdapter.adapt(rating));
        });
        return dictionary;
    }

    public prepareDocumentationStatusRatings(data: any){
        if (!data && !data.documentationStatusRatings)
            return null;

        var dictionary: DocumentationStatusRating[] = [];

        data.documentationStatusRatings.forEach(rating => {
            dictionary.push(this.documentationStatusRatingAdapter.adapt(rating));
        });
        return dictionary;
    }

    public prepareBookStatusRatings(data: any){
        if (!data && !data.bookStatusRatings)
            return null;

        var dictionary: BookStatusRating[] = [];

        data.bookStatusRatings.forEach(rating => {
            dictionary.push(this.bookStatusRatingAdapter.adapt(rating));
        });
        return dictionary;
    }

    public prepareBuildingSafetyRatings(data: any){
        if (!data && !data.buildingSafetyRatings)
            return null;

        var dictionary: BuildingSafetyRating[] = [];

        data.buildingSafetyRatings.forEach(rating => {
            dictionary.push(this.buildingSafetyRatingAdapter.adapt(rating));
        });
        return dictionary;
    }

    public prepareElementStateRatings(data: any){
        if (!data && !data.elementStateRatings)
            return null;

        var dictionary: ElementStateRating[] = [];

        data.elementStateRatings.forEach(rating => {
            dictionary.push(this.elementStateRatingAdapter.adapt(rating));
        });
        return dictionary;
    }

    public preparePostControlRecommendationPriorities(data: any) {
        if (!data && !data.postControlRecommendationPriorities)
            return null;

        var dictionary: PostControlRecommendationPriority[] = [];

        data.postControlRecommendationPriorities.forEach(priority => {
            dictionary.push(this.postControlRecommendationPriority.adapt(priority));
        });
        return dictionary;
    }

    public getProtocolChimney(caseId: string, protocolId: string) {
        return this.http.get<any>(`/api/protocols/GetNewProtocolChimney/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullChimneyProtocolAdapter.adapt(data);
            }));
    }

    public getProtocolQuinquennial(caseId: string, protocolId: string) {
        return this.http.get<any>(`/api/protocols/GetNewProtocolQuinquennial/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullQuinquennialProtocolAdapter.adapt(data);
            }));
    }

    public getProtocolAnnual(caseId: string, protocolId: string) {
        return this.http.get<any>(`/api/protocols/GetNewProtocolAnnual/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullAnnualProtocolAdapter.adapt(data);
            }));
    }

    public getNewMskProtocolAnnual(caseId: string, protocolId: string) {
        if (!caseId) {
            caseId = '_';
        }
        return this.http.get<any>(`/api/protocols/GetNewProtocolAnnual/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullMskAnnualProtocolAdapter.adapt(data);
            }));
    }

    public getNewMskProtocolHalfYear(caseId: string, protocolId: string) {
        if (!caseId) {
            caseId = '_';
        }
        return this.http.get<any>(`/api/protocols/GetNewProtocolHalfYear/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullMskHalfYearProtocolAdapter.adapt(data);
            }));
    }

    public getNewProtocolHalfYear(caseId: string, protocolId: string) {
        if (!caseId) {
            caseId = '_';
        }
        return this.http.get<any>(`/api/protocols/GetNewProtocolHalfYear/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullHalfYearProtocolAdapter.adapt(data);
            }));
    }

    public getNewMskProtocolQuinquennial(caseId: string, protocolId: string) {
        if (!caseId) {
            caseId = '_';
        }
        return this.http.get<any>(`/api/protocols/GetNewProtocolQuinquennial/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullMskQuinquennialProtocolAdapter.adapt(data);
            }));
    }

    public getNewProtocolGas(caseId: string, protocolId: string) {
        return this.http.get<any>(`/api/protocols/GetNewProtocolGas/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullGasProtocolAdapter.adapt(data);
            }));
    }

    public getNewProtocolDocumentationControl(caseId: string, protocolId: string) {
        return this.http.get<any>(`/api/protocols/GetNewProtocolDocumentationControl/${caseId}/${protocolId}`)
            .pipe(map(data => {
                if (!data)
                    return null;

                return this.fullDocumentationControlProtocolAdapter.adapt(data);
            }));
    }

    public uploadPhotos(formData: FormData) {
        return this.http.post<any>('/api/protocols/UploadFile', formData)
            .pipe(map(data => {
                return data;
            }));
    }

    public setMainPhotoForWorkObject(mainPhoto: MainPhoto) {
        return this.http.post<any>('/api/protocols/SetMainPhotoForWorkObject', mainPhoto)
            .pipe(map(data => {
                return data;
            }));
    }

    public deletePhotosFromWorkObject(deletePhotos: DeletePhotos) {
        return this.http.post<any>('/api/protocols/DeletePhotosFromWorkObject', deletePhotos)
            .pipe(map(data => {
                return data; 
            }));
    }

    public saveAnnualProtocolWorkingCopy(newAnnualProtocol: FullAnnualProtocol) {
        return this.http.post<any>('/api/protocols/SaveAnnualProtocolWorkingCopy', newAnnualProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveAnnualProtocolAndSend(newAnnualProtocol: FullAnnualProtocol) {
        return this.http.post<any>('/api/protocols/SaveAnnualProtocolAndSend', newAnnualProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveHalfYearProtocolWorkingCopy(newHalfYearProtocol: FullHalfYearProtocol) {
        return this.http.post<any>('/api/protocols/SaveHalfYearProtocolWorkingCopy', newHalfYearProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveHalfYearProtocolAndSend(newHalfYearProtocol: FullHalfYearProtocol) {
        return this.http.post<any>('/api/protocols/SaveHalfYearProtocolAndSend', newHalfYearProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveQuinquennialProtocolWorkingCopy(newQuinquennialProtocol: FullQuinquennialProtocol) {
        return this.http.post<any>('/api/protocols/SaveQuinquennialProtocolWorkingCopy', newQuinquennialProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveQuinquennialProtocolAndSend(newQuinquennialProtocol: FullQuinquennialProtocol) {
        return this.http.post<any>('/api/protocols/SaveQuinquennialProtocolAndSend', newQuinquennialProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveChimneyProtocolWorkingCopy(newChimneyProtocol: FullChimneyProtocol) {
        return this.http.post<any>('/api/protocols/SaveChimneyProtocolWorkingCopy', newChimneyProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveChimneyProtocolAndSend(newChimneyProtocol: FullChimneyProtocol) {
        return this.http.post<any>('/api/protocols/SaveChimneyProtocolAndSend', newChimneyProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveDocumentationControlWorkingCopy(newDocumentationControl: any) {
        return this.http.post<any>('/api/protocols/SavDocumentationControlWorkingCopy', newDocumentationControl)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveDocumentationControlAndSend(newDocumentationControl: any) {
        return this.http.post<any>('/api/protocols/SavDocumentationControlAndSend', newDocumentationControl)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveGasProtocolWorkingCopy(newGasProtocol: FullGasProtocol) {
        return this.http.post<any>('/api/protocols/SaveGasProtocolWorkingCopy', newGasProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public saveGasProtocolAndSend(newGasProtocol: FullGasProtocol) {
        return this.http.post<any>('/api/protocols/SaveGasProtocolAndSend', newGasProtocol)
            .pipe(map(data => {
                return data;
            }));
    }

    public removeProtocol(protocolId: string, typeNumber: number) {
        return this.http.post<any>('/api/protocols/removeProtocol', new RemoveProtocol(protocolId, typeNumber))
            .pipe(map(data => {
                return data;
            }));
    }

    public managerChangeStatusOfProtocolDashboard(userId: string, managerChangeStatusOfProtocolsDashboard: ManagerChangeStatus[], managerCommentLastDate: Date) {
        var object =
        {
            UserId: userId,
            ManagerChangeStatusOfProtocolsDashboard: managerChangeStatusOfProtocolsDashboard,
            ManagerCommentLastDate: managerCommentLastDate
        }
        return this.http.post<any>('/api/protocols/ChangeProtocolDashboardStatus', object)
            .pipe(map(data => {
                return data;
            }));
    }

    public getManagerCommentByProtocolId(protocolId: string) {
        return this.http.get<any>('/api/protocols/GetProtocolReviewComment/' + protocolId)
            .pipe(map(data => {
                if (!data || data == null)
                    return null;

                return data;
            }));
    }

    public getTasksFromProtocolsDashboard(userId: string) {
        return this.http.get<any>('/api/protocols/GetTasksFromProtocolsDashboard/' + userId)
            .pipe(map(data => {
                if (!data || data == null || data.tasksFromProtocolDashboard == null)
                    return null;

                var dictionary: TaskFromProtocolDashboard[] = [];

                data.tasksFromProtocolDashboard.forEach(task => {
                    dictionary.push(this.taskFromProtocolDashboardAdapter.adapt(task));
                });
                return dictionary;
            }));
    }

    public getProtocolsForSpecificClientTask(userId: string, taskId: string) {
        return this.http.get<any>(`/api/protocols/GetProtocolsForSpecificClientTask/${userId}/${taskId}`)
            .pipe(map(data => {
                if (!data || data == null || data.protocolsForScpecificTask == null)
                    return null;

                var dictionary: ProtocolForSpecificTask[] = [];

                data.protocolsForScpecificTask.forEach(protocol => {
                    dictionary.push(this.protocolForSpecificTaskAdapter.adapt(protocol));
                });
                return dictionary;
            }));
    }

    public getWorkObjectPhotos(caseId: string) {
        return this.http.get<any>('/api/protocols/GetWorkObjectPhotos/' + caseId)
            .pipe(map(data => {
                if (!data || !data.workObjectPhotos)
                    return null;

                var dictionary: WorkObjectPhoto[] = [];

                data.workObjectPhotos.forEach(photo => {
                    dictionary.push(this.workObjectPhotoAdapter.adapt(photo));
                });
                return dictionary;

            }));
    }

    public getObjectPhotos(caseId: string) {
        return this.http.get<any>('/api/protocols/GetObjectPhotos/' + caseId)
            .pipe(map(data => {
                if (!data || !data.workObjectPhotosExtra)
                    return null;

                var dictionary: WorkObjectPhotoExtra[] = [];

                data.workObjectPhotosExtra.forEach(photo => {
                    dictionary.push(this.workObjectPhotoExtraAdapter.adapt(photo));
                });
                return dictionary;

            }));
    }

    public getObjectProtocols(caseId: string) {
        return this.http.get<any>('/api/protocols/GetObjectProtocols/' + caseId)
            .pipe(map(data => {
                if (!data && !data.protocols)
                    return null;

                var dictionary: ProtocolListItem[] = [];

                var lp = 1;
                data.protocols.forEach(protocol => {
                    var protocolItem = this.protocolListItemAdapter.adapt(protocol);
                    protocolItem.Lp = lp;
                    dictionary.push(protocolItem);
                });
                return dictionary;
            }));
    }

    public getDocumentsForProtocols(protocolIds: string[], caseId: string) {
        let params = new HttpParams({
            fromObject: { 'protocolIds': protocolIds }
        })
            .set('caseId', caseId);

        return this.http.get<any>('/api/protocols/GetDocumentsForProtocols', { params: params }).pipe(map(data => {
            if (!data || !data.documentsForProtocols)
                return null;

            var documentsForProtocols: DocumentsForProtocol[] = [];
            for (const documentsForProtocol of data.documentsForProtocols) {
                documentsForProtocols.push(this.documentsForProtocolAdapter.adapt(documentsForProtocol));
            }

            return documentsForProtocols;
        }));
    }

    public getReport(reportId: string) {
        return this.http.get<any>('/api/protocols/GetReportById/' + reportId)
            .pipe(map(data => {
                if (!data || !data.files)
                    return null;

                return this.reportDetailsAdapter.adapt(data);
            }));
    }

    public getReportProtocolDocuments(reportId: string) {
        return this.http.get<any>('/api/protocols/GetReportProtocolDocuments/' + reportId)
            .pipe(map(data => {
                if (data.errorMessage)
                    throw new Error(data.errorMessage)
                if (!data || !data.reportProtocolDocuments)
                    return null;                 

                return data.reportProtocolDocuments.map(file => this.reportProtocolDocumentAdapter.adapt(file));
            }));
    }

    public getReportProtocolDocumentsLinks(getReportDocumentLinks: GetReportProtocolDocumentLink[]) {
        return this.http.post<any>('/api/protocols/GetReportProtocolDocumentsLinks', { GetReportProtocolDocumentLinks: getReportDocumentLinks })
            .pipe(map(data => {
                if (!data || !data.reportProtocolDocumentLinks)
                    return null;

                return data.reportProtocolDocumentLinks.map(url => this.reportProtocolDocumentLinkAdapter.adapt(url));
            }));
    }

    public getProtocolTemplates(workObjectId: string, protocolTypeNumber: number) {
        return this.http.get<any>(`/api/protocolTemplate/GetProtocolTemplates/${workObjectId}/${protocolTypeNumber}`)
            .pipe(map(data => {
                if (!data || !data.protocolTemplates)
                    return null;

                var protocolTemplates: ProtocolTemplateSimple[] = [];
                data.protocolTemplates.forEach(template => {
                    var simple = this.protocolTemplateSimpleAdapter.adapt(template);
                    protocolTemplates.push(simple);
                });
                return protocolTemplates;
            }));
    }

    public getStatusByProtocolId(protocolId: string, protocolType: number) {
        return this.http.get<any>(`/api/protocols/GetStatusByProtocolId/${protocolId}/${protocolType}`)
            .pipe(map(data => {
                if (!data && !data.protocolStatusNumber)
                    return 0;

                return data.protocolStatusNumber;
            }));
    }

    public addOrUpdateProtocolTemplate(protocolTemplate: ProtocolTemplate, id: string, saveAsGlobalTemplate: boolean, saveAsBuildingTemplate: boolean = false) {
        var object =
        {
            Id: id,
            ProtocolTypeNumber: protocolTemplate.protocolTypeNumber,
            CaseId: protocolTemplate.caseId,
            Name: protocolTemplate.name,
            ProtocolElements: protocolTemplate.protocolElements,
            SaveAsGlobalTemplate: saveAsGlobalTemplate,
            SaveAsBuildingTemplate: saveAsBuildingTemplate
        }

        return this.http.post<any>('/api/protocolTemplate/addOrUpdateProtocolTemplate', object)
            .pipe(map(data => {
                return data;
            }));
    }

    public removeProtocolTemplate(protocolNumber: number, name: string, caseId: string) {
        var object =
        {
            ProtocolTypeNumber: protocolNumber,
            CaseId: caseId,
            Name: name
        }

        return this.http.post<any>('/api/protocolTemplate/removeProtocolTemplate', object)
            .pipe(map(data => {
                return data;
            }));
    }

    public getProtocolTemplate(protocolNumber: number, name: string, caseId: string) {
        return this.http.get<any>(`/api/protocolTemplate/GetProtocolTemplate/${protocolNumber}/${name}/${caseId}`)
            .pipe(map(data => {
                if (!data || !data.elements)
                    return null;

                var elements = [];
                data.elements.forEach(element => {
                    elements.push(this.protocolBuildingElementAdapter.adapt(element));
                });

                var object =
                {
                    Elements: elements,
                    Id: data.id
                }
                return object;
            }));
    }

    public prepareEngineerSignature(data: any) {
        if (!data || !data.engineerSignatures)
            return null;

        var engineerSignatures: EngineerSignature[] = [];
        for (const engineerSignature of data.engineerSignatures) {
            engineerSignatures.push(this.engineerSignatureAdapter.adapt(engineerSignature));
        }

        return engineerSignatures;
    }

    public getProtocolSignatures(protocolTypeNumbers: number[] = [1], clientTaskId: string = "", caseId: string = "", protocolId: string = "") {
        let params = new HttpParams({
            fromObject: { 'protocolTypeNumbers': protocolTypeNumbers.map(ptn => ptn.toString()) }
        })
            .set('clientTaskId', clientTaskId)
            .set('caseId', caseId)
            .set('protocolId', protocolId);

        return this.http.get<any>('/api/protocols/GetProtocolSignatures', { params: params }).pipe(map(data => {
            if (!data || !data.protocolSignatures)
                return null;

            var protocolSignatures: ProtocolSignature[] = [];
            for (const protocolSignature of data.protocolSignatures) {
                protocolSignatures.push(this.protocolSignatureAdapter.adapt(protocolSignature));
            }

            return protocolSignatures;
        }));
    }

    public saveProtocolSignatures(protocolSignatureToSaveOrUpdate: ProtocolSignatureWithCaseIds) {
        return this.http.post<any>('/api/protocols/SaveProtocolSignatures', { protocolSignature: protocolSignatureToSaveOrUpdate })
            .pipe(map(data => {
                return data;
            }));
    }
}
