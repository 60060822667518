import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { map, } from 'rxjs/operators';
import { AccountActivation } from '../models/account-activation';
import { ChangePassword } from '../models/change-password';
import { LicenseAdapter } from '../models/license';
import { LicenseType } from '../models/license-type';

@Injectable()
export class AccountService {

    constructor(private http: HttpClient,
        private licenseAdapter: LicenseAdapter
    ) {  }

    public activateAccount(accountActivation: AccountActivation) {
        return this.http.post<any>('/api/account/ActivateUser', accountActivation)
            .pipe(map(data => {
                return data;
            }));
    }

    public changePassword(changePassword: ChangePassword) {
        return this.http.post<any>('/api/account/ChangePassword', changePassword)
            .pipe(map(data => {
                return data;
            }));
    }

    public resetUserPassword(id: string) {
        return this.http.post<any>('/api/account/ResetUserPassword', { id: id })
            .pipe(map(data => {
                return data;
            }));
    }

    public removeSignature(photoNameToDelete: string) {
        return this.http.post<any>('/api/account/RemoveSignature', { userId: "", photoNameToDelete: photoNameToDelete })
            .pipe(map(data => {
                return data;
            }));
    }

    public uploadSignature(formData: FormData) {
        return this.http.post<any>('/api/account/UploadSignature', formData)
            .pipe(map(data => {
                return data;
            }));
    }

    public removeLicenseFromUser(userIdFromFrontend: string, licenseFileName: string, type: LicenseType) {
        return this.http.post<any>('/api/account/RemoveLicense', { userId: userIdFromFrontend, licenseFileName: licenseFileName, type: type })
            .pipe(map(data => {
                return data;
            }));
    }

    public uploadLicenseForUser(formData: FormData, type: LicenseType, expirationDateForLicense: Date) {
        formData.append('type', type.toString());
        formData.append('expirationDate', expirationDateForLicense.toDateString())
        return this.http.post<any>('/api/account/UploadLicense', formData)
            .pipe(map(data => {
                return data;
            }));
    }

    public editProfileDetails(profileEdit: Object) {
        return this.http.post<any>('/api/account/EditProfileDetails', profileEdit)
            .pipe(map(data => {
                return data;
            }));
    }

    public getLicenseLink(id: string, type: LicenseType) {
        return this.http.get<any>(`/api/account/GetLicense/${id}/${type}`).pipe(map(data => {
            if (!data)
                return null;

            return this.licenseAdapter.adapt(data);
        }));
    }
}