import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Tooltip, TooltipComponent } from '@syncfusion/ej2-angular-popups';
import { FilterSettingsModel } from '@syncfusion/ej2-grids';
import { Subscription } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/utilities/modal/modal.component';
import { ClientType } from 'src/app/models/client-type';
import { ManagerChangeStatus } from 'src/app/models/manager-change-status';
import { ProtocolForSpecificTask } from 'src/app/models/protocol-for-specific-task';
import { ProtocolType } from 'src/app/models/protocol-type';
import { TaskFromProtocolDashboard } from 'src/app/models/task-from-protocol-dashboard';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalNotificationService } from 'src/app/services/global-notification.service';
import { ProtocolsService } from 'src/app/services/protocols.service';

@Component({
  selector: 'app-tables-with-protocols-dashboard',
  templateUrl: './tables-with-protocols-dashboard.component.html',
  styleUrls: ['./tables-with-protocols-dashboard.component.scss']
})
export class TablesWithProtocolsDashboardComponent implements OnInit, AfterViewInit {

  private tasksFromProtocolDashboard: TaskFromProtocolDashboard[];
  public allProtocolsForSpecificTask: ProtocolForSpecificTask[];
  @ViewChild("tooltip", { static: false }) public tooltip: TooltipComponent;
  @ViewChild("tablesWithProtocolsDashboardGrid", { static: false }) public grid: GridComponent;
  @ViewChild("modal", { static: false }) public modal: ModalComponent;
  public customAttri: object;
  public protocolIds: ManagerChangeStatus[];
  public modalSubscription: Subscription;
  public choosenTaskId: string;
  public filterOption: FilterSettingsModel = { type: 'Excel' };
  public innerWidth: any;

  public localData: Object[];
  public field: Object;

  constructor(private protocolsService: ProtocolsService,
    private notificationService: GlobalNotificationService,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngAfterViewInit() {
    this.protocolsService.getTasksFromProtocolsDashboard("userId").pipe(first()).subscribe(
      data => {
        if (data) {
          this.tasksFromProtocolDashboard = data;
          if (this.tasksFromProtocolDashboard.length > 0) {
            this.createDataForTreeView();
            this.choosenTaskId = data[0].taskId;
            this.getProtocolsForSpecificClientTaskLocal(data[0].taskId);
          }
          else {
            this.allProtocolsForSpecificTask = [];
          }
        } else {
          this.allProtocolsForSpecificTask = [];
          this.onErrorGet("Nieudana aktualizacja danych");
        }
      },
      error => {
        this.onErrorGet(error);
      }
    )
  }

  public nodeClicked(args) {
    if (args.node.classList[1] == "e-level-2") {
      this.choosenTaskId = args.node.attributes[3].nodeValue;
      this.getProtocolsForSpecificClientTaskLocal(this.choosenTaskId);
    }
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.customAttri = { 'class': 'customcss' };
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    var treeElement = document.getElementById("treeelement");
    if (treeElement)
      treeElement.style.maxWidth = this.innerWidth - 1140 + "px";
    this.createDataForTreeView()
  }

  public onEditClick(protocolType: number, id: string, withoutEditing: boolean, caseId: string, clientTypeNumber: number, protocolTypeString: string) {
    if (clientTypeNumber == 0)
      clientTypeNumber = 1;

    this.notificationService.showInfoNotificationBasedOnMessage(`Protokół ${protocolTypeString} jest ładowany...`)
    this.router.navigate([`/protokoly/${ClientType[clientTypeNumber]}/${ProtocolType[protocolType]}/${withoutEditing ? (this.isAdminOrManager ? 'skomentuj' : 'wyswietl') : 'edytuj'}/${caseId}/${id}`]);
  }

  headerCellInfo(args) {
    const toolcontent = args.cell.column.headerText;
    const tooltip: Tooltip = new Tooltip({
      content: toolcontent
    });
    if (args.cell.column.type != "checkbox")
      tooltip.appendTo(args.node);
  }

  mouseover(args) {
    if (args.target.classList.contains('customcss') && args.target.innerText) {
      if (args.target.attributes[4].nodeValue.includes('Akcje')) {
        this.tooltip.content = '';
      }
      else if (args.target.attributes[4].nodeValue.includes('Stan bezpieczeństwa')) {
        if (this.isAdminOrManager) {
          this.tooltip.content = args.target.parentElement.children[10].innerHTML;
        }
        else {
          this.tooltip.content = args.target.parentElement.children[9].innerHTML;
        }
      }
      else {
        this.tooltip.content = args.target.innerText;
      }
    }
  }

  public onDenyClick() {
    var nrOfSelectedRows = this.grid.getSelectedRowIndexes().length;
    this.protocolIds = [];

    if (nrOfSelectedRows > 0) {
      var managerComment = (<HTMLInputElement>document.getElementById('managerComment')).value;
      if (managerComment == null || managerComment.length <= 0) {
        this.modal.content = "Czy chcesz odrzucić zaznaczone protokoły bez komentarza?"
        managerComment = "";
      } else {
        this.modal.content = "Czy chcesz odrzucić zaznaczone protokoły z komentarzem o treści: '" + managerComment + "'?"
      }
      this.modal.title = "Potwierdzenie";
      this.modal.confirmText = "Tak, wiem co robię.";
      this.modal.declineText = "Anuluj";
      var id = this.modal.show();

      this.modalSubscription = this.modal.resultObservable.pipe(filter(r => r != null && r.id == id), take(1)).subscribe(
        result => {
          if (result.result) {
            this.preapreDataForManagerChangeStatus(managerComment, false);
          }
        },
        error => {
          this.notificationService.showErrorNotificationBasedOnErrorObject(error);
        }
      )
    } else {
      this.notificationService.showWarningNotification('Zaznacz co najmniej jeden protokół do odrzucenia!');
    }
  }

  public onConfirmClick() {
    var nrOfSelectedRows = this.grid.getSelectedRowIndexes().length;
    this.protocolIds = [];

    if (nrOfSelectedRows > 0) {
      this.modal.title = "Potwierdzenie";
      this.modal.content = "Czy chcesz zatwierdzić zaznaczone protokoły?"
      this.modal.confirmText = "Tak, wiem co robię.";
      this.modal.declineText = "Anuluj";
      var id = this.modal.show();

      this.modalSubscription = this.modal.resultObservable.pipe(filter(r => r != null && r.id == id), take(1)).subscribe(
        result => {
          if (result.result) {
            this.preapreDataForManagerChangeStatus('Do zaakceptowania. Nie powinienieś widzieć tego komunikatu.', true);
          }
        },
        error => {
          this.notificationService.showErrorNotificationBasedOnErrorObject(error);
        }
      )
    } else {
      this.notificationService.showWarningNotification('Zaznacz co najmniej jeden protokół do zatwierdzenia!');
    }
  }

  public get isAdminOrManager() {
    return this.authenticationService.isAdmin() || this.authenticationService.isManager();
  }

  public get thereIsAtLeastOneTask() {
    return this.tasksFromProtocolDashboard && this.tasksFromProtocolDashboard.length > 0;
  }

  public get isAtLeastOneRowSelected() {
    if (this.grid)
      return this.grid.getSelectedRowIndexes().length > 0;
    else
      return false;
  }

  private getProtocolsForSpecificClientTaskLocal(taskId: string) {
    this.protocolsService.getProtocolsForSpecificClientTask("userId", taskId).pipe(first()).subscribe(
      data => {
        if (data) {
          this.allProtocolsForSpecificTask = data;
        } else {
          this.allProtocolsForSpecificTask = [];
          this.onErrorGet("Nieudana aktualizacja danych");
        }
      },
      error => {
        this.onErrorGet(error);
      }
    )
  }

  getPosition(element) {
    var xPosition = 0;
    var yPosition = 0;

    while (element) {
      xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
      yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
      element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
  }

  private createDataForTreeView(choosenTaskId: string = "brak") {
    var distinctClientNames = [];
    var headers = [];
    var content = [];
    var counter = 0;

    var lengthOfContentName = 35;
    var lengthOfHeaderName = 40;
    if (parseInt(this.innerWidth)) {
      lengthOfContentName = (0.034369 * parseInt(this.innerWidth)) - 18;
      lengthOfHeaderName = (0.034369 * parseInt(this.innerWidth)) - 13;
    }

    for (var i = 0; i < this.tasksFromProtocolDashboard.length; i++) {
      var task = this.tasksFromProtocolDashboard[i];
      var clientFullName = task.clientFullName;
      if (!distinctClientNames.includes(clientFullName)) {
        headers.push({
          id: clientFullName,
          name: ("Klient: " + clientFullName).length > lengthOfHeaderName ? ("Klient: " + clientFullName).substr(0, lengthOfHeaderName) + "..." : ("Klient: " + clientFullName),
          hasChild: true,
          expanded: counter == 0 ? true : false,
          tooltip: "Klient: " + clientFullName
        })
        distinctClientNames.push(clientFullName);
      }
      if (choosenTaskId == "brak") {
        content.push({
          id: task.taskId,
          pid: task.clientFullName,
          name: (task.taskFullName + " (" + task.taskShortName + ")").length > lengthOfContentName ? (task.taskFullName + " (" + task.taskShortName + ")").substr(0, lengthOfContentName) + "..." : (task.taskFullName + " (" + task.taskShortName + ")"),
          isSelected: counter == 0 ? true : false,
          tooltip: task.taskFullName + " (" + task.taskShortName + ")"
        });
      }
      else {
        content.push({
          id: task.taskId,
          pid: task.clientFullName,
          name: (task.taskFullName + " (" + task.taskShortName + ")").length > lengthOfContentName ? (task.taskFullName + " (" + task.taskShortName + ")").substr(0, lengthOfContentName) + "..." : (task.taskFullName + " (" + task.taskShortName + ")"),
          isSelected: choosenTaskId == task.taskId ? true : false,
          tooltip: task.taskFullName + " (" + task.taskShortName + ")"
        });
      }

      counter++;
    }
    this.field = { dataSource: headers.concat(content), id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild', selected: 'isSelected' };
  }

  private onErrorGet(error: any) {
    this.notificationService.showErrorNotificationBasedOnErrorObject(error);
  }

  private preapreDataForManagerChangeStatus(managerComment: string, isAccepted: boolean) {
    (<HTMLInputElement>document.getElementById('managerComment')).value = "";
    var indexes = this.grid.getSelectedRowIndexes();

    for (var i = 0; i < indexes.length; i++) {
      var fullTxt = this.grid.getRowByIndex(indexes[i]).textContent;
      this.protocolIds.push(new ManagerChangeStatus(fullTxt.substr(fullTxt.length - 36), managerComment, isAccepted));
    }

    if (!isAccepted)
      this.removeProtocolIdsIfDenied();

    var date = new Date();
    date.setTime(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
    this.protocolsService.managerChangeStatusOfProtocolDashboard("userId", this.protocolIds, date)
      .pipe(first())
      .subscribe(
        data => {
          if (data.success) {
            this.onSuccessfulChangeStatus(isAccepted);
            this.protocolsService.getTasksFromProtocolsDashboard("userId").pipe(first()).subscribe(
              data => {
                if (data) {
                  this.tasksFromProtocolDashboard = data;
                  if (this.tasksFromProtocolDashboard.length > 0) {
                    var taskIds = this.tasksFromProtocolDashboard.map(t => t.taskId);
                    if (taskIds.includes(this.choosenTaskId)) {
                      this.createDataForTreeView(this.choosenTaskId);
                    }
                    else {
                      this.createDataForTreeView();
                      this.choosenTaskId = data[0].taskId;
                    }
                    this.getProtocolsForSpecificClientTaskLocal(this.choosenTaskId);
                  }
                  else {
                    this.allProtocolsForSpecificTask = [];
                  }
                } else {
                  this.allProtocolsForSpecificTask = [];
                  this.onErrorGet("Nieudana aktualizacja danych");
                }
              },
              error => {
                this.onErrorGet(error);
              }
            )
          }
          else {
            this.onUnsuccessfulChangeStatus(data.message);
          }
        },
        error => {
          this.notificationService.showErrorNotificationBasedOnErrorObject(error);
        });
  }

  private removeProtocolIdsIfDenied() {
    var realProtocolIds = [];

    for (var i = 0; i < this.protocolIds.length; i++) {
      var task = this.allProtocolsForSpecificTask.find(x => x.protocolId == this.protocolIds[i].protocolId)
      if (task.protocolStatusNumber != 4) {
        realProtocolIds.push(new ManagerChangeStatus(this.protocolIds[i].protocolId, this.protocolIds[i].managerComment, this.protocolIds[i].isAccepted));
      }
    }
    this.protocolIds = realProtocolIds;
  }

  private onUnsuccessfulChangeStatus(message: any) {
    this.notificationService.showErrorNotification(message);
  }

  private onSuccessfulChangeStatus(isAccepted: boolean = true) {
    if (isAccepted)
      var message = 'Zaznaczone protokóły zostały zatwierdzone';
    else
      var message = 'Zaznaczone protokóły zostały odrzucone';

    this.notificationService.showSuccessNotification(message);
  }

}
