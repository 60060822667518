import { WorkObjectListItem, WorkObjectListItemAdapter } from './work-object-list-item';
import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UserPickItem, UserPickItemAdapter } from './user-pick-item';

export class ClientTaskDetails {
    public currentShortName: string;

    constructor(
        public id: string,
        public name: string,
        public shortName: string,
        public clientShortName: string,
        public workObjects: WorkObjectListItem[],
        public taskUsers: UserPickItem[],
        public clientTypeNumber: number,
        public distinctShortNames: string[]
    ) { }
}

@Injectable()
export class ClientTaskDetailsAdapter implements Adapter<ClientTaskDetails>{
    constructor(private workObjectListItemAdaper: WorkObjectListItemAdapter,
        private userPickItemAdapter: UserPickItemAdapter) {
    }

    public adapt(item: any): ClientTaskDetails {
        if (!item)
            return null;
        var workObjects: WorkObjectListItem[] = [];
        item.workObjects.forEach(workObject => {
            workObjects.push(this.workObjectListItemAdaper.adapt(workObject));
        });

        var taskUsers: UserPickItem[] = [];
        item.taskUsers.forEach(taskUser => {
            taskUsers.push(this.userPickItemAdapter.adapt(taskUser));
        });

        return new ClientTaskDetails(
            item.id,
            item.name,
            item.shortName,
            item.clientShortName,
            workObjects,
            taskUsers,
            item.clientTypeNumber,
            item.distinctShortNames
        );
    }
}

@Injectable()
export class ClientTaskDetailsFormManager implements ModelFormManager<ClientTaskDetails>{
    private ClientTaskDetailsForm = this.formBuilder.group({
        name: ['', Validators.required],
        shortName: ['',
        [
            Validators.required,
            Validators.maxLength(25),
            Validators.pattern('[a-zA-Z0-9 ]*')
        ]],
        clientShortName: ['', Validators.required]
    });

    constructor(private formBuilder: UntypedFormBuilder) { }

    buildForm(model: ClientTaskDetails): UntypedFormGroup {
        if (!model)
            return this.ClientTaskDetailsForm;

        return this.formBuilder.group({
            name: [model.name == null ? '' : model.name, Validators.required],
            shortName: [model.shortName == null ? '' : model.shortName, [
                Validators.required,
                Validators.maxLength(25),
                Validators.pattern('[a-zA-Z0-9 ]*')
            ]],
            clientShortName: [model.clientShortName == null ? '' : model.clientShortName, Validators.required]
        });
    }

    getEmptyForm(): UntypedFormGroup {
        return this.ClientTaskDetailsForm;
    }
}