import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class MainPhoto {
    public isSelected: boolean = false;

    constructor(
        public caseId: string,
        public photoName: string) { }
}

@Injectable()
export class MainPhotoAdapter implements Adapter<MainPhoto>{
    adapt(item: any): MainPhoto {
        return new MainPhoto(
            item.caseId,
            item.photoName
        );
    }
}