import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class CaseIdWithCoordinates {
    constructor(
        public caseId: string,
        public longitude: string,
        public latitude: string
    ) { }
}

@Injectable()
export class CaseIdWithCoordinatesAdapter implements Adapter<CaseIdWithCoordinates>{
    adapt(item: any): CaseIdWithCoordinates {
        return new CaseIdWithCoordinates(
            item.caseId,
            item.longitude,
            item.latitude
        );
    }
}