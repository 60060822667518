import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { ClientSimpleDetails } from 'src/app/models/client-simple-details';
import { ClientTaskDashboard } from 'src/app/models/client-task-dashboard';
import { ClientTasksMenu } from 'src/app/models/client-tasks-menu';
import { UserProfile } from 'src/app/models/user-profile';
import { AccountService } from 'src/app/services/account.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CacheService, GetAllClientTasks, GetAllClients } from 'src/app/services/cache.service';
import { ClientService } from 'src/app/services/client-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public user: UserProfile;
  public clients: ClientSimpleDetails[];
  public newClientTasks: ClientTasksMenu[] = [];
  private allClientsSubscription: Subscription;
  private allClientTasksSubscription: Subscription;

  constructor(
    public authenticationService: AuthenticationService,
    public accountService: AccountService,
    private clientService: ClientService,
    private cacheService: CacheService) { }

  ngOnInit() {
    this.user = this.authenticationService.userValue;

    if (this.authenticationService.isManager()) {
      this.allClientsSubscription = this.cacheService.getData(GetAllClients).pipe(first()).subscribe(
        data => {
          this.clients = this.clientService.prepareClientsDetailsAfterGetAllClients(data);
        }
      );
    }

    if (this.canUserSeeTheirProfile()) {
      this.allClientTasksSubscription = this.cacheService.getData(GetAllClientTasks).pipe(first()).subscribe(
        data => { this.onClientTaskLoad(this.clientService.prepareClientTasks(data)); }
      );
    }
  }

  ngOnDestroy() {
    if (this.allClientTasksSubscription)
      this.allClientTasksSubscription.unsubscribe();

    if(this.allClientsSubscription)
      this.allClientsSubscription.unsubscribe();
  }

  onClientTaskLoad(data: ClientTaskDashboard[]) {
    var clients = Array.from(new Set(data.map(x => x.clientShortName)));

    clients.forEach(client => {
      var filteredClientTasks = data.filter(ct => ct.clientShortName == client);

      for (var i = 0; i < data.length; i++) {
        if (data[i].clientShortName == client) {
          client = data[i].clientFullName;
          break;
        }
      }
      this.newClientTasks.push(new ClientTasksMenu(client, filteredClientTasks));
    });
  }

  logout() {
    this.authenticationService.logout();
  }

  public canUserSeeTheirProfile() {
    return !this.authenticationService.isChimneySweep() && !this.authenticationService.isClient();
  }
}
