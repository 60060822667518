import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class PreviousPostControlRecommendation {
    constructor(
        public id: string,
        public priorityNumber: number,
        public recommendation: string,
        public priorityName: string
    ) { }
}

@Injectable()
export class PreviousPostControlRecommendationAdapter implements Adapter<PreviousPostControlRecommendation>{
    adapt(item: any): PreviousPostControlRecommendation {
        return new PreviousPostControlRecommendation(
            item.id,
            item.priorityNumber,
            item.recommendation,
            item.priorityName
        );
    }
}