import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountActivation } from 'src/app/models/account-activation';
import { AccountService } from 'src/app/services/account.service';
import { GlobalNotificationService } from 'src/app/services/global-notification.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {
  public userId: string;
  public token: any;
  public activationInProgress = true;
  public isActivationSuccessful = false;

  constructor(private route: ActivatedRoute,
    private accountService: AccountService,
    private notificationService: GlobalNotificationService) { }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParams['userId'];
    this.token = this.route.snapshot.queryParams['token'];

    this.accountService.activateAccount(new AccountActivation(this.userId, this.token))
      .pipe(first()).subscribe(
        data => {
          if (data.success) {
            this.isActivationSuccessful = true;
          }
          else {
            this.notificationService.showErrorNotification(data.message);
          }

          this.activationInProgress = false;
        },
        error => {
          this.isActivationSuccessful = false;
          this.activationInProgress = false;
        }
      );
  }

}
