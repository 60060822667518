<div class="row"></div>
<div class="row">
  <div class="col-3">
    <div *ngIf='thereIsAtLeastOneTask'>
      <div id='treeparent'>
        <ejs-treeview #treeNew id='treeelement' [fields]='field' (nodeClicked)='nodeClicked($event)'
          [selectedNodes]='selectedNodes'></ejs-treeview>
      </div>
    </div>
    <div *ngIf='!thereIsAtLeastOneTask'>
      <div tabindex="0" class="e-card" id="basic">
        <div class="e-card-content">
          Brak zadań do zrecenzowania.
        </div>
      </div>
    </div>
    <br>
  </div>

  <div class="col-9" *ngIf='thereIsAtLeastOneTask'>
    <div class="control-section">
      <ejs-tooltip #tooltip position='BottomCenter' target=".e-rowcell.customcss">
        <ejs-grid #tablesWithProtocolsDashboardGrid id='tablesWithProtocolsDashboardGrid' [dataSource]='allProtocolsForSpecificTask' [allowResizing]='true'
          [allowSorting]='true' [allowResizing]='true' [allowFiltering]='true' [filterSettings]='filterOption'
          [enablePersistence]='false' (mouseover)='mouseover($event)' (headerCellInfo)='headerCellInfo($event)'>
          <e-columns>
            <e-column *ngIf='isAdminOrManager' type='checkbox' width='35'></e-column>
            <e-column headerText="Akcje" width=105>
              <ng-template #template let-data>

                <div class='row' *ngIf='isAdminOrManager'>
                  <div class='col-5' *ngIf='data.canEdit'>
                    <button class="btn btn-warning" title="Edytuj"
                      (click)="onEditClick(data.protocolTypeNumber, data.protocolId, false, data.caseId, data.clientTypeNumber, data.protocolType)"><i
                        class="fas fa-edit"></i></button>
                  </div>
                  <div class='col-5' *ngIf='data.canView'>
                    <button class="btn btn-info" title="Recenzuj"
                      (click)="onEditClick(data.protocolTypeNumber, data.protocolId, true, data.caseId, data.clientTypeNumber, data.protocolType)"><i
                        class="fas fa-book-open"></i></button>
                  </div>
                </div>

                <div class='row' *ngIf='!isAdminOrManager && data.clientTypeNumber != 3'>
                  <div class='col-5' *ngIf='data.canEdit'>
                    <button class="btn btn-warning" title="Edytuj"
                      (click)="onEditClick(data.protocolTypeNumber, data.protocolId, false, data.caseId, data.clientTypeNumber, data.protocolType)"><i
                        class="fas fa-edit"></i></button>
                  </div>
                  <div class='col-5' *ngIf='data.canView'>
                    <button class="btn btn-info" title="Wyświetl"
                      (click)="onEditClick(data.protocolTypeNumber, data.protocolId, true, data.caseId, data.clientTypeNumber, data.protocolType)"><i
                        class="fas fa-book-open"></i></button>
                  </div>
                </div>

                <div class='row' *ngIf='!isAdminOrManager && data.clientTypeNumber == 3'>
                  <div class='col-5' *ngIf='data.canEdit'>
                    <button class="btn btn-warning" title="Edytuj"
                      (click)="onEditClick(data.protocolTypeNumber, data.protocolId, false, data.caseId, data.clientTypeNumber, data.protocolType)"><i
                        class="fas fa-edit"></i></button>
                  </div>
                </div>

              </ng-template>
            </e-column>
            <e-column field='caseId' headerText='Obiekt' width=125 [customAttributes]='customAttri'></e-column>
            <e-column field='engineerNameAndSurname' headerText='Imię i nazwisko inżyniera' width=115
              [customAttributes]='customAttri'></e-column>
            <e-column field='protocolType' headerText='Typ' [customAttributes]='customAttri' width='130'>
              <ng-template #template let-data>
                <label *ngIf="data.protocolTypeNumber == 1"><i class="fas fa-calendar-plus"></i>
                  {{data.protocolType}}</label>
                <label *ngIf="data.protocolTypeNumber == 2"><i class="fas fa-dice-five"></i>
                  {{data.protocolType}}</label>
                <label *ngIf="data.protocolTypeNumber == 3 || data.protocolTypeNumber == 8"><i class="fas fa-home"></i> {{data.protocolType}}</label>
                <label *ngIf="data.protocolTypeNumber == 4"><i class="fas fa-calendar-minus"></i>
                  {{data.protocolType}}</label>
                <label *ngIf="data.protocolTypeNumber == 5 || data.protocolTypeNumber == 9"><i class="fas fa-burn"></i> {{data.protocolType}}</label>
                <label *ngIf="data.protocolTypeNumber == 6"><i class="far fa-file-alt"></i>
                  {{data.protocolType}}</label>
              </ng-template>
            </e-column>
            <e-column field='protocolStatus' headerText='Status' width=110 [customAttributes]='customAttri'></e-column>
            <e-column field='controlDate' headerText='Data kontroli' width=120 [customAttributes]='customAttri'>
            </e-column>
            <e-column field='protocolCreationDate' headerText='Data stworzenia protokołu' width=120
              [customAttributes]='customAttri'></e-column>
            <e-column field='buildingStateRating' headerText='Stan techniczny' width=100
              [customAttributes]='customAttri'></e-column>
            <e-column field='buildingSafetyRatingNumber' headerText='Stan bezpieczeństwa' width=100
              [customAttributes]='customAttri'></e-column>
            <e-column field='buildingSafetyRating' headerText='buildingSafetyRating' [visible]="false"></e-column>
            <e-column field='protocolId' headerText='protocolId' [visible]="false"></e-column>
          </e-columns>
        </ejs-grid>
      </ejs-tooltip>
    </div>
    <br>
    <div class='row'></div>
    <div *ngIf='isAtLeastOneRowSelected'>
      <div class="e-card-content" *ngIf='isAdminOrManager'>
        <div class="form-group">
          <textarea autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" type="text"
            class="form-control" id="managerComment"
            placeholder="Wpisz komentarz do protokołu dla inżyniera..."></textarea>
        </div>
        <div class="col">
          <div class="row">
            <button id="denyCheckedProtocolsButton" class="btn btn-warning mt-3 mr-2" (click)="onDenyClick()"><i class="fas fa-times"></i> Odrzuć
              zaznaczone</button>
            <button id="acceptCheckedProtocolsButton" class="btn btn-success mt-3 mr-2" (click)="onConfirmClick()"><i class="fas fa-check"></i>
              Zatwierdź zaznaczone</button>
          </div>
          <br>
        </div>
      </div>
    </div>
    <div *ngIf='!isAtLeastOneRowSelected'>
      <div class="e-card-content" *ngIf='isAdminOrManager'>
        <div class="form-group">
          <textarea disabled autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" type="text"
            class="form-control" id="managerComment"
            placeholder="Wpisz komentarz do protokołu dla inżyniera..."></textarea>
        </div>
        <div class="col">
          <div class="row">
            <button class="btn btn-warning mt-3 mr-2" disabled><i class="fas fa-times"></i> Odrzuć zaznaczone</button>
            <button class="btn btn-success mt-3 mr-2" disabled><i class="fas fa-check"></i> Zatwierdź
              zaznaczone</button>
          </div>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal #modal></app-modal>