<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="/" id="navbarBrand"><i class="fas fa-home"></i> Przeglądy budowlane</a>

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#nav-right-content,#nav-left-content"
    aria-controls="nav-right-content,nav-left-content" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="nav-left-content">
    <ul class="navbar-nav">
      <li class="nav-item dropdown" *ngIf='authenticationService.isManager()'>
        <a class="nav-link dropdown-toggle" href="#" id="clientsNavbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-users"></i> Klienci
        </a>
        <div class="dropdown-menu" aria-labelledby="clientsNavbarDropdown">
          <a class="dropdown-item" id="clientListDropdownItem" routerLink="/klienci"><i class="fas fa-list-ol"></i> Lista klientów</a>
          <div class="dropdown-divider"></div>
          <a *ngFor="let client of clients" class="dropdown-item" [routerLink]='["/klienci", client.shortName]'><i
              class="fas fa-angle-right"></i> {{client.fullName}}</a>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf='authenticationService.isManager() ||  authenticationService.isEngineer()'>
        <a class="nav-link dropdown-toggle" href="#" id="tasksNavbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-tasks"></i> Zadania
        </a>
        <div class="dropdown-menu" aria-labelledby="tasksNavbarDropdown">
          <a class="dropdown-item" id="clientTaskListDropdownItem" routerLink="/zadania"><i class="fas fa-list-ol"></i> Lista zadań</a>
          <div class="dropdown-divider"></div>
          <div *ngFor="let clientTaskMenu of newClientTasks">
            <label class="ml-2"><b>Klient:</b> {{clientTaskMenu.clientName}}</label>
            <a *ngFor="let clientTask of clientTaskMenu.clientTasks" class="dropdown-item"
              [routerLink]='["/zadania", clientTask.clientShortName, clientTask.shortName]'><i
                class="fas fa-angle-right"></i> {{clientTask.fullName}}</a>
            <div class="dropdown-divider"></div>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" *ngIf='authenticationService.isAdmin()'>
        <a class="nav-link dropdown-toggle" href="#" id="adminNavbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-user-secret"></i> Admin
        </a>
        <div class="dropdown-menu" aria-labelledby="adminNavbarDropdown">
          <a class="dropdown-item" [routerLink]='["/admin/uzytkownicy"]'><i class="far fa-id-card"></i> Użytkownicy</a>
          <a class="dropdown-item" [routerLink]='["/admin/ustawienia"]'><i class="fa fa-cog"></i> Ustawienia</a>
          <a class="dropdown-item" [routerLink]='["/admin/raporty"]'><i class="fa fa-table"></i> Raporty</a>
          <a class="dropdown-item" [routerLink]='["/raporty/generuj"]'><i class="fa fa-book"></i> Generowanie zbiorcze</a>
        </div>
      </li>
    </ul>
  </div>

  <div class="collapse navbar-collapse justify-content-end" id="nav-right-content">
    <ul class="navbar-nav">
      <li *ngIf="user" class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="myProfileNavbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <i class="fas fa-id-badge"></i> {{user.nameAndSurname}}
        </a>
        <div class="dropdown-menu" aria-labelledby="myProfileNavbarDropdown">
          <div *ngIf='canUserSeeTheirProfile()'>
            <a id="myProfileButton" class="dropdown-item" [routerLink]='["/profil"]'><i class="fas fa-user-circle"></i> Mój profil</a>
            <div class="dropdown-divider"></div>
          </div>
          <a id="logoutButton" class="dropdown-item" (click)="logout()"><i class="fas fa-sign-out-alt"></i> Wyloguj</a>
        </div>
      </li>
      <li class="nav-item">
        <div id='element' *ngIf="user">
          <span *ngIf="!user.hasPicture" class="e-avatar e-avatar-circle">{{user.initials}}</span>
          <span *ngIf="user.hasPicture" class="e-avatar e-avatar-circle"><img class="image"
              [src]="user.pictureUrl" alt="avatar" /></span>
        </div>
      </li>
    </ul>
  </div>
</nav>