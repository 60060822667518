<h1>Przeglądy budowlane</h1>

<div *ngIf='canUserSeeFullContentOfApplication()'>
    <app-mobile-app-version id="mobileAppVersion"></app-mobile-app-version>
    <app-documentation id="documentation"></app-documentation>
    <app-tables-with-protocols-dashboard id="tablesWithProtocolsDashboard"></app-tables-with-protocols-dashboard>
</div>

<div *ngIf='authenticationService.isChimneySweep()'>
    <div tabindex="0" class="e-card" id="basic">
        <div class="e-card-content">
            <b><i>Posiadasz rolę Kominiarz, która pozwala Ci na dostęp jedynie do aplikacji mobilnej!</i></b>
        </div>
    </div>
    <app-mobile-app-version></app-mobile-app-version>
</div>

<div *ngIf='authenticationService.isClient()'>
    <div tabindex="0" class="e-card" id="basic">
        <div class="e-card-content">
            <b><i>Posiadasz rolę Klient, która pozwala Ci na dostęp jedynie do aplikacji z podglądami!</i></b>
        </div>
    </div>
</div>