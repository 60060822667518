import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class PostControlRecommendationPriority {
    constructor(
        public name: string,
        public number: number,
        public description: string
    ) { }
}

@Injectable()
export class PostControlRecommendationPriorityAdapter implements Adapter<PostControlRecommendationPriority>{
    adapt(item: any): PostControlRecommendationPriority {
        return new PostControlRecommendationPriority(
            item.name,
            item.number,
            item.description
        );
    }
}