import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class UserPickItem {
    public fullName: string;

    constructor(
        public userName: string,
        public firstName: string,
        public lastName: string,
        public userRole: number
    ) {
        this.fullName = `${firstName} ${lastName}`;
    }
}

@Injectable()
export class UserPickItemAdapter implements Adapter<UserPickItem>{
    adapt(item: any): UserPickItem {
        return new UserPickItem(
            item.userName,
            item.firstName,
            item.lastName,
            item.userRole
        );
    }
}