<div tabindex="0" class="e-card" id="basic">
    <div class="e-card-header">
        <div class="e-card-header-caption">
            <div class="e-card-title text-center">
                <h3> {{ title }}</h3>
            </div>
        </div>
    </div>
    @if(!constructionLicenseSet)
    {
    <div class="e-card-content">
        <label>Data ważności wgrywanych uprawnień</label>
        <ejs-datepicker placeholder='Data ważności wgrywanych uprawnień' format='dd.MM.yyyy'
            [(value)]='expirationDateForLicense' (change)="onDateChange($event)" [min]="minDate"></ejs-datepicker>

        <div class="row">
            <div class="col text-center">
                <input type="file" #file placeholder="Choose file" (change)="onLicenseSet(file.files)"
                    style="display: none;" multiple accept="application/pdf" />
                <button id="topMargin" type="button" class="btn btn-success" (click)="file.click()"
                    [disabled]="!dateSet">
                    <i class="far fa-folder-open"></i>
                    Dodaj uprawnienia
                </button>
            </div>
        </div>
    </div>
    }
    @else {
    <div class="e-card-content">
        <div class="row">
            <label class="col text-center">Data wgrania pliku: <b>{{license.uploadDate | date:'dd.MM.yyyy HH:mm'}}</b></label>
        </div>
        <div class="row">
            <label class="col text-center">Nazwa pliku: <b>{{license.fileName}}</b></label>
        </div>
        <div class="row">
            <label class="col text-center">Data ważności: 
                <span [ngStyle]="{'background-color': license.expirationDate < currentDate ? 'lightcoral' : 'transparent'}">
                    <b>{{license.expirationDate | date:'dd.MM.yyyy'}}</b>
                </span>
            </label>
        </div>
        <div class="row">
            <pdf-viewer [src]="license.linkToPdf" [original-size]="true" [fit-to-page]="true" [page]="1"
                style="width: 1000px; height: 400px"></pdf-viewer>
        </div>
        <br>
        <div class="row">
            <div class="col text-center">
                <button class="btn btn-danger" (click)="onLicenseRemove()"><i class="fas fa-trash-alt"></i> Usuń
                    uprawnienia</button>
            </div>
        </div>
    </div>
    }
</div>