import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ProtocolReportDetail {
    constructor(public id: string,
        public caseId: string,
        public jnDesignation: string,
        public sapId: string,
        public inventoryId: string,
        public protocolType: string,
        public protocolTypeNumber: number,
        public authorName: string,
        public controlDate: Date,
        public creationDate: Date) {
    }
}

@Injectable()
export class ProtocolReportDetailAdapter implements Adapter<ProtocolReportDetail> {
    public adapt(item: any): ProtocolReportDetail {
        var protocolReportDetail = new ProtocolReportDetail(
            item.id,
            item.caseId,
            item.jnDesignation,
            item.sapId,
            item.inventoryId,
            item.protocolType,
            item.protocolTypeNumber,
            item.authorName,
            new Date(item.controlDate),
            new Date(item.creationDate)
        );
        return protocolReportDetail;
    }
}