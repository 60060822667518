import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';

export class ProtocolGasElement {
    public id: string;
    public elementType: string;
    public elementRating: string;
    public remarksAndObservations: string;

    constructor() { }
}

@Injectable()
export class ProtocolGasElementAdapter implements Adapter<ProtocolGasElement>{
    adapt(item: any): ProtocolGasElement {
        var element = new ProtocolGasElement();

        element.id = item.id;
        element.elementType = item.elementType;
        element.elementRating = item.elementRating;
        element.remarksAndObservations = item.remarksAndObservations;

        return element;
    }
}