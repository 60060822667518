import { Injectable } from '@angular/core';
import { DocumentsForProtocol } from './documents-for-protocol';
import { Adapter } from './interfaces/adapter.interface';

export class ProtocolListItem {
    public Lp: number;

    constructor(
        public id: string,
        public type: string,
        public typeNumber: number,
        public status: string,
        public statusNumber: number,
        public controlDate: Date,
        public creationDate: Date,
        public authorFullName: string,
        public lastUpdatedByFullName: string,
        public canEdit: boolean,
        public canView: boolean,
        public canRemove: boolean,
        public hasPdf: boolean,
        public pdfUrl: string,
        public hasSignedPdf: boolean,
        public signedPdfUrl: string,
        public hasDoc: boolean,
        public docUrl: string,
        public hasError: boolean
    ) {
    }
}

@Injectable()
export class ProtocolListItemAdapter implements Adapter<ProtocolListItem> {
    adapt(item: any): ProtocolListItem {
        return new ProtocolListItem(
            item.id,
            item.type,
            item.typeNumber,
            item.status,
            item.statusNumber,
            item.controlDate,
            item.creationDate,
            item.authorFullName,
            item.lastUpdatedByFullName,
            item.canEdit,
            item.canView,
            item.canRemove,
            item.hasPdf,
            item.pdfUrl,
            item.hasSignedPdf,
            item.signedPdfUrl,
            item.hasDoc,
            item.docUrl,
            item.hasError
        );
    }

    adaptFromDocumentsForProtocol(pli: ProtocolListItem, documentsForProtocol: DocumentsForProtocol) {
        pli.hasDoc = documentsForProtocol.hasDoc;
        pli.docUrl = documentsForProtocol.docUrl;
        pli.hasSignedPdf = documentsForProtocol.hasSignedPdf;
        pli.signedPdfUrl = documentsForProtocol.signedPdfUrl;
        pli.hasPdf = documentsForProtocol.hasPdf;
        pli.pdfUrl = documentsForProtocol.pdfUrl;

        return pli
    }
}