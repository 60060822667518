import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalResult } from 'src/app/models/modal-result';

declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  public title: string;
  public content: string;
  public confirmText: string = "Ok";
  public declineText: string = "Odrzuć";

  private resultSubject: BehaviorSubject<ModalResult>;
  private currentId: string;

  public get resultObservable() {
    return this.resultSubject.asObservable();
  }

  constructor() {
  }

  ngOnInit() {
    this.resultSubject = new BehaviorSubject<ModalResult>(null);
  }

  public show(): string {
    $("#modalComponent").modal('show');
    this.currentId = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
    return this.currentId;
  }

  public getValue(result: boolean) {
    this.resultSubject.next(new ModalResult(this.currentId, result));
    this.hide();
  }

  public hide(): void {
    $("#modalComponent").modal('hide');
  }
}
