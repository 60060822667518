import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ClientContactPersonCreation {

    constructor(
        public name: string,
        public email: string,
        public phone: string
    ) {
    }
}

@Injectable()
export class ClientContactPersonCreationAdapter implements Adapter<ClientContactPersonCreation> {
    adapt(item: any): ClientContactPersonCreation {
        return new ClientContactPersonCreation(
            item.name,
            item.email,
            item.phone
        )
    }
}