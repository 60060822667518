import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { License } from 'src/app/models/license';
import { LicenseType } from 'src/app/models/license-type';
import { AccountService } from 'src/app/services/account.service';
import { AdminService } from 'src/app/services/admin.service';
import { GlobalNotificationService } from 'src/app/services/global-notification.service';

@Component({
  selector: 'app-construction-license',
  templateUrl: './construction-license.component.html',
  styleUrl: './construction-license.component.scss'
})
export class ConstructionLicenseComponent implements OnInit {
  public expirationDateForLicense: Date;
  public constructionLicenseSet: boolean = false;
  public license: License;
  public dateSet = false;
  public currentDate: Date = new Date();
  public uploadObservable: Observable<any>;
  public removeObservable: Observable<any>;
  public getObservable: Observable<any>;
  public minDate: Date = new Date();
  @Input() title: string;
  @Input() userId: string;
  @Input() type: LicenseType;
  @Input() editFromProfile = false;

  constructor(private notificationService: GlobalNotificationService,
    private adminService: AdminService,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.getLicense();
  }

  onDateChange(event: any) {
    const selectedDate = event.value;
    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      this.dateSet = true;
    } else {
      this.dateSet = false;
    }
  }

  public getLicense() {
    if (this.editFromProfile) {
      this.getObservable = this.accountService.getLicenseLink("FilledOnBackend", this.type);
    }
    else {
      this.getObservable = this.adminService.getLicenseLink(this.userId, this.type);
    }

    this.getObservable.pipe(first()).subscribe(
      data => {
        this.license = data;
        this.constructionLicenseSet = this.license.fileName !== "";
      },
      error => {
        this.notificationService.showErrorNotificationBasedOnErrorObject(error);
        this.constructionLicenseSet = false;
      });
  }

  public onLicenseSet = (files) => {
    if (files.length === 0) {
      return;
    }

    if (this.license.fileName != "") {
      this.notificationService.showWarningNotification("Najpierw usuń stare uprawnienia");
      return;
    }

    let filesToUpload: File[] = files;
    const formData = new FormData();

    Array.from(filesToUpload).map((file, _) => {
      return formData.append(this.userId, file, file.name);
    });

    this.notificationService.showInfoNotificationBasedOnMessage('Rozpoczęto przesyłanie uprawnień. Proszę czekać na potwierdzenie.');

    if (this.editFromProfile) {
      this.uploadObservable = this.accountService.uploadLicenseForUser(formData, this.type, this.expirationDateForLicense);
    }
    else {
      this.uploadObservable = this.adminService.uploadLicenseForUser(formData, this.type, this.expirationDateForLicense);
    }

    this.uploadObservable.pipe(first()).subscribe(
      data => {
        if (data.success) {
          this.notificationService.showSuccessNotification("Uprawnienie zostało dodane.");
          this.getLicense();
        }
        else {
          this.notificationService.showErrorNotification(data.message);
        }
      },
      error => {
        this.notificationService.showErrorNotificationBasedOnErrorObject(error);
      });
  }

  public onLicenseRemove() {
    if (this.license.fileName === "") {
      this.notificationService.showWarningNotification("Brak uprawnień do usunięcia.");
      return;
    }

    if (this.editFromProfile) {
      this.removeObservable = this.accountService.removeLicenseFromUser(this.userId, this.license.fileName, this.type);
    }
    else {
      this.removeObservable = this.adminService.removeLicenseFromUser(this.userId, this.license.fileName, this.type);
    }

    this.removeObservable.pipe(first()).subscribe(
      data => {
        if (data.success) {
          this.notificationService.showSuccessNotification("Uprawnienie zostało usunięte.");
          this.getLicense();
        }
        else {
          this.notificationService.showErrorNotification(data.message);
        }
      },
      error => {
        this.notificationService.showErrorNotificationBasedOnErrorObject(error);
      });
  }
}
