import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ClientContactPersonCreation, ClientContactPersonCreationAdapter } from './client-contact-person-creation';

export class ClientCreation {

    public clientContactPeople: [ClientContactPersonCreation];

    constructor(
        public fullName: string,
        public shortName: string,
        public description: string,
        public executorDescription: string,
        public clientTypeNumber?: number
    ) {
    }
}

@Injectable()
export class ClientCreationAdapter implements Adapter<ClientCreation> {
    constructor(private clientContactPersonCreationAdapter: ClientContactPersonCreationAdapter) {

    }

    adapt(item: any): ClientCreation {
        var clientCreation = new ClientCreation(
            item.fullName,
            item.shortName,
            item.description,
            item.executorDescription,
            item.clientTypeNumber ? item.clientTypeNumber : null
        );

        if (item.clientContactPeople && item.clientContactPeople.length > 0) {
            item.clientContactPeople.forEach(person => {
                if (!clientCreation.clientContactPeople) {
                    clientCreation.clientContactPeople = [this.clientContactPersonCreationAdapter.adapt(person)];
                }
                else {
                    clientCreation.clientContactPeople.push(this.clientContactPersonCreationAdapter.adapt(person));
                }
            });
        }

        return clientCreation;
    }
}