import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class BookStatusRating {
    constructor(
        public name: string,
        public number: number
    ) { }
}

@Injectable()
export class BookStatusRatingAdapter implements Adapter<BookStatusRating>{
    adapt(item: any): BookStatusRating {
        return new BookStatusRating(
            item.name,
            item.number
        );
    }
}