import { ReportFileDetails, ReportFileDetailsAdapter } from './report-file-details';
import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';

export class ReportDetails {
    constructor(
        public id: string,
        public requestedDate: Date,
        public completionDate: Date,
        public clientShortName: string,
        public taskShortName: string,
        public description: string,
        public files: ReportFileDetails[]
    ) { }
}

@Injectable()
export class ReportDetailsAdapter implements Adapter<ReportDetails>{
    constructor(
        private reportFileDetailsAdapter: ReportFileDetailsAdapter
    ) { }

    adapt(item: any): ReportDetails {
        var files = [];

        item.files.forEach(reportFile => {
            files.push(this.reportFileDetailsAdapter.adapt(reportFile));
        });

        var reportDetails = new ReportDetails(
            item.id,
            item.requestedDate,
            item.completionDate,
            item.clientShortName,
            item.taskShortName,
            item.description,
            files
        );

        return reportDetails;
    }

}