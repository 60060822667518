import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class Tag {
    constructor(
        public id: number,
        public name: string
    ) { }
}

@Injectable()
export class TagAdapter implements Adapter<Tag>{
    adapt(item: any): Tag {
        return new Tag(
            item.id,
            item.name
        );
    }
}