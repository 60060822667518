import { UserPickItem } from './user-pick-item';
import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { Roles } from './roles-enum';

export class WorkObjectDetails {
    public managersString: string;
    public engineersString: string;
    public clientsString: string;

    constructor(public CaseId: string,
        public JnDesignation: string,
        public Region: string,
        public County: string,
        public SapId: string,
        public InventoryId: string,
        public BuildingType: string,
        public Description: string,
        public Group: string,
        public BuildingArea: string,
        public FunctionalArea: string,
        public RoofArea: string,
        public PreviousHalfYearControlDate: Date,
        public PreviousAnnualControlDate: Date,
        public PreviousQuinquennialControlDate: Date,
        public SAT_FIAA: string,
        public ExploitationStartDate: Date,
        public AttritionPeriod: string,
        public YearsTillDate: string,
        public RemainingTime: string,
        public VerifiedLifeTime: string,
        public CurrentHalfYearControlDate: Date,
        public CurrentAnnualControlDate: Date,
        public CurrentQuinquennialControlDate: Date,
        public PostControlRecommendations: string,
        public PostControlRecommendations2ndGrade: string,
        public PostControlRecommendations3rdGrade: string,
        public PostControlHalfYearRecommendations: string,
        public PostControlHalfYearRecommendations2ndGrade: string,
        public PostControlHalfYearRecommendations3rdGrade: string,
        public PostControlQuinquennialRecommendations: string,
        public PostControlQuinquennialRecommendations2ndGrade: string,
        public PostControlQuinquennialRecommendations3rdGrade: string,
        public BuildingStateRating: string,
        public Longitude: string,
        public Latitude: string,
        public AdditionalNotes: string,
        public clientTypeNumber: number,
        public clientTaskId: string,
        public assignedUsers: UserPickItem[]) {
        if (assignedUsers) {
            if (assignedUsers.some(au => au.userRole == Roles.Inżynier))
            {
                var engineers = assignedUsers.filter(au => au.userRole == Roles.Inżynier);
                this.engineersString = engineers.map((s) => 
                        `${s.firstName} ${s.lastName}`).join(', ');
            }            
           
            if (assignedUsers.some(au => au.userRole == Roles.Klient))
            {
                var clients = assignedUsers.filter(au => au.userRole == Roles.Klient);
                this.clientsString = clients.map((s) => 
                        `${s.firstName} ${s.lastName}`).join(', ');
            }

            if (assignedUsers.some(au => au.userRole == Roles.Menadżer))
            {
                var clients = assignedUsers.filter(au => au.userRole == Roles.Menadżer);
                this.managersString = clients.map((s) => 
                        `${s.firstName} ${s.lastName}`).join(', ');
            }  
        }
    }
}

@Injectable()
export class WorkObjectDetailsAdapter implements Adapter<WorkObjectDetails>{
    public adapt(item: any): WorkObjectDetails {
        var workObjectDetails = new WorkObjectDetails(
            item.caseId,
            item.jnDesignation,
            item.region,
            item.county,
            item.sapId,
            item.inventoryId,
            item.buildingType,
            item.description,
            item.group,
            item.buildingArea,
            item.functionalArea,
            item.roofArea,
            item.previousHalfYearControlDate != null ? new Date(item.previousHalfYearControlDate) : null,
            item.previousAnnualControlDate != null ? new Date(item.previousAnnualControlDate) : null,
            item.previousQuinquennialControlDate != null ? new Date(item.previousQuinquennialControlDate) : null,
            item.sAT_FIAA,
            item.exploitationStartDate != null ? new Date(item.exploitationStartDate) : null,
            item.attritionPeriod,
            item.yearsTillDate,
            item.remainingTime,
            item.verifiedLifeTime,
            item.currentHalfYearControlDate != null ? new Date(item.currentHalfYearControlDate) : null,
            item.currentAnnualControlDate != null ? new Date(item.currentAnnualControlDate) : null,
            item.currentQuinquennialControlDate != null ? new Date(item.currentQuinquennialControlDate) : null,
            item.postControlRecommendations,
            item.postControlRecommendations2ndGrade,
            item.postControlRecommendations3rdGrade,
            item.postControlHalfYearRecommendations,
            item.postControlHalfYearRecommendations2ndGrade,
            item.postControlHalfYearRecommendations3rdGrade,
            item.postControlQuinquennialRecommendations,
            item.postControlQuinquennialRecommendations2ndGrade,
            item.postControlQuinquennialRecommendations3rdGrade,
            item.buildingStateRating,
            item.longitude,
            item.latitude,
            item.additionalNotes,
            item.clientTypeNumber,
            item.clientTaskId,
            item.assignedUsers
        );

        return workObjectDetails;
    }
}