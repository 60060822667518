<div class="login-page">
  <div class="before-login-form">
    <form [formGroup]="passwordForm" (ngSubmit)="onChange()" class="login-form">
      <label>Zmiana hasła</label>

      <input type="password" placeholder="hasło" required formControlName="password" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && passwordData.password.errors }" />
      <div *ngIf="submitted && passwordData.password.errors" class="invalid-feedback">
        <div *ngIf="passwordData.password.errors.required">Hasło jest wymagane!</div>
      </div>

      <input type="password" placeholder="podaj ponownie hasło" required formControlName="passwordRepeat"
        class="form-control" [ngClass]="{ 'is-invalid': submitted && passwordData.passwordRepeat.errors }" />
      <div *ngIf="submitted && passwordData.passwordRepeat.errors" class="invalid-feedback">
        <div *ngIf="passwordData.passwordRepeat.errors.required">Hasło jest wymagane!</div>
      </div>
      <button [disabled]="changeInProgress" class="btn-primary">zmień</button>
    </form>
  </div>
</div>