import { Injectable } from "@angular/core";
import { ToastType } from 'src/app/models/toast-details';
import { NotificationService } from "./notification.service";

@Injectable()
export class GlobalNotificationService {

    constructor(
        private notification: NotificationService
    ) { }

    public showErrorNotificationBasedOnErrorObject(errorObject: any) {
        if (errorObject?.error?.Message && typeof errorObject?.error?.Message === 'string')
            this.notification.showToast('Błąd', 'Komunikat: ' + errorObject.error.Message, ToastType.error);
        else if (errorObject && errorObject instanceof Error && errorObject?.message && typeof errorObject?.message === 'string')
            this.notification.showToast('Błąd', 'Komunikat: ' + errorObject, ToastType.error);
        else
            this.notification.showToast('Nieznany błąd', 'Brak dodatkowych informacji o błędzie', ToastType.error);
    }

    public showErrorNotification(errorMessage: string) {
        this.notification.showToast('Błąd', errorMessage, ToastType.error);
    }

    public showWarningNotification(warningMessage: string) {
        this.notification.showToast('Niepowodzenie', warningMessage, ToastType.warning);
    }

    public showWarningNotificationWithSpecificTitle(notificationTitle: string, warningMessage: string) {
        this.notification.showToast(notificationTitle, warningMessage, ToastType.warning);
    }

    public showSuccessNotification(successMessage: string) {
        this.notification.showToast('Sukces', successMessage, ToastType.success);
    }

    public showInfoNotificationBasedOnMessage(infoMessage: string) {
        this.notification.showToast('Informacja', infoMessage, ToastType.info);
    }
}