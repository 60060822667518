import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivateAccountComponent } from './components/account/activate-account/activate-account.component';
import { ChangePasswordComponent } from './components/account/change-password/change-password.component';
import { ProfileEditComponent } from './components/account/profile-edit/profile-edit.component';
import { HomeComponent } from './components/layout/home/home.component';
import { LoginComponent } from './components/layout/login/login.component';
import { MainComponent } from './components/layout/main/main.component';
import { ErrorCodePageComponent } from './components/shared/error-code-page/error-code-page.component';
import { AuthGuardService } from './services/auth-guard.service';
import { RoleGuard } from './services/role.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'aktywujKonto', component: ActivateAccountComponent },
  { path: 'zmienHaslo', component: ChangePasswordComponent },
  {
    path: '', component: MainComponent, canActivate: [AuthGuardService],
    children: [
      { path: '', component: HomeComponent, canActivate: [AuthGuardService] },
      { path: 'klienci', loadChildren: () => import('./components/clients/clients.module').then((m) => { return m.ClientModule }), canActivate: [RoleGuard], data: { roleClaims: ['admin_access', 'manager_access'] } },
      { path: 'zadania', loadChildren: () => import('./components/client-tasks/client-tasks.module').then((m) => { return m.ClientTasksModule }), canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] } },
      { path: 'obiekty', loadChildren: () => import('./components/work-objects/work-objects.module').then((m) => { return m.WorkObjectsModule }), canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] } },
      { path: 'admin', loadChildren: () => import('./components/admin/admin.module').then((m) => { return m.AdminModule }), canActivate: [RoleGuard], data: { roleClaims: ['admin_access'] } },
      { path: 'protokoly', loadChildren: () => import('./components/protocols/protocols.module').then((m) => { return m.ProtocolsModule }), canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] } },
      { path: 'raporty', loadChildren: () => import('./components/reports/reports.module').then((m) => { return m.ReportsModule }), canActivate: [RoleGuard], data: { roleClaims: ['manager_access'] } },
      { path: 'profil', component: ProfileEditComponent, canActivate: [RoleGuard], data: { roleClaims: ['engineer_access'] } },

      // error code page
      { path: 'blad/403', component: ErrorCodePageComponent, data: { code: '403', text: 'Nie masz uprawnień do wyświetlania tej strony' } },
      { path: 'blad/404', component: ErrorCodePageComponent, data: { code: '404', text: 'Nie znaleziono strony' } },
      { path: 'blad/500', component: ErrorCodePageComponent, data: { code: '500', text: 'Błąd serwera. Ewidentnie coś poszło nie tak' } },
      { path: '**', component: ErrorCodePageComponent, data: { code: '404', text: 'Nie znaleziono strony. Wpisałeś błędną ścieżkę' } }
    ]
  }];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'enabled'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
