import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';

export class ProtocolChimneyElement {
    public id: string;
    public elementType: string;
    public elementRating: string;
    public remarksAndObservations: string;

    constructor() { }
}

@Injectable()
export class ProtocolChimneyElementAdapter implements Adapter<ProtocolChimneyElement>{
    adapt(item: any): ProtocolChimneyElement {
        var element = new ProtocolChimneyElement();

        element.id = item.id;
        element.elementType = item.elementType;
        element.elementRating = item.elementRating;
        element.remarksAndObservations = item.remarksAndObservations;

        return element;
    }
}