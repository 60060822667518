import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

export class ProtocolComplaint {
    constructor(
        public id: string,
        public complainer: string,
        public content: string
    ) {
    }
}

@Injectable()
export class ProtocolComplaintAdapter implements Adapter<ProtocolComplaint> {
    adapt(item: any): ProtocolComplaint {
        return new ProtocolComplaint(
            item.id,
            item.complainer,
            item.content
        );
    }
}

@Injectable()
export class ProtocolComplaintFormManager implements ModelFormManager<ProtocolComplaint>{
    constructor(private formBuilder: UntypedFormBuilder) { }

    private protocolCompaintForm = this.formBuilder.group({
        id: [null],
        complainer: ["-", Validators.required],
        content: ["-", Validators.required]
    });

    buildForm(model: ProtocolComplaint): UntypedFormGroup {
        if (!model)
        {
            return this.protocolCompaintForm;
        }
        
         this.protocolCompaintForm = this.formBuilder.group({
            id: [model.id],
            complainer: [model.complainer, Validators.required],
            content: [model.content, Validators.required]
        });

        return this.protocolCompaintForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.protocolCompaintForm;
    }
}