<div class="login-page">
  <div class="before-login-form" id="loginForm">
    <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="login-form">
      <label>Logowanie</label>

      <input id="usernameInput" type="text" placeholder="login lub email" required formControlName="username" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && loginData.username.errors }" />
      <div *ngIf="submitted && loginData.username.errors" class="invalid-feedback">
        <div *ngIf="loginData.username.errors.required">Użytkownik jest wymagany!</div>
      </div>

      <input id="passwordInput" type="password" placeholder="hasło" required formControlName="password" class="form-control"
        [ngClass]="{ 'is-invalid': submitted && loginData.password.errors }" />
      <div *ngIf="submitted && loginData.password.errors" class="invalid-feedback">
        <div *ngIf="loginData.password.errors.required">Hasło jest wymagane!</div>
      </div>
      <button id="loginButton" [disabled]="loginInProgress" class="btn-primary">zaloguj</button>
    </form>
  </div>
</div>