import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';

export class TaskFromProtocolDashboard {
    public taskId: string;
    public clientFullName: string;
    public taskFullName: string;
    public taskShortName: string;

    constructor() { }
}

@Injectable()
export class TaskFromProtocolDashboardAdapter implements Adapter<TaskFromProtocolDashboard>{
    adapt(item: any): TaskFromProtocolDashboard {
        var element = new TaskFromProtocolDashboard();

        element.taskId = item.taskId;
        element.clientFullName = item.clientFullName;
        element.taskFullName = item.taskFullName;
        element.taskShortName = item.taskShortName;

        return element;
    }
}