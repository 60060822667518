import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ProtocolTypeListItem {
    constructor(
        public name: string,
        public number: number,
        public description: string ="",
        public iconClass: string ="",
        public buttonStyle: string = "",
        public id: string = ""
    ) { }
}

@Injectable()
export class ProtocolTypeListItemAdapter implements Adapter<ProtocolTypeListItem>{
    adapt(item: any): ProtocolTypeListItem {
        return new ProtocolTypeListItem(
            item.name,
            item.number
        );
    }
}