import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { Tag, TagAdapter } from './tag';

export class WorkObjectPhotoExtra {
    public isSelected: boolean = false;

    constructor(
        public id: string,
        public name: string,
        public url: string,
        public isInProtocol: boolean,
        public isMainPhoto: boolean,
        public tags: Tag[]) { }
}

@Injectable()
export class WorkObjectPhotoExtraAdapter implements Adapter<WorkObjectPhotoExtra>{
    constructor(
        private tagAdapter: TagAdapter) { }

    adapt(item: any): WorkObjectPhotoExtra {
        var tagsAdapted: Tag[] = [];
        item.tags.forEach(tag => {
            tagsAdapted.push(this.tagAdapter.adapt(tag));
        });

        return new WorkObjectPhotoExtra(
            item.id,
            item.name,
            item.url,
            item.isInProtocol,
            item.isMainPhoto,
            tagsAdapted
        );
    }
}