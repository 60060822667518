import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';
import { WorkObjectPhoto, WorkObjectPhotoAdapter } from './work-object-photo';

export class ProtocolBuildingElement {
    public id: string;
    public elementType: string;
    public elementCategory: string;
    public material: string;
    public elementStateRating: string;
    public elementStateRatingNumber: number;
    public remarksAndObservations: string;
    public postControlRecommendationPriority: string;
    public postControlRecommendationPriorityNumber: number;
    public requiresMaterial: boolean;
    public isEstetic: boolean;
    public elementPhotos: WorkObjectPhoto[];
    public elementPurpose: number;

    constructor() { }
}

@Injectable()
export class ProtocolBuildingElementAdapter implements Adapter<ProtocolBuildingElement>{
    constructor(private workObjectPhotoAdapter: WorkObjectPhotoAdapter) { }

    adapt(item: any): ProtocolBuildingElement {
        var element = new ProtocolBuildingElement();

        element.id = item.id;
        element.elementType = item.elementType;
        element.elementCategory = item.elementCategory;
        element.material = item.material;
        element.elementStateRating = item.elementStateRating;
        element.elementStateRatingNumber = item.elementStateRatingNumber;
        element.remarksAndObservations = item.remarksAndObservations;
        element.postControlRecommendationPriority = item.postControlRecommendationPriority;
        element.postControlRecommendationPriorityNumber = item.postControlRecommendationPriorityNumber;
        element.requiresMaterial = item.requiresMaterial;
        element.isEstetic = item.isEstetic;
        element.elementPurpose = item.elementPurpose ? item.elementPurpose : 0;

        var elementPhotos = [];

        if (item.elementPhotos) {
            item.elementPhotos.forEach(element => {
                elementPhotos.push(this.workObjectPhotoAdapter.adapt(element));
            });
        }

        element.elementPhotos = elementPhotos;

        return element;
    }

}