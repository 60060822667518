import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { EditService, ExcelExportService, FilterService, GridModule, GroupService, PageService, ResizeService, SearchService, SortService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { AccordionModule, TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { UtilitiesModule } from '../utilities/utilities.module';
import { ButtonComponent } from './button/button.component';
import { ChooseWorkflowDayAndMonthComponent } from './choose-workflow-day-and-month/choose-workflow-day-and-month.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { ErrorCodePageComponent } from './error-code-page/error-code-page.component';
import { HeaderComponent } from './header/header.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ManagerCommentComponent } from './manager-comment/manager-comment.component';
import { MobileAppVersionComponent } from './mobile-app-version/mobile-app-version.component';
import { PhotoFilteringComponent } from './photo-filtering/photo-filtering.component';
import { PhotoGalleryComponent } from './photo-gallery/photo-gallery.component';
import { PhotoComponent } from './photo/photo.component';
import { PreviewManagerCommentComponent } from './preview-manager-comment/preview-manager-comment.component';
import { ProtocolPhotosComponent } from './protocol-photos/protocol-photos.component';
import { ProtocolSignaturesListComponent } from './protocol-signatures-list/protocol-signatures-list.component';
import { ProtocolSignaturesComponent } from './protocol-signatures/protocol-signatures.component';
import { SharedFunctionsService } from './shared-functions.service';
import { SummaryReportsComponent } from './summary-reports/summary-reports.component';
import { TablesWithProtocolsDashboardComponent } from './tables-with-protocols-dashboard/tables-with-protocols-dashboard.component';
import { UserActivityReportComponent } from './user-activity-report/user-activity-report.component';
import { ConstructionLicenseComponent } from './construction-license/construction-license.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
 
@NgModule({
  declarations: [
    ButtonComponent,
    ErrorCodePageComponent,
    HeaderComponent,
    ManageUsersComponent,
    ManagerCommentComponent,
    MobileAppVersionComponent,
    PreviewManagerCommentComponent,
    ProtocolPhotosComponent,
    ProtocolSignaturesComponent,
    ProtocolSignaturesListComponent,
    TablesWithProtocolsDashboardComponent,
    UserActivityReportComponent,
    ChooseWorkflowDayAndMonthComponent,
    SummaryReportsComponent,
    PhotoComponent,
    PhotoGalleryComponent,
    PhotoFilteringComponent,
    DocumentationComponent,
    ConstructionLicenseComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AccordionModule,
    SwitchModule,
    GridModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownListModule,
    MultiSelectModule,
    TooltipModule,
    TreeViewModule,
    DatePickerModule,
    UtilitiesModule,
    PdfViewerModule
  ],
  providers: [
    EditService,
    ToolbarService,
    PageService,
    FilterService,
    SearchService,
    SortService,
    GroupService,
    ExcelExportService,
    ResizeService,
    SharedFunctionsService
  ],
  exports: [
    ButtonComponent,
    ErrorCodePageComponent,
    HeaderComponent,
    ManageUsersComponent,
    ManagerCommentComponent,
    MobileAppVersionComponent,
    PreviewManagerCommentComponent,
    ProtocolPhotosComponent,
    ProtocolSignaturesComponent,
    ProtocolSignaturesListComponent,
    TablesWithProtocolsDashboardComponent,
    UserActivityReportComponent,
    ChooseWorkflowDayAndMonthComponent,
    SummaryReportsComponent,
    AccordionModule,
    SwitchModule,
    GridModule,
    DropDownListModule,
    MultiSelectModule,
    TooltipModule,
    TreeViewModule,
    DatePickerModule,
    UtilitiesModule,
    PhotoComponent,
    PhotoGalleryComponent,
    PhotoFilteringComponent,
    ConstructionLicenseComponent,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }) as any,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }) as any,
    DocumentationComponent
  ]
})
export class SharedModule { }