<app-navbar></app-navbar>
<div class="container-fluid bg-secondary container-min-height">
  <!-- <div class="row">
    <div class="col ml-3 mr-3 mb-2 bg-dark">
      <div class="alert alert-danger text-center">
        Tutaj będą ważne komunikaty
      </div>
    </div>
  </div> -->
  <div>
    <div class="ml-3 mr-3 bg-light">
      <div class="m-2">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>