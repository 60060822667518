import { NavMenuItem } from './nav-menu-item';
import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class NavMenuItemMain extends NavMenuItem {
    constructor(
        public id: string,
        public text: string,
        public routerLink: string,
        public subItems: NavMenuItem[] = null,
        public expanded: boolean = true
    ) {
        super(id, null, text, routerLink);
    }
}

@Injectable()
export class NavMenuItemMainAdapter implements Adapter<NavMenuItemMain> {
    adapt(item: any): NavMenuItemMain {
        return new NavMenuItemMain(
            item.main.id,
            item.main.text,
            item.main.routerLink,
            item.subItems
        )
    }
}