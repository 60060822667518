import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class BuildingSafetyRating {
    constructor(
        public name: string,
        public number: number
    ) { }
}

@Injectable()
export class BuildingSafetyRatingAdapter implements Adapter<BuildingSafetyRating>{
    adapt(item: any): BuildingSafetyRating {
        return new BuildingSafetyRating(
            item.name,
            item.number
        );
    }
}