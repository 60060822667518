import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ProtocolBuildingElement, ProtocolBuildingElementAdapter } from './protocol-building-element';

export class ProtocolTemplate {
    constructor(
        public protocolTypeNumber: number,
        public caseId: string,
        public name: string,
        public protocolElements: ProtocolBuildingElement[]
    ) {
    }
}

@Injectable()
export class ProtocolTemplateAdapter implements Adapter<ProtocolTemplate> {
    constructor(private ProtocolBuildingElementAdapter: ProtocolBuildingElementAdapter) { }
    adapt(item: any): ProtocolTemplate {
        var protocolAnnualElements = [];

        if (item.protocolElements) {
            item.protocolElements.forEach(element => {
                protocolAnnualElements.push(this.ProtocolBuildingElementAdapter.adapt(element));
            });
        }

        return new ProtocolTemplate(
            item.protocolTypeNumber,
            item.caseId,
            item.name,
            protocolAnnualElements
        )
    }
}