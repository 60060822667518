import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ProtocolsSearchOptions {
    constructor(
        public clientShortName: string,
        public taskShortName: string,
        public loadNewestOfType: boolean,
        public loadNeverIncluded: boolean,
        public regenerateProtocols: boolean,
        public controlDateFrom?: Date,
        public controlDateTo?: Date,
        public creationDateFrom?: Date,
        public creationDateTo?: Date,
    ) {
    }
}

@Injectable()
export class ProtocolsSearchOptionsAdapter implements Adapter<ProtocolsSearchOptions> {
    adapt(item: any): ProtocolsSearchOptions {
        if(item.creationDateTo)
            item.creationDateTo = this.setTimeOnEndOfTheDay(item.creationDateTo);

        if(item.controlDateTo)
            item.controlDateTo = this.setTimeOnEndOfTheDay(item.controlDateTo);

        var protocolsSearchOptions = new ProtocolsSearchOptions(
            item.clientShortName,
            item.taskShortName,
            item.loadNewestOfType,
            item.loadNeverIncluded,
            item.regenerateProtocols,
            item.controlDateFrom,
            item.controlDateTo,
            item.creationDateFrom,
            item.creationDateTo
        )

        return protocolsSearchOptions;
    }

    setTimeOnEndOfTheDay(date: any){
        var newDate = new Date(date);
        newDate.setHours(23);
        newDate.setMinutes(59);
        newDate.setSeconds(59);

        return newDate;
    }
}