import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';
import { DocumentType } from './document-type';

export class ReportProtocolDocument {
    public url: string;
    public fileName: string;

    constructor(
        public documentType: DocumentType,
        public documentTypeName: string,
        public protocolType: number,
        public protocolTypeName: string,
        public caseId: string,
        public workObjectId: string,
        public protocolId: string,
        public controlDate: Date,
        public creationDate: Date,
        public authorFullName: string
    ) { }
}

@Injectable()
export class ReportProtocolDocumentAdapter implements Adapter<ReportProtocolDocument>{
    adapt(item: any): ReportProtocolDocument {

        let reportProtocolDocument = new ReportProtocolDocument(
            item.documentType,
            item.documentTypeName,
            item.protocol.protocolType,
            item.protocol.protocolTypeName,
            item.protocol.caseId,
            item.protocol.workObjectId,
            item.protocol.protocolId,
            item.protocol.controlDate,
            item.protocol.creationDate,
            item.protocol.authorFullName
        );

        reportProtocolDocument.controlDate = new Date(reportProtocolDocument.controlDate);
        reportProtocolDocument.controlDate.setHours(0, 0, 0, 0);
        reportProtocolDocument.creationDate = new Date(reportProtocolDocument.creationDate);
        reportProtocolDocument.creationDate.setHours(0, 0, 0, 0);

        return reportProtocolDocument;
    }
}