import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class EngineerSignature {
    constructor(public engineerId: string,
        public engineerNameAndSurname: string) {
    }
}

@Injectable()
export class EngineerSignatureAdapter implements Adapter<EngineerSignature> {
    public adapt(item: any): EngineerSignature {
        return new EngineerSignature(
            item.engineerId,
            item.engineerNameAndSurname);
    }
}