import { Injectable } from '@angular/core';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { Adapter } from './interfaces/adapter.interface';
import { ProtocolGasElement, ProtocolGasElementAdapter } from './protocol-gas-element';
import { DatePipe } from '@angular/common';
import { ProtocolSignature } from './protocol-signature';
import { PreviousControlDateComponent } from '../components/shared-protocols/data-section/previous-control-date/previous-control-date.component';

export class FullGasProtocol {
    public id: string;
    public protocolNumber: string;
    public controlDate: Date;
    public previousControlDate: Date;
    public previousControlDateText: string;
    public nextControlDate: Date;
    public reviewComment: string;
    public protocolGasElements: ProtocolGasElement[] = [];
    public gasTemplate: any;
    public additionalNotes: string;
    public clientTypeNumber: number;
    public canBeUsed?: boolean
    public remarksAndObservations?: string;
    public recommendationsFromLastProtocol?: string;
    public recommendationsNotImplemented?: string;
    public isHalfyear: boolean;
    public clientTaskId: string;
    public protocolSignatureWithCaseIds: ProtocolSignature;

    constructor(public caseId: string,
        public sapId: string,
        public inventoryId: string,
        public jnDesignation: string) { }
}

@Injectable()
export class FullGasProtocolAdapter implements Adapter<FullGasProtocol>{
    constructor(private protocolGasElementAdapter: ProtocolGasElementAdapter) { }

    adapt(item: any): FullGasProtocol {
        var protocolGasElements = [];

        if (item.protocolGasElements) {
            item.protocolGasElements.forEach(element => {
                protocolGasElements.push(this.protocolGasElementAdapter.adapt(element));
            });
        }

        var gasProtocol = new FullGasProtocol(
            item.caseId,
            item.sapId,
            item.inventoryId,
            item.jnDesignation);

        gasProtocol.id = item.id;
        gasProtocol.protocolNumber = item.protocolNumber;
        gasProtocol.controlDate = item.controlDate;
        gasProtocol.previousControlDate = item.previousControlDate;
        gasProtocol.previousControlDateText = item.prevControlDateText;
        gasProtocol.nextControlDate = item.nextControlDate;
        gasProtocol.reviewComment = item.reviewComment;
        gasProtocol.protocolGasElements = protocolGasElements;
        gasProtocol.additionalNotes = item.additionalNotes;
        gasProtocol.clientTypeNumber = item.clientTypeNumber;
        gasProtocol.canBeUsed = item.canBeUsed;
        gasProtocol.remarksAndObservations = item.remarksAndObservations;
        gasProtocol.recommendationsFromLastProtocol = item.recommendationsFromLastProtocol;
        gasProtocol.recommendationsNotImplemented = item.recommendationsNotImplemented;
        gasProtocol.isHalfyear = item.isHalfyear;
        gasProtocol.clientTaskId = item.clientTaskId;

        return gasProtocol;
    }
}

@Injectable()
export class FullGasProtocolFormManager implements ModelFormManager<FullGasProtocol>{
    private protocolGasElementsForm = this.formBuilder.group({
        id: [null],
        elementType: [null, Validators.required],
        elementRating: [null],
        remarksAndObservations: [null]
    });

    private gasProtocolForm = this.formBuilder.group({
        id: [null],
        caseId: [null],
        sapId: [null],
        inventoryId: [null],
        jnDesignation: [null],
        protocolNumber: [null],
        controlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        previousControlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        previousControlDateText: [null],
        nextControlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        canBeUsed: [true],
        remarksAndObservations: [null],
        recommendationsFromLastProtocol: [null],
        recommendationsNotImplemented: [null],
        protocolGasElements: this.formBuilder.array([this.protocolGasElementsForm])
    });

    constructor(private formBuilder: UntypedFormBuilder,
        private datepipe: DatePipe) { }

    buildForm(model: FullGasProtocol): UntypedFormGroup {
        if (!model)
            return this.gasProtocolForm;

        this.gasProtocolForm = this.formBuilder.group({
            id: [model.id],
            caseId: [model.caseId],
            sapId: [model.sapId],
            inventoryId: [model.inventoryId],
            jnDesignation: [model.jnDesignation],
            protocolNumber: [model.protocolNumber],
            controlDate: [model.controlDate ? this.datepipe.transform(model.controlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            previousControlDate: [model.previousControlDate ? this.datepipe.transform(model.previousControlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            previousControlDateText: [model.previousControlDateText],
            nextControlDate: [model.nextControlDate ? this.datepipe.transform(model.nextControlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            canBeUsed: [model.canBeUsed],
            remarksAndObservations: [model.remarksAndObservations],
            recommendationsFromLastProtocol: [model.recommendationsFromLastProtocol],
            recommendationsNotImplemented: [model.recommendationsNotImplemented],
            protocolGasElements: this.formBuilder.array([this.protocolGasElementsForm])
        });

        if (!model.protocolGasElements || model.protocolGasElements.length == 0) {
            this.gasProtocolForm = this.addElementsBaseOnTemplate(model.gasTemplate);
        } else {
            var elementsArray = (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray);
            elementsArray.removeAt(0);
            model.protocolGasElements.forEach(element => {
                var elementForm = this.formBuilder.group({
                    id: [element.id],
                    elementType: [element.elementType, Validators.required],
                    elementRating: [element.elementRating],
                    remarksAndObservations: [element.remarksAndObservations]
                });
                elementsArray.push(elementForm);
            });
        }

        return this.gasProtocolForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.gasProtocolForm;
    }

    public addElementsBaseOnTemplate(gasProtocolTemplate: any): UntypedFormGroup {
        (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray).removeAt(0);

        gasProtocolTemplate.categories.forEach(category => {
            category.types.forEach(type => {
                var elementsArray = (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray);
                var element = this.formBuilder.group({
                    id: [null],
                    elementType: [type.elementName, Validators.required],
                    elementRating: [''],
                    remarksAndObservations: ['']
                });
                elementsArray.push(element);
            });
        });

        return this.gasProtocolForm;
    }

    public removeElement(index: number) {
        var elementsFormArray = (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray);
        if (elementsFormArray.length > 1)
            elementsFormArray.removeAt(index);
        return this.gasProtocolForm;
    }

    public removeAllElementsButFirst() {
        var elementsFormArray = (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 1; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.gasProtocolForm;
    }

    public addNewElementBehindSelected(index: number) {
        var elementsFormArray = (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray);
        var selectedElementForm = (elementsFormArray.controls[index] as UntypedFormGroup);
        var newElementForm = this.formBuilder.group({
            id: [null],
            elementType: [selectedElementForm.get('elementType').value, Validators.required],
            elementRating: [''],
            remarksAndObservations: ['']
        });
        elementsFormArray.insert(index + 1, newElementForm);

        elementsFormArray.updateValueAndValidity();

        return this.gasProtocolForm;
    }

    public loadElements(elements: ProtocolGasElement[]){
        var elementsFormArray = (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray);
        while (elementsFormArray.length !== 0) {
            elementsFormArray.removeAt(0)
        }
        elements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                elementType: [element.elementType, Validators.required],
                elementRating: [element.elementRating],
                remarksAndObservations: [element.remarksAndObservations]
            });

            (this.gasProtocolForm.get('protocolGasElements') as UntypedFormArray).push(elementForm);
        });
    }
}