import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class DocumentationStatusRating {
    constructor(
        public name: string,
        public number: number
    ) { }
}

@Injectable()
export class DocumentationStatusRatingAdapter implements Adapter<DocumentationStatusRating>{
    adapt(item: any): DocumentationStatusRating {
        return new DocumentationStatusRating(
            item.name,
            item.number
        );
    }
}