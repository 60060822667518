import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class License {
    constructor(
        public fileName: string,
        public uploadDate: Date,
        public expirationDate: Date,
        public linkToPdf: string) { }
}

@Injectable()
export class LicenseAdapter implements Adapter<License> {
    adapt(item: any): License {
        return new License(
            item.fileName,
            new Date(item.uploadDate),
            new Date(item.expirationDate),
            item.linkToPdf
        );
    }
}