import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class DictionaryObject {
    constructor(
        public name: string,
        public number: number,
        public isDefault: boolean
    ) { }
}

@Injectable()
export class DictionaryObjectAdapter implements Adapter<DictionaryObject>{
    adapt(item: any): DictionaryObject {
        return new DictionaryObject(
            item.name,
            item.number,
            item.isDefault ? item.isDefault : false
        );
    }
}