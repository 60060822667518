import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ClientSimpleDetails {

    constructor(
        public fullName: string,
        public shortName: string,
        public description: string,
        public descriptionForFiltering: string,
        public executorDescription: string,
        public executorDescriptionForFiltering: string
    ) {
    }
}

@Injectable()
export class ClientSimpleDetailsAdapter implements Adapter<ClientSimpleDetails> {
    adapt(item: any): ClientSimpleDetails {
        return new ClientSimpleDetails(
            item.fullName,
            item.shortName,
            item.description,
            item.description.toString().replaceAll("<br/>", " "),
            item.executorDescription,
            item.executorDescription.toString().replaceAll("<br/>", " ")
        )
    }
}