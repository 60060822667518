import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CacheService, GetNewestActiveMobileVersion } from 'src/app/services/cache.service';

@Component({
  selector: 'app-mobile-app-version',
  templateUrl: './mobile-app-version.component.html',
  styleUrls: ['./mobile-app-version.component.scss']
})
export class MobileAppVersionComponent implements OnInit, OnDestroy {

  public versionNumber: string;
  public versionName: string;
  public publishDate: Date;
  public url: string;
  private newestActiveMobileVersionSub: Subscription;

  constructor(private cacheService: CacheService) { }

  ngOnInit() {
    this.newestActiveMobileVersionSub = this.cacheService.getData(GetNewestActiveMobileVersion)
      .pipe(first())
      .subscribe(
        data => {
          if (data) {
            this.versionNumber = data.versionNumber;
            this.versionName = data.versionName;
            this.publishDate = data.publishDate;
            this.url = data.url;
          }
        });
  }

  ngOnDestroy(): void {
    if (this.newestActiveMobileVersionSub) {
      this.newestActiveMobileVersionSub.unsubscribe();
    }
  }
}
