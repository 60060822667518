import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class UserCreation {
    constructor(
        public userId: string,
        public userName: string,
        public firstName: string,
        public lastName: string,
        public email: string,
        public role: string,
        public membershipNumbers: string,
        public licenseNumbers: string,
        public signature: string,
        public pictureUrl?: string
    ) {
    }
}

@Injectable()
export class UserCreationAdapter implements Adapter<UserCreation> {
    adapt(item: any): UserCreation {
        return new UserCreation(
            item.userId.value,
            item.userName.value,
            item.firstName.value,
            item.lastName.value,
            item.email.value,
            item.role.value,
            item.membershipNumbers.value,
            item.licenseNumbers.value,
            item.signature.value,
            item.pictureUrl.value
        )
    }
}