import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { UserPickItem } from './user-pick-item';
import { PhotoStatuses } from "src/app/models/photo-status";
import { Roles } from './roles-enum';

export class WorkObjectListItem {
    public Lp: number;
    public isNew: boolean = false;
    public isUpdated: boolean = false;
    public managersString: string;
    public engineersString: string;
    public clientsString: string;
    public actualAnnualProtocol: boolean;
    public actualQuinquennialProtocol: boolean;
    public actualChimneyProtocol: boolean;
    public actualGasProtocol: boolean;
    public actualHalfYearProtocol: boolean;
    public actualDocumentationControl: boolean;
    public lastPhotoUpdate: PhotoStatuses;

    constructor(public CaseId: string,
        public JnDesignation: string,
        public Region: string,
        public County: string,
        public SapId: string,
        public InventoryId: string,
        public BuildingType: string,
        public Description: string,
        public Group: string,
        public BuildingArea: string,
        public FunctionalArea: string,
        public RoofArea: string,
        public PreviousHalfYearControlDate: Date,
        public PreviousAnnualControlDate: Date,
        public PreviousQuinquennialControlDate: Date,
        public SAT_FIAA: number,
        public ExploitationStartDate: Date,
        public AttritionPeriod: string,
        public YearsTillDate: string,
        public RemainingTime: string,
        public VerifiedLifeTime: string,
        public CurrentAnnualControlDate: Date,
        public CurrentQuinquennialControlDate: Date,
        public CurrentChimneyControlDate: Date,
        public CurrentHalfYearControlDate: Date,
        public CurrentGasControlDate: Date,
        public LastPhotoDate: Date,
        public CurrentDocumentationControlControlDate: Date,
        public PostControlRecommendations: string,
        public PostControlRecommendations2ndGrade: string,
        public PostControlRecommendations3rdGrade: string,
        public PostControlHalfYearRecommendations: string,
        public PostControlHalfYearRecommendations2ndGrade: string,
        public PostControlHalfYearRecommendations3rdGrade: string,
        public PostControlQuinquennialRecommendations: string,
        public PostControlQuinquennialRecommendations2ndGrade: string,
        public PostControlQuinquennialRecommendations3rdGrade: string,
        public IsActive: boolean,
        public BuildingStateRating: string,
        public Longitude: string,
        public Latitude: string,
        public HalfYearRequired: boolean,
        public AssignedUsers: UserPickItem[]) {
        if (AssignedUsers) {
            if (AssignedUsers.some(au => au.userRole == Roles.Inżynier))
            {
                var engineers = AssignedUsers.filter(au => au.userRole == Roles.Inżynier);
                this.engineersString = engineers.map((s) => 
                        `${s.firstName} ${s.lastName}`).join(', ');
            }            
           
            if (AssignedUsers.some(au => au.userRole == Roles.Klient))
            {
                var clients = AssignedUsers.filter(au => au.userRole == Roles.Klient);
                this.clientsString = clients.map((s) => 
                        `${s.firstName} ${s.lastName}`).join(', ');
            }

            if (AssignedUsers.some(au => au.userRole == Roles.Menadżer))
            {
                var clients = AssignedUsers.filter(au => au.userRole == Roles.Menadżer);
                this.managersString = clients.map((s) => 
                        `${s.firstName} ${s.lastName}`).join(', ');
            }
        }
    }
}

@Injectable()
export class WorkObjectListItemAdapter implements Adapter<WorkObjectListItem>{
    public adapt(item: any): WorkObjectListItem {
        var workObjectListItem = new WorkObjectListItem(
            item.caseId,
            item.jnDesignation,
            item.region,
            item.county,
            item.sapId,
            item.inventoryId,
            item.buildingType,
            item.description,
            item.group,
            item.buildingArea,
            item.functionalArea,
            item.roofArea,
            item.previousHalfYearControlDate != null ? new Date(item.previousHalfYearControlDate) : null,
            item.previousAnnualControlDate != null ? new Date(item.previousAnnualControlDate) : null,
            item.previousQuinquennialControlDate != null ? new Date(item.previousQuinquennialControlDate) : null,
            item.saT_FIAA,
            item.exploitationStartDate != null ? new Date(item.exploitationStartDate) : null,
            item.attritionPeriod,
            item.yearsTillDate,
            item.remainingTime,
            item.verifiedLifeTime,
            item.currentAnnualControlDate != null ? new Date(item.currentAnnualControlDate) : null,
            item.currentQuinquennialControlDate != null ? new Date(item.currentQuinquennialControlDate) : null,
            item.currentChimneyControlDate != null ? new Date(item.currentChimneyControlDate) : null,
            item.currentHalfYearControlDate != null ? new Date(item.currentHalfYearControlDate) : null,
            item.currentGasControlDate != null ? new Date(item.currentGasControlDate) : null,
            item.lastPhotoDate != null ? new Date(item.lastPhotoDate) : null,
            item.currentDocumentationControlControlDate != null ? new Date(item.currentDocumentationControlControlDate) : null,
            item.postControlRecommendations,
            item.postControlRecommendations2ndGrade,
            item.postControlRecommendations3rdGrade,
            item.postControlHalfYearRecommendations,
            item.postControlHalfYearRecommendations2ndGrade,
            item.postControlHalfYearRecommendations3rdGrade,
            item.postControlQuinquennialRecommendations,
            item.postControlQuinquennialRecommendations2ndGrade,
            item.postControlQuinquennialRecommendations3rdGrade,
            item.isActive,
            item.buildingStateRating,
            item.longitude,
            item.latitude,
            item.halfYearRequired,
            item.assignedUsers
        );

        return workObjectListItem;
    }

    createObjectWithDatesWithoutTimezoneInformation(workObject: WorkObjectListItem): any {
        let preparedWorkObject =
        {
            CaseId: workObject.CaseId,
            JnDesignation: workObject.JnDesignation,
            Region: workObject.Region,
            County: workObject.County,
            SapId: workObject.SapId,
            InventoryId: workObject.InventoryId,
            BuildingType: workObject.BuildingType,
            Description: workObject.Description,
            Group: workObject.Group,
            BuildingArea: workObject.BuildingArea,
            FunctionalArea: workObject.FunctionalArea,
            RoofArea: workObject.RoofArea,
            PreviousHalfYearControlDate: workObject.PreviousHalfYearControlDate == null ? null : workObject.PreviousHalfYearControlDate.toDateString(),
            PreviousAnnualControlDate: workObject.PreviousAnnualControlDate == null ? null : workObject.PreviousAnnualControlDate.toDateString(),
            PreviousQuinquennialControlDate: workObject.PreviousQuinquennialControlDate == null ? null : workObject.PreviousQuinquennialControlDate.toDateString(),
            SAT_FIAA: workObject.SAT_FIAA,
            ExploitationStartDate: workObject.ExploitationStartDate == null ? null : workObject.ExploitationStartDate.toDateString(),
            AttritionPeriod: workObject.AttritionPeriod,
            YearsTillDate: workObject.YearsTillDate,
            RemainingTime: workObject.RemainingTime,
            VerifiedLifeTime: workObject.VerifiedLifeTime,
            CurrentHalfYearControlDate: workObject.CurrentHalfYearControlDate == null ? null : workObject.CurrentHalfYearControlDate.toDateString(),
            CurrentAnnualControlDate: workObject.CurrentAnnualControlDate == null ? null : workObject.CurrentAnnualControlDate.toDateString(),
            CurrentQuinquennialControlDate: workObject.CurrentQuinquennialControlDate == null ? null : workObject.CurrentQuinquennialControlDate.toDateString(),
            PostControlRecommendations: workObject.PostControlRecommendations,
            PostControlRecommendations2ndGrade: workObject.PostControlRecommendations2ndGrade,
            PostControlRecommendations3rdGrade: workObject.PostControlRecommendations3rdGrade,
            PostControlHalfYearRecommendations: workObject.PostControlHalfYearRecommendations,
            PostControlHalfYearRecommendations2ndGrade: workObject.PostControlHalfYearRecommendations2ndGrade,
            PostControlHalfYearRecommendations3rdGrade: workObject.PostControlHalfYearRecommendations3rdGrade,
            PostControlQuinquennialRecommendations: workObject.PostControlQuinquennialRecommendations,
            PostControlQuinquennialRecommendations2ndGrade: workObject.PostControlQuinquennialRecommendations2ndGrade,
            PostControlQuinquennialRecommendations3rdGrade: workObject.PostControlQuinquennialRecommendations3rdGrade,
            BuildingStateRating: workObject.BuildingStateRating,
            Longitude: workObject.Longitude,
            Latitude: workObject.Latitude,
            IsNew: workObject.isNew,
            IsUpdated: workObject.isUpdated
        }

        return preparedWorkObject;
      }
}