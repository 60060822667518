import { Injectable } from '@angular/core';
import { MultiSelectChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { Tag, TagAdapter } from 'src/app/models/tag';
import { WorkObjectPhoto } from 'src/app/models/work-object-photo';
import { WorkObjectPhotoExtra } from 'src/app/models/work-object-photo-extra';

@Injectable({
    providedIn: 'root',
})
export class SharedFunctionsService {

    constructor(private tagAdapter: TagAdapter) { }

    public modifyMultiSelectChangeEventArgsAndCheckIfShouldReturn(e: MultiSelectChangeEventArgs, photo: WorkObjectPhotoExtra | WorkObjectPhoto, tagsInPhoto: number[]): boolean {
        e.oldValue = photo.tags.map(t => t.id);

        if (!e.value)
            e.value = [];

        if (!photo.name) {
            return true;
        }

        //In some cases when we don't have tags in photo, e.value = [""]. Or in other cases e.value = ["", 1, 2] etc.
        if (e.value && e.value[0] == "") {
            e.value = e.value.splice(0, 1);
            return true;
        }

        //Nothing changed but onChange called after signalR update
        if (e.value && e.value.length > 0 && this.arraysHaveSameValues((e.value as number[]), tagsInPhoto)) {
            return true;
        }

        //Contains newly added custom values - it will be handled by oncustomValueSelection
        if (e.value && e.value.length > 0 && this.containsDecimalNumbers(e.value)) {
            return true;
        }

        //Contains newly added custom values (before signalR update)
        if (e.oldValue && e.oldValue.length > 0 && this.containsDecimalNumbers(e.oldValue)) {
            return true;
        }

        return false;
    }

    public getDifference(arr1: number[], arr2: number[]): number[] {
        const difference1 = arr1.filter(item => !arr2.includes(item));
        const difference2 = arr2.filter(item => !arr1.includes(item));
        return [...difference1, ...difference2];
    }

    public containsDecimalNumbers(arr: any[]): boolean {
        for (const element of arr) {
            if (typeof element === 'number' && !Number.isInteger(element)) {
                return true;
            }
        }
        return false;
    }

    public arraysHaveSameValues(arr1: any[], arr2: any[]): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }

        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true;
    }

    public adaptTags(tags: any[]): Tag[] {
        var tagsProcessed: Tag[] = [];
        tags.forEach(tag => {
            tagsProcessed.push(this.tagAdapter.adapt(tag));
        });
        return tagsProcessed;
    }
}