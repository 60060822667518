import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AddWorkObject } from './add-work-object';

export class ClientTaskCreation {
    public workObjects: AddWorkObject[];

    constructor(
        public name: string,
        public shortName: string,
        public clientShortName: string
    ) { }
}

@Injectable()
export class ClientTaskCreationAdapter implements Adapter<ClientTaskCreation>{
    public adapt(item: any): ClientTaskCreation {
        return new ClientTaskCreation(
            item.name,
            item.shortName,
            item.clientShortName
        );
    }
}

@Injectable()
export class ClientTaskCreationFormManager implements ModelFormManager<ClientTaskCreation>{
    private clientTaskCreationForm = this.formBuilder.group({
        name: ['', Validators.required],
        shortName: ['',
            [
                Validators.required,
                Validators.maxLength(25),
                Validators.pattern('[a-zA-Z0-9 ]*')
            ]],
        clientShortName: ['', Validators.required]
    });

    constructor(private formBuilder: UntypedFormBuilder) { }

    buildForm(model: ClientTaskCreation): UntypedFormGroup {
        if (!model)
            return this.clientTaskCreationForm;

        return this.formBuilder.group({
            name: [model.name == null ? '' : model.name, Validators.required],
            shortName: [model.shortName == null ? '' : model.shortName, Validators.required],
            clientShortName: [model.clientShortName == null ? '' : model.clientShortName, Validators.required]
        });
    }

    getEmptyForm(): UntypedFormGroup {
        return this.clientTaskCreationForm;
    }

    getFormForClientShortName(clientShortName: string): UntypedFormGroup {
        return this.formBuilder.group({
            name: ['', Validators.required],
            shortName: ['',
                [
                    Validators.required,
                    Validators.maxLength(25),
                    Validators.pattern('[a-zA-Z0-9 ]*')
                ]],
            clientShortName: [clientShortName, Validators.required]
        });
    }
}