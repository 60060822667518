import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { CaseIdWithCoordinates, CaseIdWithCoordinatesAdapter } from "../models/case-id-with-coordinates";
import { CaseIdWithDate, CaseIdWithDateAdapter } from "../models/case-id-with-date";
import { Tag, TagAdapter } from "../models/tag";
import { WorkObjectDetailsAdapter } from '../models/work-object-details';
import { WorkObjectListItemAdapter } from "../models/work-object-list-item";

@Injectable()
export class WorkObjectService {
    constructor(
        private http: HttpClient,
        private workObjectDetailsAdapter: WorkObjectDetailsAdapter,
        private workObjectListItemAdapter: WorkObjectListItemAdapter,
        private caseIdWithCoordinatesAdapter: CaseIdWithCoordinatesAdapter,
        private caseIdWithDateAdapter: CaseIdWithDateAdapter,
        private tagAdapter: TagAdapter) { }

    public getWorkObject(caseId: string, afterSignalRUpdate = false) {
        return this.http.get<any>('/api/workObjects/GetWorkObject/' + caseId).pipe(map(data => {
            if (!data)
                return null;

            if (afterSignalRUpdate)
                return this.workObjectListItemAdapter.adapt(data);
            return this.workObjectDetailsAdapter.adapt(data);
        }));
    }

    public getClientNotActiveWorkObjects(clientShortName: string, shortName: string) {
        return this.http.get<any>('/api/workObjects/GetClientNotActiveWorkObjects/' + clientShortName + '/' + shortName).pipe(map(data => {
            if (!data)
                return null;

            return data
        }));
    }

    public prepareAllTags(data: any) {
        if (!data)
            return null;

        var tags: Tag[] = [];
        data.tags.forEach(tag => {
            tags.push(this.tagAdapter.adapt(tag));
        });

        return tags; 
    }

    public changeStatusOfIsActive(caseIds: string[], status: boolean, clientShortName: string) {
        var object =
        {
            CaseIds: caseIds,
            ClientShortName: clientShortName,
            Status: status
        }
        return this.http.post<any>('/api/workObjects/UpdateActivity', object)
            .pipe(map(data => {
                return data;
            }));
    }

    public updateWorkObjecsGPSData(caseIds: string[]) {
        return this.http.post<any>('/api/workObjects/UpdateGPSData', { CaseIds: caseIds })
            .pipe(map(data => {

                var modifiedWorkObjects: CaseIdWithCoordinates[] = [];
                data.modifiedWorkObjects.forEach(wo => {
                    modifiedWorkObjects.push(this.caseIdWithCoordinatesAdapter.adapt(wo));
                });

                return modifiedWorkObjects;
            }));
    }

    public updateWorkObjecsLastPhotoDate(caseIds: string[]) {
        return this.http.post<any>('/api/workObjects/UpdateLastPhotoDate', { CaseIds: caseIds })
            .pipe(map(data => {

                var modifiedWorkObjects: CaseIdWithDate[] = [];
                data.modifiedWorkObjects.forEach(wo => {
                    modifiedWorkObjects.push(this.caseIdWithDateAdapter.adapt(wo));
                });

                return modifiedWorkObjects;
            }));
    }

    public updateTagsInPhoto(caseId: string, photoName: string, tagNamesAdded: string[], tagNamesDeleted: string[]) {
        return this.http.post<any>('/api/workObjects/UpdateTagsInPhoto',
            {
                CaseId: caseId,
                PhotoName: photoName,
                TagNamesAdded: tagNamesAdded,
                TagNamesDeleted: tagNamesDeleted
            })
            .pipe(map(data => {
                return data;
            }));
    }

    public editAdditionalNotes(caseId: string, additionalNotes: string) {
        return this.http.post<any>('/api/workObjects/EditAdditionalNotes', { caseId: caseId, additionalNotes: additionalNotes })
            .pipe(map(data => {
                return data;
            }));
    }
}