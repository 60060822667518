import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { FileInfo } from '@syncfusion/ej2-inputs';
import { map, tap } from 'rxjs/operators';
import { AssignUsers } from '../models/assign-users';
import { ClientCreation } from '../models/client-creation';
import { ClientDetails, ClientDetailsAdapter } from '../models/client-details';
import { ClientSimpleDetails, ClientSimpleDetailsAdapter } from '../models/client-simple-details';
import { ClientTaskDashboard, ClientTaskDashboardAdapter } from '../models/client-task-dashboard';
import { DictionaryObject, DictionaryObjectAdapter } from '../models/dictionary-object';
import { Roles } from "../models/roles-enum";
import { UnassignUsers } from '../models/unassign-users';
import { UserPickItem, UserPickItemAdapter } from '../models/user-pick-item';

const apiClients = "/api/clients";
const apiClientTasks = "/api/clientTasks";

@Injectable()
export class ClientService {
    constructor(
        private http: HttpClient,
        private clientSimpleDetailsAdapter: ClientSimpleDetailsAdapter,
        private clientDetailsAdapter: ClientDetailsAdapter,
        private clientTasksDashboardAdapter: ClientTaskDashboardAdapter,
        private userPickItemAdapter: UserPickItemAdapter,
        private dictionaryObjectAdapter: DictionaryObjectAdapter
    ) { }

    public prepareClientsDetailsAfterGetAllClients(data: any) {
        if (!data || !data.clientsSimpleDetails)
            return null;

        var clientDetails: ClientSimpleDetails[] = [];
        for (const userDetailDto of data.clientsSimpleDetails) {
            clientDetails.push(this.clientSimpleDetailsAdapter.adapt(userDetailDto));
        }

        return clientDetails;
    }

    public prepareClientTasks(data: any) {
        if (!data || !data.clientTasksDashboards)
            return null;

        var clientTasksDashboards: ClientTaskDashboard[] = [];
        for (const clientTaskDto of data.clientTasksDashboards) {
            clientTasksDashboards.push(this.clientTasksDashboardAdapter.adapt(clientTaskDto));
        }

        return clientTasksDashboards;
    }

    public prepareClientTypes(data: any) {
        if (!data || !data.types)
            return null;

        var types: DictionaryObject[] = [];
        for (const type of data.types) {
            types.push(this.dictionaryObjectAdapter.adapt(type));
        }

        return types;
    }

    public prepareUsers(data: any) {
        if (!data || !data.users)
            return null;

        var users: UserPickItem[] = [];
        for (const user of data.users) {
            users.push(this.userPickItemAdapter.adapt(user));
        }

        return users;
    }

    public createClient(clientCreation: ClientCreation) {
        return this.http.post<any>(`${apiClients}/CreateClient`, clientCreation)
            .pipe(tap(data => {
                return data;
            }));
    }

    public deleteClient(shortName: string) {
        return this.http.post<any>(`${apiClients}/DeleteClient`, { shortName: shortName })
            .pipe(map(data => {
                return data;
            }));
    }

    public getClient(shortName: string) {
        return this.http.get<any>(`${apiClients}/GetClient/${shortName}`).pipe(map(data => {
            if (!data)
                return null;

            var clientDetails = this.clientDetailsAdapter.adapt(data);

            return clientDetails;
        }));
    }

    public editClient(editedClient: ClientDetails) {
        return this.http.post<any>(`${apiClients}/editClient`, editedClient)
            .pipe(map(data => {
                return data;
            }));
    }

    public sendFile(file: FileInfo) {
        return this.http.post<any>(`${apiClientTasks}/UploadObjectsFile`, file.rawFile, { headers: { "Content-Type": file.type } })
            .pipe(map(data => {
                return data;
            }));
    }

    public createClientTask(clientTaskCreation: any) {
        return this.http.post<any>(`${apiClientTasks}/CreateClientTask`, clientTaskCreation)
            .pipe(map(data => {
                return data;
            }));
    }

    public getClientTask(clientShortName: string, shortName: string) {
        return this.http.get<any>(`${apiClientTasks}/GetClientTask/${clientShortName}/${shortName}`).pipe(map(data => {
            return data;
        }));
    }

    public editClientTask(clientTask: any) {
        return this.http.post<any>(`${apiClientTasks}/editClientTask`, clientTask)
            .pipe(map(data => {
                return data;
            }));
    }

    public ChangeStatusOfIsDeleted(shortName: string, clientShortName: string, clientFullName: string, status: boolean) {
        var object =
        {
            ShortName: shortName,
            ClientShortName: clientShortName,
            ClientFullName: clientFullName,
            Status: status
        }
        return this.http.post<any>(`${apiClientTasks}/ChangeStatusOfIsDeleted`, object)
            .pipe(map(data => {
                return data;
            }));
    }

    public assignUsers(assignUsers: AssignUsers) {
        let commandAddress = "AssignEngineersWithChimneySweeps";
        if (assignUsers.role == Roles.Menadżer)
            commandAddress = "AssignManagers";
        if (assignUsers.role == Roles.Klient)
            commandAddress = "AssignClients";

        return this.http.post<any>(`${apiClientTasks}/${commandAddress}`, assignUsers)
            .pipe(map(data => {
                return data;
            }));
    }

    public unassignUsers(unassignUsers: UnassignUsers) {
        let commandAddress = "UnassignEngineersWithChimneySweeps";
        if (unassignUsers.role == Roles.Menadżer)
            commandAddress = "UnassignManagers";
        if (unassignUsers.role == Roles.Klient)
            commandAddress = "UnassignClients";

        return this.http.post<any>(`${apiClientTasks}/${commandAddress}`, unassignUsers)
            .pipe(map(data => {
                return data;
            }));
    }

    public canClientGenerateExcelSummary(clientShortName: string) {
        return this.http.get<any>(`${apiClientTasks}/canClientGenerateExcelSummary/${clientShortName}`).pipe(map(data => {
            return data;
        }));
    }
}