import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class DocumentsForProtocol {
    constructor(
        public id: string,
        public hasDoc: boolean,
        public docUrl: string,
        public hasSignedPdf: boolean,
        public signedPdfUrl: string,
        public hasPdf: boolean,
        public pdfUrl: string
    ) { }
}

@Injectable()
export class DocumentsForProtocolAdapter implements Adapter<DocumentsForProtocol>{
    adapt(item: any): DocumentsForProtocol {
        return new DocumentsForProtocol(
            item.id,
            item.hasDoc,
            item.docUrl,
            item.hasSignedPdf,
            item.signedPdfUrl,
            item.hasPdf,
            item.pdfUrl
        );
    }
}