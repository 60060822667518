import { Injectable } from '@angular/core';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms';
import { WorkObjectAdministrator, WorkObjectAdministratorAdapter } from './work-object-administrator';
import { ProtocolBuildingElement, ProtocolBuildingElementAdapter } from './protocol-building-element';
import { PreviousPostControlRecommendation, PreviousPostControlRecommendationAdapter } from './previous-post-control-recommendation';
import { ProtocolRecommendationElementAdapter, ProtocolRecommendationElement } from "./protocol-recommendation-element";
import { Adapter } from './interfaces/adapter.interface';
import { DatePipe } from '@angular/common';
import { ProtocolExtension, ProtocolExtensionAdapter, ProtocolExtensionFormManager } from './protocol-extension';
import { ProtocolComplaint, ProtocolComplaintFormManager } from './protocol-complaint';
import { ProtocolLodzElement, ProtocolLodzElementFormManager } from './protocol-lodz-element';
import { ProtocolSignature } from './protocol-signature';

export class FullAnnualProtocol {
    public id: string;
    public protocolNumber: string;
    public controlDate: Date;
    public previousControlDate: Date;
    public previousControlDateText: string;
    public buildingStateRating: string;
    public buildingStateRatingNumber?: number;
    public buildingSafetyRating: string;
    public buildingSafetyRatingNumber?: number;
    public suitabilityForExploitation: string;
    public suitabilityForExploitationNumber?: number;
    public remainingTime?: number;
    public technicalDescription: string;
    public innerInstallations: string;
    public mainPhoto: string;
    public mainPhotoUrl: string;
    public objectMainPhoto: string;
    public reviewComment: string;
    public previousPostControlRecommendationsCompletionSummary: string;
    public protocolAnnualElements: ProtocolBuildingElement[] = [];
    public protocolRecommendationElements: ProtocolRecommendationElement[] = [];
    public annualTemplate: any;
    public additionalNotes: string;
    public canEditPreviousPostControlRecommendations = false;
    public canAddPreviousPostControlRecommendations = false;
    public methodsOfUse: string;
    public protocolExtension: ProtocolExtension;
    public clientTaskId: string;
    public clientTypeNumber: number;
    public protocolSignatureWithCaseIds: ProtocolSignature;

    constructor(public caseId: string,
        public sapId: string,
        public inventoryId: string,
        public jnDesignation: string,
        public administrators: WorkObjectAdministrator[],
        public previousPostControlRecommendations: PreviousPostControlRecommendation[]) { }
}

@Injectable()
export class FullAnnualProtocolAdapter implements Adapter<FullAnnualProtocol>{
    constructor(private workObjectAdministratorAdapter: WorkObjectAdministratorAdapter,
        private previousPostControlRecommendationAdapter: PreviousPostControlRecommendationAdapter,
        private protocolAnnaulElementAdapter: ProtocolBuildingElementAdapter,
        private protocolRecommendationElementAdapter: ProtocolRecommendationElementAdapter,
        private protocolExtensionAdapter: ProtocolExtensionAdapter) { }

    adapt(item: any): FullAnnualProtocol {
        var annualProtocol = this.adaptGeneric(item);
        annualProtocol.protocolExtension = item.protocolExtension ? this.protocolExtensionAdapter.adapt(item.protocolExtension) : null;

        return annualProtocol;
    }

    adaptBeforeSaving(item: any): FullAnnualProtocol {
        var annualProtocol = this.adaptGeneric(item);
        annualProtocol.protocolExtension = item.protocolExtension ? this.protocolExtensionAdapter.adaptBeforeSaving(item.protocolExtension) : null;

        return annualProtocol;
    }

    private adaptGeneric(item: any): FullAnnualProtocol {
        var administrators = [];

        if (item.administrators) {
            item.administrators.forEach(administrator => {
                administrators.push(this.workObjectAdministratorAdapter.adapt(administrator));
            });
        }

        var previousPostControlRecommendations = [];

        if (item.previousPostControlRecommendations) {
            item.previousPostControlRecommendations.forEach(recommendation => {
                previousPostControlRecommendations.push(this.previousPostControlRecommendationAdapter.adapt(recommendation));
            });
        }

        var protocolAnnualElements = [];

        if (item.protocolAnnualElements) {
            item.protocolAnnualElements.forEach(element => {
                protocolAnnualElements.push(this.protocolAnnaulElementAdapter.adapt(element));
            });
        }

        var protocolRecommendationElements = [];

        if (item.protocolRecommendationElements) {
            item.protocolRecommendationElements.forEach(element => {
                protocolRecommendationElements.push(this.protocolRecommendationElementAdapter.adapt(element));
            });
        }

        var annualProtocol = new FullAnnualProtocol(
            item.caseId,
            item.sapId,
            item.inventoryId,
            item.jnDesignation,
            administrators,
            previousPostControlRecommendations);

        annualProtocol.id = item.id;
        annualProtocol.protocolNumber = item.protocolNumber;
        annualProtocol.controlDate = item.controlDate;
        annualProtocol.previousControlDate = item.previousControlDate;
        annualProtocol.previousControlDateText = item.previousControlDateText;
        annualProtocol.buildingStateRating = item.buildingStateRating;
        annualProtocol.buildingStateRatingNumber = item.buildingStateRatingNumber;
        annualProtocol.buildingSafetyRating = item.buildingSafetyRating;
        annualProtocol.buildingSafetyRatingNumber = item.buildingSafetyRatingNumber;
        annualProtocol.suitabilityForExploitation = item.suitabilityForExploitation;
        annualProtocol.suitabilityForExploitationNumber = item.suitabilityForExploitationNumber;
        annualProtocol.remainingTime = item.remainingTime;
        annualProtocol.technicalDescription = item.technicalDescription;
        annualProtocol.innerInstallations = item.innerInstallations;
        annualProtocol.mainPhoto = item.mainPhoto;
        annualProtocol.objectMainPhoto = item.objectMainPhoto;
        annualProtocol.reviewComment = item.reviewComment;
        annualProtocol.previousPostControlRecommendationsCompletionSummary = item.previousPostControlRecommendationsCompletionSummary;
        annualProtocol.protocolAnnualElements = protocolAnnualElements;
        annualProtocol.protocolRecommendationElements = protocolRecommendationElements;
        annualProtocol.additionalNotes = item.additionalNotes;
        annualProtocol.canEditPreviousPostControlRecommendations = item.canEditPreviousPostControlRecommendations;
        annualProtocol.canAddPreviousPostControlRecommendations = item.canAddPreviousPostControlRecommendations;
        annualProtocol.methodsOfUse = item.methodsOfUse;
        annualProtocol.clientTaskId = item.clientTaskId;
        annualProtocol.clientTypeNumber = item.clientTypeNumber;

        return annualProtocol;
    }
}

@Injectable()
export class FullAnnualProtocolFormManager implements ModelFormManager<FullAnnualProtocol>{
    constructor(
        private formBuilder: UntypedFormBuilder,
        private datepipe: DatePipe,
        private protocolExtensionFormManager: ProtocolExtensionFormManager,
        private protocolComplaintFormManager: ProtocolComplaintFormManager,
        private protocolLodzElementsFormManager: ProtocolLodzElementFormManager) { }

    private previousPostRecommendationForm = this.formBuilder.group({
        id: [null],
        priorityNumber: [null],
        recommendation: [null],
        priorityName: [null]
    });

    private administratorForm = this.formBuilder.group({
        id: [null],
        name: [null, Validators.required],
        email: [null, Validators.email],
        phone: [null, Validators.pattern('[0-9]*')]
    });

    private elementPhotoForm = this.formBuilder.group({
        id: [null],
        name: [null],
        url: [null]
    });

    private protocolAnnualElementsForm = this.formBuilder.group({
        id: [null],
        elementType: [null, Validators.required],
        elementCategory: [null, Validators.required],
        material: [null],
        elementStateRating: [null],
        elementStateRatingNumber: [null, Validators.required],
        remarksAndObservations: [null],
        postControlRecommendationPriority: [null],
        postControlRecommendationPriorityNumber: [null],
        requiresMaterial: [null],
        isEstetic: [null],
        elementPhotos: this.formBuilder.array([this.elementPhotoForm])
    });

    private protocolRecommendationElementsForm = this.formBuilder.group({
        id: [null],
        recommendation: [null, Validators.required],
        priorityNumber: [null, Validators.required]
    });

    private annualProtocolForm = this.formBuilder.group({
        id: [null],
        caseId: [null],
        sapId: [null],
        inventoryId: [null],
        jnDesignation: [null],
        protocolNumber: [null],
        controlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        previousControlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        previousControlDateText: [null],
        buildingStateRating: [null],
        buildingStateRatingNumber: [null, Validators.required],
        buildingSafetyRating: [null],
        buildingSafetyRatingNumber: [null, Validators.required],
        suitabilityForExploitation: [null],
        suitabilityForExploitationNumber: [null, Validators.required],
        remainingTime: [null, Validators.required],
        technicalDescription: [null, Validators.required],
        innerInstallations: [null, Validators.required],
        mainPhoto: [null],
        reviewComment: [null],
        previousPostControlRecommendationsCompletionSummary: [null],
        administrators: this.formBuilder.array([this.administratorForm]),
        protocolAnnualElements: this.formBuilder.array([this.protocolAnnualElementsForm]),
        protocolRecommendationElements: this.formBuilder.array([this.protocolRecommendationElementsForm]),
        previousPostControlRecommendations: this.formBuilder.array([this.previousPostRecommendationForm]),
        methodsOfUse: [null],
        protocolExtension: this.protocolExtensionFormManager.getEmptyForm(),
        clientTaskId: [null],
        clientTypeNumber: [null]
    });

    buildForm(model: FullAnnualProtocol): UntypedFormGroup {
        if (!model)
        {
            return this.annualProtocolForm;
        }

        this.annualProtocolForm = this.formBuilder.group({
            id: [model.id],
            caseId: [model.caseId],
            sapId: [model.sapId],
            inventoryId: [model.inventoryId],
            jnDesignation: [model.jnDesignation],
            protocolNumber: [model.protocolNumber],
            controlDate: [model.controlDate ? this.datepipe.transform(model.controlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            previousControlDate: [model.previousControlDate ? this.datepipe.transform(model.previousControlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            previousControlDateText: [model.previousControlDateText],
            buildingStateRating: [model.buildingStateRating],
            buildingStateRatingNumber: [model.buildingStateRatingNumber, Validators.required],
            buildingSafetyRating: [model.buildingSafetyRating],
            buildingSafetyRatingNumber: [model.buildingSafetyRatingNumber, Validators.required],
            suitabilityForExploitation: [model.suitabilityForExploitation],
            suitabilityForExploitationNumber: [model.suitabilityForExploitationNumber, Validators.required],
            remainingTime: [model.remainingTime, Validators.required],
            technicalDescription: [model.technicalDescription, Validators.required],
            innerInstallations: [model.innerInstallations, Validators.required],
            mainPhoto: [model.mainPhoto],
            reviewComment: [model.reviewComment],
            previousPostControlRecommendationsCompletionSummary: [model.previousPostControlRecommendationsCompletionSummary],
            administrators: this.formBuilder.array([this.administratorForm]),
            protocolAnnualElements: this.formBuilder.array([this.protocolAnnualElementsForm]),
            protocolRecommendationElements: this.formBuilder.array([]),
            previousPostControlRecommendations: this.formBuilder.array([this.previousPostRecommendationForm]),
            methodsOfUse: [model.methodsOfUse],
            protocolExtension: this.protocolExtensionFormManager.buildForm(model.protocolExtension),
            clientTaskId: [model.clientTaskId],
            clientTypeNumber: [model.clientTypeNumber]
        });

        (this.annualProtocolForm.get('administrators') as UntypedFormArray).removeAt(0);

        if (model.administrators && model.administrators.length > 0) {
            model.administrators.forEach(person => {
                (this.annualProtocolForm.get('administrators') as UntypedFormArray).push(this.formBuilder.group({
                    id: [person.id],
                    name: [person.name, Validators.required],
                    email: [person.email],
                    phone: [person.phone]
                }));
            });
        }

        (this.annualProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray).removeAt(0);

        if (model.previousPostControlRecommendations && model.previousPostControlRecommendations.length > 0) {
            model.previousPostControlRecommendations.forEach(recommendation => {
                (this.annualProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray).push(this.formBuilder.group({
                    id: [recommendation.id],
                    priorityNumber: [Number(recommendation.priorityNumber), Validators.required],
                    recommendation: [recommendation.recommendation, Validators.required]
                }));
            });
        }

        (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray).removeAt(0);
        model.protocolAnnualElements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                elementType: [element.elementType, Validators.required],
                elementCategory: [element.elementCategory, Validators.required],
                material: [element.material],
                elementStateRating: [element.elementStateRating],
                elementStateRatingNumber: [element.elementStateRatingNumber, Validators.required],
                remarksAndObservations: [element.remarksAndObservations],
                postControlRecommendationPriority: [element.postControlRecommendationPriority],
                postControlRecommendationPriorityNumber: [element.postControlRecommendationPriorityNumber],
                requiresMaterial: [element.requiresMaterial],
                isEstetic: [element.isEstetic],
                elementPhotos: this.formBuilder.array([this.elementPhotoForm])
            });

            (elementForm.get('elementPhotos') as UntypedFormArray).removeAt(0);
            element.elementPhotos.forEach(photo => {
                (elementForm.get('elementPhotos') as UntypedFormArray).push(this.formBuilder.group({
                    id: [null],
                    name: [photo.name],
                    url: [photo.url]
                }));
            });

            (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray).push(elementForm);
        });

        if (model.protocolRecommendationElements.length > 0)
            (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray).removeAt(0);

        model.protocolRecommendationElements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                recommendation: [element.recommendation, Validators.required],
                priorityNumber: [element.priorityNumber, Validators.required]
            });

            (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray).push(elementForm);
        });

        return this.annualProtocolForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.annualProtocolForm;
    }

    public addNewPerson(): UntypedFormGroup {
        (this.annualProtocolForm.get('administrators') as UntypedFormArray).push(this.formBuilder.group({
            name: [null, Validators.required],
            email: [null],
            phone: [null]
        }));

        return this.annualProtocolForm;
    }

    public removePerson(index: number): UntypedFormGroup {
        (this.annualProtocolForm.get('administrators') as UntypedFormArray).removeAt(index);
        return this.annualProtocolForm;
    }

    public removeNewRecommendation(index: number): UntypedFormGroup {
        (this.annualProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray).removeAt(index);
        return this.annualProtocolForm;
    }

    public addNewRecommendation(): UntypedFormGroup {
        var recommendationsFormArray = this.annualProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray;
        recommendationsFormArray.push(this.formBuilder.group({
            id: [null],
            priorityNumber: [null, Validators.required],
            recommendation: [null, Validators.required]
        }));

        recommendationsFormArray.updateValueAndValidity();

        return this.annualProtocolForm;
    }

    public removeElement(index: number) {
        var elementsFormArray = (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray);
        if (elementsFormArray.length > 1)
            elementsFormArray.removeAt(index);
        return this.annualProtocolForm;
    }

    public removeComplaint(index: number) {
        var protocolExtension = (this.annualProtocolForm.get('protocolExtension') as UntypedFormGroup)
        var complaintsFormArray = (protocolExtension.get('complaints') as UntypedFormArray);

        if (complaintsFormArray.length > 1)
            complaintsFormArray.removeAt(index);

        return this.annualProtocolForm;
    }

    public removeLodzElement(index: number, elementsType: number) {
        var protocolExtension = (this.annualProtocolForm.get('protocolExtension') as UntypedFormGroup)
        var lodzElementFormArray = null;
        if (elementsType == 2)
        {
            lodzElementFormArray = (protocolExtension.get('lodzElementsWithWeight') as UntypedFormArray);
        }
        else if (elementsType == 3)
        {
            lodzElementFormArray = (protocolExtension.get('lodzElementsWithStateRatingNumber') as UntypedFormArray);
        }

        if (lodzElementFormArray.length > 1)
            lodzElementFormArray.removeAt(index);

        return this.annualProtocolForm;
    }

    public removeRecommendation(index: number) {
        var elementsFormArray = (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        if (elementsFormArray.length > 0)
            elementsFormArray.removeAt(index);
        return this.annualProtocolForm;
    }

    public removeAllElementsButFirst() {
        var elementsFormArray = (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 1; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.annualProtocolForm;
    }

    public removeAllLodzElementsButFirst(elementsType: number) {
        var protocolExtension = (this.annualProtocolForm.get('protocolExtension') as UntypedFormGroup)
        var lodzElementsFormArray = null;
        if (elementsType == 1)
        {
            lodzElementsFormArray = (protocolExtension.get('lodzElementsWithWeight') as UntypedFormArray);
        }
        else if (elementsType == 2)
        {
            lodzElementsFormArray = (protocolExtension.get('lodzElementsWithStateRatingNumber') as UntypedFormArray);
        }

        for (let index = lodzElementsFormArray.length - 1; index >= 1; index--) {
            if (!lodzElementsFormArray.controls[index].get('isPermanent').value)
            {
                lodzElementsFormArray.removeAt(index);
            }            
        }
        return this.annualProtocolForm;
    }

    public removeAllComplaintsButFirst() {
        var protocolExtension = (this.annualProtocolForm.get('protocolExtension') as UntypedFormGroup)
        var complaintsFormArray = (protocolExtension.get('complaints') as UntypedFormArray);
        for (let index = complaintsFormArray.length - 1; index >= 1; index--) {
            complaintsFormArray.removeAt(index);
        }
        return this.annualProtocolForm;
    }

    public removeAllRecommendationsButFirst() {
        var elementsFormArray = (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 1; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.annualProtocolForm;
    }

    public removeFirstEmptyRecommendationIfExists() {
        var elementsFormArray = (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        if (elementsFormArray.controls.length > 0 && !elementsFormArray.controls[0].value.priorityNumber) {
            elementsFormArray.removeAt(0);
        }
    }

    public addNewRecommendationFromElements(index: number) {
        var elementsFormArray = (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray);
        var recommendationsFormArray = (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);

        let element = elementsFormArray.controls[index];
        if (element.get('postControlRecommendationPriorityNumber').value) {
            var newRecommendationForm = this.formBuilder.group({
                id: [null],
                recommendation: [`${element.get('elementCategory').value} - ${element.get('elementType').value}: ${this.getRemarksAndObservationsFromElement(element)}`, Validators.required],
                priorityNumber: [element.get('postControlRecommendationPriorityNumber').value, Validators.required]
            });
            recommendationsFormArray.push(newRecommendationForm);
            recommendationsFormArray.updateValueAndValidity();
            this.removeFirstEmptyRecommendationIfExists();
        }

        return this.annualProtocolForm;
    }

    public removeAllRecommendations() {
        var elementsFormArray = (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 0; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.annualProtocolForm;
    }

    public loadRecommendations() {
        var elementsFormArray = (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray);

        this.removeAllRecommendations();

        var recommendationsFormArray = (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        elementsFormArray.controls.forEach(element => {
            if (element.get('postControlRecommendationPriorityNumber').value) {
                var newRecommendationForm = this.formBuilder.group({
                    id: [null],
                    recommendation: [`${element.get('elementCategory').value} - ${element.get('elementType').value}: ${this.getRemarksAndObservationsFromElement(element)}`, Validators.required],
                    priorityNumber: [element.get('postControlRecommendationPriorityNumber').value, Validators.required]
                });
                recommendationsFormArray.push(newRecommendationForm);
                recommendationsFormArray.updateValueAndValidity();
            }
        });

        return this.annualProtocolForm;
    }

    public addNewElementBehindSelected(index: number) {
        var elementsFormArray = (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray);
        var selectedElementForm = (elementsFormArray.controls[index] as UntypedFormGroup);
        var newElementForm = this.formBuilder.group({
            id: [null],
            elementType: [selectedElementForm.get('elementType').value, Validators.required],
            elementCategory: [selectedElementForm.get('elementCategory').value, Validators.required],
            material: [''],
            elementStateRating: [''],
            elementStateRatingNumber: [null, Validators.required],
            remarksAndObservations: [''],
            postControlRecommendationPriority: [''],
            postControlRecommendationPriorityNumber: [null],
            requiresMaterial: [selectedElementForm.get('requiresMaterial').value],
            isEstetic: [selectedElementForm.get('isEstetic').value],
            elementPhotos: this.formBuilder.array([this.elementPhotoForm])
        });
        (newElementForm.get('elementPhotos') as UntypedFormArray).removeAt(0);
        elementsFormArray.insert(index + 1, newElementForm);

        elementsFormArray.updateValueAndValidity();

        return this.annualProtocolForm;
    }

    public addNewComplaintBehindSelected(index: number) {
        var protocolExtension = (this.annualProtocolForm.get('protocolExtension') as UntypedFormGroup)
        var complaintsFormArray = (protocolExtension.get('complaints') as UntypedFormArray);
        var selectedComplaint = complaintsFormArray.controls[index] as UntypedFormGroup;

        var newComplaintForm = this.protocolComplaintFormManager.buildForm(
            new ProtocolComplaint(
                null,
                selectedComplaint.controls['complainer'].value,
                selectedComplaint.controls['content'].value));

        complaintsFormArray.insert(index + 1, newComplaintForm);
        complaintsFormArray.updateValueAndValidity();

        return this.annualProtocolForm;
    }

    public addNewLodzElementBehindSelected(index: number, elementsType: number) {
        var protocolExtension = (this.annualProtocolForm.get('protocolExtension') as UntypedFormGroup);
        var lodzElementsFormArray = null;
        if (elementsType == 2)
        {
          lodzElementsFormArray = (protocolExtension.get('lodzElementsWithWeight') as UntypedFormArray);
        }
        else if (elementsType == 3)
        {
          lodzElementsFormArray = (protocolExtension.get('lodzElementsWithStateRatingNumber') as UntypedFormArray);
        }
        var selectedLodzElement = lodzElementsFormArray.controls[index] as UntypedFormGroup;

        var element = new ProtocolLodzElement();
        element.elementTypeNumber = selectedLodzElement.controls['elementTypeNumber'].value;
        element.id = null;
        element.elementCategory = selectedLodzElement.controls['elementCategory'].value;
        element.elementName = selectedLodzElement.controls['elementName'].value;
        element.durability = selectedLodzElement.controls['durability'].value;
        element.isPermanent = false;
        element.isDisabled = false;
        element.protocolAnnualLodzElementPhotos = [];
        element.protocolQuinquennialLodzElementPhotos = [];
        element.durabilityRatio = 0;

        if (selectedLodzElement.controls['elementTypeNumber'].value == 1)
        {
            element.elementWeight = selectedLodzElement.controls['elementWeight'].value;
        }

        if (selectedLodzElement.controls['elementTypeNumber'].value == 2)
        {
            element.elementStateRatingNumber = selectedLodzElement.controls['elementStateRatingNumber'].value;
        }

        var newLodzElementForm = this.protocolLodzElementsFormManager.buildForm(element);

        lodzElementsFormArray.insert(index + 1, newLodzElementForm);
        lodzElementsFormArray.updateValueAndValidity();

        return this.annualProtocolForm;
    }

    public addNewRecommendationBehindSelected(index: number) {
        var recommendationFormArray = (this.annualProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        var selectedRecommendationForm = (recommendationFormArray.controls[index] as UntypedFormGroup);
        var newRecommendationForm = this.formBuilder.group({
            id: [null],
            recommendation: [selectedRecommendationForm.get('recommendation').value, Validators.required],
            priorityNumber: [selectedRecommendationForm.get('priorityNumber').value, Validators.required]
        });
        recommendationFormArray.insert(index + 1, newRecommendationForm);
        recommendationFormArray.updateValueAndValidity();

        return this.annualProtocolForm;
    }

    public loadElements(elements: ProtocolBuildingElement[]) {
        var elementsFormArray = (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray);
        while (elementsFormArray.length !== 0) {
            elementsFormArray.removeAt(0)
        }
        elements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                elementType: [element.elementType, Validators.required],
                elementCategory: [element.elementCategory, Validators.required],
                material: [element.material],
                elementStateRating: [element.elementStateRating],
                elementStateRatingNumber: [element.elementStateRatingNumber, Validators.required],
                remarksAndObservations: [element.remarksAndObservations],
                postControlRecommendationPriority: [element.postControlRecommendationPriority],
                postControlRecommendationPriorityNumber: [element.postControlRecommendationPriorityNumber],
                requiresMaterial: [element.requiresMaterial],
                isEstetic: [element.isEstetic],
                elementPhotos: this.formBuilder.array([this.elementPhotoForm])
            });

            (elementForm.get('elementPhotos') as UntypedFormArray).removeAt(0);
            element.elementPhotos.forEach(photo => {
                (elementForm.get('elementPhotos') as UntypedFormArray).push(this.formBuilder.group({
                    id: [null],
                    name: [photo.name],
                    url: [photo.url]
                }));
            });

            (this.annualProtocolForm.get('protocolAnnualElements') as UntypedFormArray).push(elementForm);
        });
    }

    private getRemarksAndObservationsFromElement(element: AbstractControl)
    {
        var remarksAndObservationsValue = element.get('remarksAndObservations').value;
        return remarksAndObservationsValue == null ? "" : remarksAndObservationsValue;
    }
}