import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';
import { UserCreation } from '../models/user-creation';
import { UserDetails, UserDetailsAdapter } from '../models/user-details';
import { WorkflowPeriodType } from "../models/workflow-date";
import { LicenseAdapter } from '../models/license';
import { LicenseType } from '../models/license-type';

@Injectable()
export class AdminService {

    constructor(
        private http: HttpClient,
        private userDetailsAdapter: UserDetailsAdapter,
        private licenseAdapter: LicenseAdapter
    ) { }

    public prepareAllUsers(data: any) {
        if (!data || !data.usersDetails)
            return null;

        var userDetails: UserDetails[] = [];
        for (const userDetailDto of data.usersDetails) {
            userDetails.push(this.userDetailsAdapter.adapt(userDetailDto));
        }

        return userDetails;
    }

    public createUser(userCreation: UserCreation) {
        return this.http.post<any>('/api/admin/CreateUser', userCreation)
            .pipe(map(data => {
                return data;
            }));
    }

    public editUser(userCreation: UserCreation) {
        return this.http.post<any>('/api/admin/EditUser', userCreation)
            .pipe(map(data => {
                return data;
            }));
    }

    public unassignUserFromWorkObjects(id: string) {
        return this.http.post<any>('/api/admin/UnassignUserFromWorkObjects', { id: id })
            .pipe(map(data => {
                return data;
            }));
    }

    public resetUserPassword(id: string) {
        return this.http.post<any>('/api/admin/ResetUserPassword', { id: id })
            .pipe(map(data => {
                return data;
            }));
    }

    public deactivateUser(id: string) {
        return this.http.post<any>('/api/admin/DeactivateUser', { id: id })
            .pipe(map(data => {
                return data;
            }));
    }

    public activateUser(id: string) {
        return this.http.post<any>('/api/admin/ActivateUser', { userId: id })
            .pipe(map(data => {
                return data;
            }));
    }

    public getUser(id: string) {
        return this.http.get<any>('/api/admin/GetUser/' + id).pipe(map(data => {
            if (!data)
                return null;

            var userDetails = this.userDetailsAdapter.adapt(data);

            return userDetails;
        }));
    }

    public removeSignatureFromUser(userIdFromFrontend: string, photoNameToDelete: string) {
        return this.http.post<any>('/api/admin/RemoveSignature', { userId: userIdFromFrontend, photoNameToDelete: photoNameToDelete })
            .pipe(map(data => {
                return data;
            }));
    }

    public uploadSignatureForUser(formData: FormData) {
        return this.http.post<any>('/api/admin/UploadSignature', formData)
            .pipe(map(data => {
                return data;
            }));
    }

    public removeLicenseFromUser(userIdFromFrontend: string, licenseFileName: string, type: LicenseType) {
        return this.http.post<any>('/api/admin/RemoveLicense', { userId: userIdFromFrontend, licenseFileName: licenseFileName, type: type })
            .pipe(map(data => {
                return data;
            }));
    }

    public uploadLicenseForUser(formData: FormData, type: LicenseType, expirationDateForLicense: Date) {
        formData.append('type', type.toString());
        formData.append('expirationDate', expirationDateForLicense.toDateString())
        return this.http.post<any>('/api/admin/UploadLicense', formData)
            .pipe(map(data => {
                return data;
            }));
    }

    public getSignatureLinkForProfile(id: string) {
        return this.http.get<any>('/api/admin/GetSignatureLinkForProfile/' + id).pipe(map(data => {
            return data;
        }));
    }

    public getLicenseLink(id: string, type: LicenseType) {
        return this.http.get<any>(`/api/admin/GetLicense/${id}/${type}`).pipe(map(data => {
            if (!data)
                return null;

            return this.licenseAdapter.adapt(data);
        }));
    }

    public generateExcelUserActivityReport(dateTimeFrom: string, dateTimeTo: string) {
        let data = {
            DateFrom: dateTimeFrom,
            DateTo: dateTimeTo
        }
        return this.http.post<any>('/api/admin/GenerateExcelUserActivityReport', data)
    }

    public updateWorkflowDayAndMonth(workflowDayFromFrontend: string, workflowMonthFromFrontend: string, workflowPeriodType: WorkflowPeriodType) {
        return this.http.post<any>('/api/admin/UpdateWorkflowDayAndMonth', { workflowDay: workflowDayFromFrontend, workflowMonth: workflowMonthFromFrontend, workflowPeriodType: workflowPeriodType })
            .pipe(map(data => {
                return data;
            }));
    }
}