import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ProtocolSignatureWithCaseIds {    
    constructor(public id: string,
        public clientTaskId: string,
        public caseIds: string[],
        public protocolId: string,
        public protocolTypeNumber: number,
        public firstEngineerId: string,
        public secondEngineerId: string) {
    }
}

@Injectable()
export class ProtocolSignatureWithCaseIdsAdapter implements Adapter<ProtocolSignatureWithCaseIds> {
    public adapt(item: any): ProtocolSignatureWithCaseIds {
        return new ProtocolSignatureWithCaseIds(
            item.id,
            item.clientTaskId,
            item.caseIds,
            item.protocolId,
            item.protocolTypeNumber,
            item.firstEngineerId,
            item.secondEngineerId);
    }
}