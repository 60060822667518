import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ClientContactPersonDetails {

    constructor(
        public id: string,
        public name: string,
        public email: string,
        public phone: string
    ) {
    }
}

@Injectable()
export class ClientContactPersonDetailsAdapter implements Adapter<ClientContactPersonDetails> {
    adapt(item: any): ClientContactPersonDetails {
        return new ClientContactPersonDetails(
            item.id,
            item.name,
            item.email,
            item.phone
        )
    }
}