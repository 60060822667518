import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ElementStateRating {
    constructor(
        public name: string,
        public description: string,
        public number: number
    ) { }
}

@Injectable()
export class ElementStateRatingAdapter implements Adapter<ElementStateRating>{
    adapt(item: any): ElementStateRating {
        return new ElementStateRating(
            item.name,
            item.description,
            item.number
        );
    }
}