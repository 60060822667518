import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class SuitabilityForExploitationRating {
    constructor(
        public name: string,
        public number: number
    ) { }
}

@Injectable()
export class SuitabilityForExploitationRatingAdapter implements Adapter<SuitabilityForExploitationRating>{
    adapt(item: any): SuitabilityForExploitationRating {
        return new SuitabilityForExploitationRating(
            item.name,
            item.number
        );
    }
}