import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class WorkObjectAdministrator {
    constructor(
        public id: string,
        public name: string,
        public email: string,
        public phone: string
    ) { }
}

@Injectable()
export class WorkObjectAdministratorAdapter implements Adapter<WorkObjectAdministrator>{
    adapt(item: any): WorkObjectAdministrator {
        return new WorkObjectAdministrator(
            item.id,
            item.name,
            item.email,
            item.phone
        )
    }
}