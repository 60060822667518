import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { ChangePassword } from 'src/app/models/change-password';
import { AccountService } from 'src/app/services/account.service';
import { GlobalNotificationService } from 'src/app/services/global-notification.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public userId: string;
  public token: string;

  public passwordForm: UntypedFormGroup;
  public returnUrl = '/';

  public submitted = false;
  public changeInProgress = false;

  public get passwordData() {
    return this.passwordForm.controls;
  }

  constructor(private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private accountService: AccountService,
    private notificationService: GlobalNotificationService) { }

  ngOnInit() {
    this.userId = this.route.snapshot.queryParams['userId'];
    this.token = this.route.snapshot.queryParams['token'];

    this.passwordForm = this.formBuilder.group({
      passwordRepeat: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onChange() {
    this.submitted = true;
    this.changeInProgress = true;

    if (this.passwordForm.invalid || this.passwordData.password.value != this.passwordData.passwordRepeat.value) {
      this.notificationService.showWarningNotification("Podane hasła nie są takie same!");
      this.changeInProgress = false;
      return;
    }

    this.accountService.changePassword(new ChangePassword(this.userId, this.token, this.passwordData.password.value))
      .pipe(first())
      .subscribe(() => {
        this.notificationService.showSuccessNotification("Hasło zostało zmienione. Możesz się zalogować.");
        this.router.navigate([this.returnUrl])
      },
        error => {
          this.notificationService.showErrorNotificationBasedOnErrorObject(error);
          this.changeInProgress = false;
        })
  }
}
