import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ProtocolTemplateSimple {
    constructor(
        public name: string,
        public isDefault: boolean
    ) {
    }
}

@Injectable()
export class ProtocolTemplateSimpleAdapter implements Adapter<ProtocolTemplateSimple> {
    adapt(item: any): ProtocolTemplateSimple {
        return new ProtocolTemplateSimple(
            item.name,
            item.isDefault
        );
    }
}