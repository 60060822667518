export class NavMenuItem {

    public combinedId: string;

    constructor(
        public id: string,
        public parentId: string,
        public text: string,
        public routerLink: string,
        public allowedRoles: string[] = null,
        public parameter: string = null,
        public secondParameter: string = null
    ) {
        if (parameter) {
            this.combinedId = this.parentId == null ? `${this.id}-${this.parameter}` : `${this.parentId}-${this.id}-${this.parameter}`;
            if (secondParameter)
                this.combinedId = `${this.combinedId}-${this.secondParameter}`;
        }
        else
            this.combinedId = this.parentId == null ? this.id : `${this.parentId}-${this.id}`;
    }
}