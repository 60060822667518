import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class SummaryReport {
    constructor(
        public reportId: string,
        public requestedDate: Date,
        public description: string,
        public clientName: string,
        public clientTaskName: string,
        public loadNewestOfType: string,
        public loadNeverIncluded: string,
        public regenerateProtocols: string,
        public controlDateFrom: Date,
        public controlDateTo: Date,
        public creationDateFrom: Date,
        public creationDateTo: Date,
        public generatedByUserId: string
    ) { }
}

@Injectable()
export class SummaryReportAdapter implements Adapter<SummaryReport>{
    adapt(item: any): SummaryReport {
        return new SummaryReport(
            item.reportId,
            item.requestedDate,
            item.description,
            item.clientName,
            item.clientTaskName,
            item.loadNewestOfType,
            item.loadNeverIncluded,
            item.regenerateProtocols,
            item.controlDateFrom,
            item.controlDateTo,
            item.creationDateFrom,
            item.creationDateTo,
            item.generatedByUserId
        );
    }
}