import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';
import { WorkObjectPhoto, WorkObjectPhotoAdapter } from './work-object-photo';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';

export class ProtocolLodzElement {
    public id: string;
    public elementName: string;
    public elementCategory: string;
    public durability: number;
    public recommendations: string;
    public elementStateRating: string;
    public elementStateRatingNumber: number;
    public postControlRecommendationPriority: string;
    public postControlRecommendationPriorityNumber: number;
    public elementWeight: number;
    public elementTypeNumber: number;
    public isPermanent: boolean;
    public isDisabled: boolean;
    public durabilityRatio: number;

    public protocolAnnualLodzElementPhotos: WorkObjectPhoto[];
    public protocolQuinquennialLodzElementPhotos: WorkObjectPhoto[];

    constructor() { }
}

@Injectable()
export class ProtocolLodzElementAdapter implements Adapter<ProtocolLodzElement>{
    constructor(private workObjectPhotoAdapter: WorkObjectPhotoAdapter) { }

    adapt(item: any): ProtocolLodzElement {
        var element = new ProtocolLodzElement();

        element.id = item.id;
        element.elementName = item.elementName;
        element.elementCategory = item.elementCategory;
        element.durability = item.durability;
        element.elementStateRating = item.elementStateRating;
        element.elementStateRatingNumber = item.elementStateRatingNumber;
        element.recommendations = item.recommendations;
        element.postControlRecommendationPriority = item.postControlRecommendationPriority;
        element.postControlRecommendationPriorityNumber = item.postControlRecommendationPriorityNumber;
        element.elementWeight = item.elementWeight;
        element.elementTypeNumber = item.elementTypeNumber;
        element.isPermanent = item.isPermanent;
        element.isDisabled = item.isDisabled;
        element.durabilityRatio = item.durabilityRatio;

        var protocolAnnualLodzElementPhotos = [];
        if (item.protocolAnnualLodzElementPhotos) {
            item.protocolAnnualLodzElementPhotos.forEach(element => {
                protocolAnnualLodzElementPhotos.push(this.workObjectPhotoAdapter.adapt(element));
            });
        }
        element.protocolAnnualLodzElementPhotos = protocolAnnualLodzElementPhotos;

        var protocolQuinquennialLodzElementPhotos = [];
        if (item.protocolQuinquennialLodzElementPhotos) {
            item.protocolQuinquennialLodzElementPhotos.forEach(element => {
                protocolQuinquennialLodzElementPhotos.push(this.workObjectPhotoAdapter.adapt(element));
            });
        }
        element.protocolQuinquennialLodzElementPhotos = protocolQuinquennialLodzElementPhotos;

        return element;
    }

}

@Injectable()
export class ProtocolLodzElementFormManager implements ModelFormManager<ProtocolLodzElement>{
    constructor(private formBuilder: UntypedFormBuilder) { }

    private elementPhotoForm = this.formBuilder.group({
        id: [null],
        name: [null],
        url: [null]
    });

    private protocolLodzElementForm = this.formBuilder.group({
        id: [null],
        elementName: [null, Validators.required],
        elementCategory: [null, Validators.required],
        durability: [null, [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
        elementStateRating: [null],
        elementStateRatingNumber: [null],
        recommendations: [null],
        postControlRecommendationPriority: [null],
        postControlRecommendationPriorityNumber: [null],
        elementWeight: [null, [Validators.required, Validators.pattern("[1-3]")]],
        elementTypeNumber: [2],
        isPermanent: [true],
        isDisabled: [false],
        durabilityRatio: [null],
        protocolAnnualLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm]),
        protocolQuinquennialLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm])
    });

    buildForm(model: ProtocolLodzElement): UntypedFormGroup {
        if (!model)
        {
            (this.protocolLodzElementForm.get('protocolAnnualLodzElementPhotos') as UntypedFormArray).removeAt(0);
            return this.protocolLodzElementForm;
        }
        
        if (model.elementTypeNumber == 1)
        {
            this.protocolLodzElementForm = this.formBuilder.group({
                id: [model.id],
                elementName: [model.elementName, Validators.required],
                elementCategory: [model.elementCategory, Validators.required],
                durability: [model.durability, [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
                elementStateRating: [model.elementStateRating],
                elementStateRatingNumber: [model.elementStateRatingNumber],
                recommendations: [model.recommendations],
                postControlRecommendationPriority: [model.postControlRecommendationPriority],
                postControlRecommendationPriorityNumber: [model.postControlRecommendationPriorityNumber],
                elementWeight: [model.elementWeight, [Validators.required, Validators.pattern("[1-3]")]],
                elementTypeNumber: [model.elementTypeNumber],
                isPermanent: [model.isPermanent],
                isDisabled: [model.isDisabled],
                durabilityRatio: [model.durabilityRatio],
                protocolAnnualLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm]),
                protocolQuinquennialLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm])
            });
        }
        else if (model.elementTypeNumber == 2)
        {
            this.protocolLodzElementForm = this.formBuilder.group({
                id: [model.id],
                elementName: [model.elementName, Validators.required],
                elementCategory: [model.elementCategory, Validators.required],
                durability: [model.durability, [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
                elementStateRating: [model.elementStateRating],
                elementStateRatingNumber: [model.elementStateRatingNumber, Validators.required],
                recommendations: [model.recommendations],
                postControlRecommendationPriority: [model.postControlRecommendationPriority],
                postControlRecommendationPriorityNumber: [model.postControlRecommendationPriorityNumber],
                elementWeight: [model.elementWeight],
                elementTypeNumber: [model.elementTypeNumber],
                isPermanent: [model.isPermanent],
                isDisabled: [model.isDisabled],
                durabilityRatio: [model.durabilityRatio],
                protocolAnnualLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm]),
                protocolQuinquennialLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm])
            });
        }
        else
        {
            this.protocolLodzElementForm = this.formBuilder.group({
                id: [model.id],
                elementName: [model.elementName, Validators.required],
                elementCategory: [model.elementCategory, Validators.required],
                durability: [model.durability, [Validators.required, Validators.pattern("^[0-9][0-9]?$|^100$")]],
                elementStateRating: [model.elementStateRating],
                elementStateRatingNumber: [model.elementStateRatingNumber],
                recommendations: [model.recommendations],
                postControlRecommendationPriority: [model.postControlRecommendationPriority],
                postControlRecommendationPriorityNumber: [model.postControlRecommendationPriorityNumber],
                elementWeight: [model.elementWeight],
                elementTypeNumber: [model.elementTypeNumber],
                isPermanent: [model.isPermanent],
                isDisabled: [model.isDisabled],
                durabilityRatio: [model.durabilityRatio],
                protocolAnnualLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm]),
                protocolQuinquennialLodzElementPhotos: this.formBuilder.array([this.elementPhotoForm])
            });
        }
        
        (this.protocolLodzElementForm.get('protocolAnnualLodzElementPhotos') as UntypedFormArray).removeAt(0);
        model.protocolAnnualLodzElementPhotos.forEach(photo => {
            var photoForm = this.formBuilder.group({
                id: [null],
                name: [photo.name],
                url: [photo.url]
            });

            (this.protocolLodzElementForm.get('protocolAnnualLodzElementPhotos') as UntypedFormArray).push(photoForm);
        });

        (this.protocolLodzElementForm.get('protocolQuinquennialLodzElementPhotos') as UntypedFormArray).removeAt(0);
        model.protocolQuinquennialLodzElementPhotos.forEach(photo => {
            var photoForm = this.formBuilder.group({
                id: [null],
                name: [photo.name],
                url: [photo.url]
            });

            (this.protocolLodzElementForm.get('protocolQuinquennialLodzElementPhotos') as UntypedFormArray).push(photoForm);
        });

        return this.protocolLodzElementForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.protocolLodzElementForm;
    }
}