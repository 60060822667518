import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ClientTaskDashboard {
    constructor(
        public clientFullName: string,
        public clientShortName: string,
        public fullName: string,
        public shortName: string,
        public workObjectsQty: number,
        public assignedManagers: string) { }
}

@Injectable()
export class ClientTaskDashboardAdapter implements Adapter<ClientTaskDashboard> {
    adapt(item: any): ClientTaskDashboard {
        return new ClientTaskDashboard(
            item.clientFullName,
            item.clientShortName,
            item.fullName,
            item.shortName,
            item.workObjectsQty,
            item.assignedManagers
        )
    }
}