import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ProtocolSignature {
    public headerName: string;
    public headerNameShort: string;

    constructor(public id: string,
        public clientTaskId: string,
        public caseId: string,
        public protocolId: string,
        public protocolTypeNumber: number,
        public protocolTypeName: string,
        public firstEngineerId: string,
        public secondEngineerId: string) {
    }
}

@Injectable()
export class ProtocolSignatureAdapter implements Adapter<ProtocolSignature> {
    public adapt(item: any): ProtocolSignature {

        var protocolSignature = new ProtocolSignature(
            item.id,
            item.clientTaskId,
            item.caseId,
            item.protocolId,
            item.protocolTypeNumber,
            item.protocolTypeName,
            item.firstEngineerId,
            item.secondEngineerId);

        var headerSuffix = "";
        if (protocolSignature.protocolId == null && protocolSignature.caseId == null && protocolSignature.clientTaskId == null)
            headerSuffix = " na podstawie globalnych ustawień dla całej aplikacji";
        else if (protocolSignature.protocolId == null && protocolSignature.caseId == null && protocolSignature.clientTaskId != null)
            headerSuffix = " na podstawie ustawień zapisanych dla tego zadania";
        else if (protocolSignature.protocolId == null && protocolSignature.caseId != null && protocolSignature.clientTaskId != null)
            headerSuffix = " na podstawie ustawień zapisanych dla tego obiektu";
        else if (protocolSignature.protocolId != null && protocolSignature.caseId != null && protocolSignature.clientTaskId != null)
            headerSuffix = " na podstawie ustawień zapisanych dla tego protokołu";

        protocolSignature.headerNameShort = "Podpisy dla " + item.protocolTypeName;
        protocolSignature.headerName = protocolSignature.headerNameShort + headerSuffix;
        return protocolSignature;
    }
}