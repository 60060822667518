import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { DatePipe } from '@angular/common';

export class AddWorkObject {
    public Lp: number;
    public CaseId: string;
    public JnDesignation: string;
    public Region: string;
    public County: string;
    public SapId: string;
    public InventoryId: string;
    public BuildingType: string;
    public Description: string;
    public Group: string;
    public BuildingArea: string;
    public FunctionalArea: string;
    public RoofArea: string;
    public PreviousHalfYearControlDate?: Date;
    public PreviousAnnualControlDate?: Date;
    public PreviousQuinquennialControlDate?: Date;
    public SAT_FIAA: string;
    public ExploitationStartDate?: Date;
    public AttritionPeriod: string;
    public YearsTillDate: string;
    public RemainingTime: string;
    public VerifiedLifeTime: string;
    public CurrentHalfYearControlDate?: Date;
    public CurrentAnnualControlDate?: Date;
    public CurrentQuinquennialControlDate?: Date;
    public PostControlRecommendations: string;
    public PostControlRecommendations2ndGrade: string;
    public PostControlRecommendations3rdGrade: string;
    public PostControlHalfYearRecommendations: string;
    public PostControlHalfYearRecommendations2ndGrade: string;
    public PostControlHalfYearRecommendations3rdGrade: string;
    public PostControlQuinquennialRecommendations: string;
    public PostControlQuinquennialRecommendations2ndGrade: string;
    public PostControlQuinquennialRecommendations3rdGrade: string;
    public BuildingStateRating: string;
    public Longitude: string;
    public Latitude: string;

    constructor() { }
}

@Injectable()
export class AddWorkObjectAdapter implements Adapter<AddWorkObject>{
    constructor(private datepipe: DatePipe) { }

    adapt(item: any): AddWorkObject {
        var addWorkObject = new AddWorkObject();
        addWorkObject.CaseId = item.caseId;
        addWorkObject.JnDesignation = item.jnDesignation;
        addWorkObject.Region = item.region;
        addWorkObject.County = item.county;
        addWorkObject.SapId = item.sapId;
        addWorkObject.InventoryId = item.inventoryId;
        addWorkObject.BuildingType = item.buildingType;
        addWorkObject.Description = item.description;
        addWorkObject.Group = item.group;
        addWorkObject.BuildingArea = item.buildingArea;
        addWorkObject.FunctionalArea = item.functionalArea;
        addWorkObject.RoofArea = item.roofArea;
        addWorkObject.PreviousHalfYearControlDate = item.previousHalfYearControlDate != null ? new Date(item.previousHalfYearControlDate) : null;
        addWorkObject.PreviousAnnualControlDate = item.previousAnnualControlDate != null ? new Date(item.previousAnnualControlDate) : null;
        addWorkObject.PreviousQuinquennialControlDate = item.previousQuinquennialControlDate != null ? new Date(item.previousQuinquennialControlDate) : null;
        addWorkObject.SAT_FIAA = item.saT_FIAA;
        addWorkObject.ExploitationStartDate = item.exploitationStartDate != null ? new Date(item.exploitationStartDate) : null;
        addWorkObject.AttritionPeriod = item.attritionPeriod;
        addWorkObject.YearsTillDate = item.yearsTillDate;
        addWorkObject.RemainingTime = item.remainingTime;
        addWorkObject.VerifiedLifeTime = item.verifiedLifeTime;
        addWorkObject.CurrentHalfYearControlDate = item.currentHalfYearControlDate != null ? new Date(item.currentHalfYearControlDate) : null;
        addWorkObject.CurrentAnnualControlDate = item.currentAnnualControlDate != null ? new Date(item.currentAnnualControlDate) : null;
        addWorkObject.CurrentQuinquennialControlDate = item.currentQuinquennialControlDate != null ? new Date(item.currentQuinquennialControlDate) : null;
        addWorkObject.PostControlRecommendations = item.postControlRecommendations;
        addWorkObject.PostControlRecommendations2ndGrade = item.postControlRecommendations2ndGrade;
        addWorkObject.PostControlRecommendations3rdGrade = item.postControlRecommendations3rdGrade;
        addWorkObject.PostControlHalfYearRecommendations = item.postControlHalfYearRecommendations;
        addWorkObject.PostControlHalfYearRecommendations2ndGrade = item.postControlHalfYearRecommendations2ndGrade;
        addWorkObject.PostControlHalfYearRecommendations3rdGrade = item.postControlHalfYearRecommendations3rdGrade;
        addWorkObject.PostControlQuinquennialRecommendations = item.postControlQuinquennialRecommendations;
        addWorkObject.PostControlQuinquennialRecommendations2ndGrade = item.postControlQuinquennialRecommendations2ndGrade;
        addWorkObject.PostControlQuinquennialRecommendations3rdGrade = item.postControlQuinquennialRecommendations3rdGrade;
        addWorkObject.BuildingStateRating = item.buildingStateRating;
        addWorkObject.Longitude = item.longitude;
        addWorkObject.Latitude = item.latitude;

        return addWorkObject;
    }

    createObjectWithDatesWithoutTimezoneInformation(workObject: AddWorkObject): any
    {
        let preparedWorkObject =
        {
            CaseId: workObject.CaseId,
            JnDesignation: workObject.JnDesignation,
            Region: workObject.Region,
            County: workObject.County,
            SapId: workObject.SapId,
            InventoryId: workObject.InventoryId,
            BuildingType: workObject.BuildingType,
            Description: workObject.Description,
            Group: workObject.Group,
            BuildingArea: workObject.BuildingArea,
            FunctionalArea: workObject.FunctionalArea,
            RoofArea: workObject.RoofArea,
            PreviousHalfYearControlDate: workObject.PreviousHalfYearControlDate == null ? null : workObject.PreviousHalfYearControlDate.toDateString(),
            PreviousAnnualControlDate: workObject.PreviousAnnualControlDate == null ? null : workObject.PreviousAnnualControlDate.toDateString(),
            PreviousQuinquennialControlDate: workObject.PreviousQuinquennialControlDate == null ? null : workObject.PreviousQuinquennialControlDate.toDateString(),
            SAT_FIAA: workObject.SAT_FIAA,
            ExploitationStartDate: workObject.ExploitationStartDate == null ? null : workObject.ExploitationStartDate.toDateString(),
            AttritionPeriod: workObject.AttritionPeriod,
            YearsTillDate: workObject.YearsTillDate,
            RemainingTime: workObject.RemainingTime,
            VerifiedLifeTime: workObject.VerifiedLifeTime,
            CurrentHalfYearControlDate: workObject.CurrentHalfYearControlDate == null ? null : workObject.CurrentHalfYearControlDate.toDateString(),
            CurrentAnnualControlDate: workObject.CurrentAnnualControlDate == null ? null : workObject.CurrentAnnualControlDate.toDateString(),
            CurrentQuinquennialControlDate: workObject.CurrentQuinquennialControlDate == null ? null : workObject.CurrentQuinquennialControlDate.toDateString(),
            PostControlRecommendations: workObject.PostControlRecommendations,
            PostControlRecommendations2ndGrade: workObject.PostControlRecommendations2ndGrade,
            PostControlRecommendations3rdGrade: workObject.PostControlRecommendations3rdGrade,
            PostControlHalfYearRecommendations: workObject.PostControlHalfYearRecommendations,
            PostControlHalfYearRecommendations2ndGrade: workObject.PostControlHalfYearRecommendations2ndGrade,
            PostControlHalfYearRecommendations3rdGrade: workObject.PostControlHalfYearRecommendations3rdGrade,
            PostControlQuinquennialRecommendations: workObject.PostControlQuinquennialRecommendations,
            PostControlQuinquennialRecommendations2ndGrade: workObject.PostControlQuinquennialRecommendations2ndGrade,
            PostControlQuinquennialRecommendations3rdGrade: workObject.PostControlQuinquennialRecommendations3rdGrade,
            BuildingStateRating: workObject.BuildingStateRating,
            Longitude: workObject.Longitude,
            Latitude: workObject.Latitude
        }

        return preparedWorkObject;
    }
}