import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ProtocolDocumentationControlElement, ProtocolDocumentationControlElementAdapter } from './protocol-documentation-control-element';
import { ProtocolSignature } from './protocol-signature';

export class FullDocumentationControlProtocol {
    public id: string;
    public protocolNumber: string;
    public controlDate: Date;
    public protocolDocumentationControlElements: ProtocolDocumentationControlElement[] = [];
    public documentationControlTemplate: any;
    public clientTypeNumber: number;
    public clientTaskId: string;
    public protocolSignatureWithCaseIds: ProtocolSignature;

    constructor(public caseId: string,
        public sapId: string,
        public inventoryId: string,
        public jnDesignation: string) { }
}

@Injectable()
export class FullDocumentationControlProtocolAdapter implements Adapter<FullDocumentationControlProtocol>{
    constructor(private protocolDocumentationControlElementAdapter: ProtocolDocumentationControlElementAdapter) { }

    adapt(item: any): FullDocumentationControlProtocol {
        var protocolDocumentationControlElements = [];

        if (item.protocolDocumentationControlElements) {
            item.protocolDocumentationControlElements.forEach(element => {
                protocolDocumentationControlElements.push(this.protocolDocumentationControlElementAdapter.adapt(element));
            });
        }

        var documentationControlProtocol = new FullDocumentationControlProtocol(
            item.caseId,
            item.sapId,
            item.inventoryId,
            item.jnDesignation);

        documentationControlProtocol.id = item.id;
        documentationControlProtocol.protocolNumber = item.protocolNumber;
        documentationControlProtocol.controlDate = item.controlDate;
        documentationControlProtocol.protocolDocumentationControlElements = protocolDocumentationControlElements;
        documentationControlProtocol.clientTypeNumber = item.clientTypeNumber;
        documentationControlProtocol.clientTaskId = item.clientTaskId;

        return documentationControlProtocol;
    }
}