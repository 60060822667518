import { Component } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

export class HomeComponent {
  constructor(public authenticationService: AuthenticationService) { }

  public canUserSeeFullContentOfApplication()
  {
    return !this.authenticationService.isChimneySweep() && !this.authenticationService.isClient();
  }
}
