import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

export class ProtocolBuildingEquipment {
    constructor(
        public id: string,
        public waterInstallation: boolean,
        public sewerageInstallation: boolean,
        public electricalInstallation: boolean,
        public gasInstallation: boolean,
        public centralHeatingInstallation: boolean,
        public domesticHotWaterInstallation: boolean,
        public lightningProtectionInstallation: boolean,
        public liftingEquipment: boolean
    ) {
    }
}

@Injectable()
export class ProtocolBuildingEquipmentAdapter implements Adapter<ProtocolBuildingEquipment> {
    adapt(item: any): ProtocolBuildingEquipment {
        return new ProtocolBuildingEquipment(
            item.id,
            item.waterInstallation,
            item.sewerageInstallation,
            item.electricalInstallation,
            item.gasInstallation,
            item.centralHeatingInstallation,
            item.domesticHotWaterInstallation,
            item.lightningProtectionInstallation,
            item.liftingEquipment
        );
    }
}

@Injectable()
export class ProtocolBuildingEquipmentFormManager implements ModelFormManager<ProtocolBuildingEquipment>{
    constructor(private formBuilder: UntypedFormBuilder) { }

    private protocolBuildingEquipmentForm = this.formBuilder.group({
        id: [null],
        waterInstallation: [false],
        sewerageInstallation: [false],
        electricalInstallation: [false],
        gasInstallation: [false],
        centralHeatingInstallation: [false],
        domesticHotWaterInstallation: [false],
        lightningProtectionInstallation: [false],
        liftingEquipment: [false]
    });

    buildForm(model: ProtocolBuildingEquipment): UntypedFormGroup {
        if (!model)
            return this.protocolBuildingEquipmentForm;
        
         this.protocolBuildingEquipmentForm = this.formBuilder.group({
            id: [model.id],
            waterInstallation: [model.waterInstallation],
            sewerageInstallation: [model.sewerageInstallation],
            electricalInstallation: [model.electricalInstallation],
            gasInstallation: [model.gasInstallation],
            centralHeatingInstallation: [model.centralHeatingInstallation],
            domesticHotWaterInstallation: [model.domesticHotWaterInstallation],
            lightningProtectionInstallation: [model.lightningProtectionInstallation],
            liftingEquipment: [model.liftingEquipment]
        });

        return this.protocolBuildingEquipmentForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.protocolBuildingEquipmentForm;
    }
}