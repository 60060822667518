import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ClientContactPersonDetailsAdapter, ClientContactPersonDetails } from './client-contact-person-details';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';

export class ClientDetails {

    public clientContactPeople: [ClientContactPersonDetails];
    public descriptionOnlyShow: string;
    public executorDescriptionOnlyShow: string;

    constructor(
        public id: string,
        public fullName: string,
        public shortName: string,
        public description: string,
        public executorDescription: string,
        public clientTypeName: string,
        public clientTypeNumber: number,
        public distinctShortNames: string[]
    ) {
    }
}

@Injectable()
export class ClientDetailsAdapter implements Adapter<ClientDetails> {
    constructor(private clientContactPersonDetailsAdapter: ClientContactPersonDetailsAdapter) {}

    public adapt(item: any): ClientDetails {
        var clientDetails = new ClientDetails(
            item.id,
            item.fullName,
            item.shortName,
            item.description,
            item.executorDescription,
            item.clientTypeName,
            item.clientTypeNumber,
            item.distinctShortNames
        );

        clientDetails.descriptionOnlyShow = item.description.toString().replaceAll("\n", "<br>");
        clientDetails.executorDescriptionOnlyShow = item.executorDescription.toString().replaceAll("\n", "<br>");

        if (item.clientContactPeople && item.clientContactPeople.length > 0) {
            item.clientContactPeople.forEach(person => {
                if (!clientDetails.clientContactPeople) {
                    clientDetails.clientContactPeople = [this.clientContactPersonDetailsAdapter.adapt(person)];
                }
                else {
                    clientDetails.clientContactPeople.push(this.clientContactPersonDetailsAdapter.adapt(person));
                }
            });
        }

        return clientDetails;
    }
}

@Injectable()
export class ClientDetailsFormManager implements ModelFormManager<ClientDetails>{
    private clientContactPeopleForm = this.formBuilder.group({
        id: [''],
        name: ['', Validators.required],
        email: ['', Validators.email],
        phone: ['', Validators.pattern('[0-9]*')]
    });

    private clientDetailsForm = this.formBuilder.group({
        id: [''],
        fullName: ['', Validators.required],
        shortName: ['',
        [
          Validators.required,
          Validators.maxLength(25),
          Validators.pattern('[a-zA-Z0-9 ]*')
        ]],
        description: ['', Validators.required],
        executorDescription: ['', Validators.required],
        clientContactPeople: this.formBuilder.array([this.clientContactPeopleForm])
    });

    constructor(private formBuilder: UntypedFormBuilder) { }

    public getEmptyForm(): UntypedFormGroup {
        return this.clientDetailsForm;
    }

    public buildForm(model: ClientDetails): UntypedFormGroup {
        if (!model)
            return this.clientDetailsForm;

        this.clientDetailsForm = this.formBuilder.group({
            id: [model.id],
            fullName: [model.fullName, Validators.required],
            shortName: [model.shortName,
            [
              Validators.required,
              Validators.maxLength(25),
              Validators.pattern('[a-zA-Z0-9 ]*')
            ]],
            description: [model.description, Validators.required],
            executorDescription: [model.executorDescription, Validators.required],
            clientContactPeople: this.formBuilder.array([this.clientContactPeopleForm])
        });

        (this.clientDetailsForm.get('clientContactPeople') as UntypedFormArray).removeAt(0);

        if (model.clientContactPeople && model.clientContactPeople.length > 0) {
            model.clientContactPeople.forEach(person => {
                (this.clientDetailsForm.get('clientContactPeople') as UntypedFormArray).push(this.formBuilder.group({
                    id: [person.id],
                    name: [person.name, Validators.required],
                    email: [person.email],
                    phone: [person.phone]
                }));
            });
        }

        return this.clientDetailsForm
    }

    public addNewPerson(): UntypedFormGroup {
        (this.clientDetailsForm.get('clientContactPeople') as UntypedFormArray).push(this.formBuilder.group({
            name: ['', Validators.required],
            email: [''],
            phone: ['']
        }));

        return this.clientDetailsForm;
    }

    public removePerson(index: number): UntypedFormGroup {
        (this.clientDetailsForm.get('clientContactPeople') as UntypedFormArray).removeAt(index);
        return this.clientDetailsForm;
    }
}