import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ModalPeriodForExcelSummaryComponent } from './modal-period-for-excel-summary/modal-period-for-excel-summary.component';
import { ModalWithoutButtonsComponent } from './modal-without-buttons/modal-without-buttons.component';
import { ModalComponent } from './modal/modal.component';
 
@NgModule({
  declarations: [
    ModalComponent,
    ModalWithoutButtonsComponent,
    ModalPeriodForExcelSummaryComponent
  ],
  imports: [
    CommonModule,
    DatePickerModule
  ],
  providers: [],
  exports: [
    ModalComponent,
    ModalWithoutButtonsComponent,
    ModalPeriodForExcelSummaryComponent
  ]
})
export class UtilitiesModule { }