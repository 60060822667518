<div class="modal fade" id="modalComponent" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">

      <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" (click)="getValue(false)"><i class="fas fa-times-circle"></i></button>
      </div>

      <div class="modal-body" [innerHTML]="content">
      </div>

      <div class="modal-footer">
        <button id="confirmButtonModalComponent" type="button" class="btn btn-success" (click)="getValue(true)"><i class="fas fa-check"></i>
          {{confirmText}}</button>
        <button id="declineButtonModalComponent" type="button" class="btn btn-danger" (click)="getValue(false)"><i class="fas fa-ban"></i>
          {{declineText}}</button>
      </div>

    </div>
  </div>
</div>