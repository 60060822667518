import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class DeletePhotos {
    constructor(
        public caseId: string,
        public photoNamesToDelete: string[]) { }
}

@Injectable()
export class DeletePhotosAdapter implements Adapter<DeletePhotos>{
    
    adapt(item: any): DeletePhotos {
        return new DeletePhotos(
            item.caseId,
            item.photoNamesToDelete,
        );
    }
}