import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';

export class ReportFileDetails {
    constructor(
        public name: string,
        public url: string
    ) { }
}

@Injectable()
export class ReportFileDetailsAdapter implements Adapter<ReportFileDetails>{
    adapt(item: any): ReportFileDetails {
        return new ReportFileDetails(
            item.name,
            item.url
        );
    }
}