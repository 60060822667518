import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

export class ProtocolTypesOfConstruction {
    constructor(
        public id: string,
        public isReinforcedConcrete: boolean,
        public isBrick: boolean,
        public isWooden: boolean,
        public isSteel: boolean,
        public isDifferent: boolean
    ) {
    }
}

@Injectable()
export class ProtocolTypesOfConstructionAdapter implements Adapter<ProtocolTypesOfConstruction> {
    adapt(item: any): ProtocolTypesOfConstruction {
        return new ProtocolTypesOfConstruction(
            item.id,
            item.isReinforcedConcrete,
            item.isBrick,
            item.isWooden,
            item.isSteel,
            item.isDifferent
        );
    }
}

@Injectable()
export class ProtocolTypesOfConstructionFormManager implements ModelFormManager<ProtocolTypesOfConstruction>{
    constructor(private formBuilder: UntypedFormBuilder) { }

    private protocolTypesOfConstructionForm = this.formBuilder.group({
        id: [null],
        isReinforcedConcrete: [false],
        isBrick: [false],
        isWooden: [false],
        isSteel: [false],
        isDifferent: [true]
    });

    buildForm(model: ProtocolTypesOfConstruction): UntypedFormGroup {
        if (!model)
            return this.protocolTypesOfConstructionForm;
        
         this.protocolTypesOfConstructionForm = this.formBuilder.group({
            id: [model.id],
            isReinforcedConcrete: [model.isReinforcedConcrete],
            isBrick: [model.isBrick],
            isWooden: [model.isWooden],
            isSteel: [model.isSteel],
            isDifferent: [model.isDifferent]
        });

        return this.protocolTypesOfConstructionForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.protocolTypesOfConstructionForm;
    }
}