import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class CaseIdWithDate {
    constructor(
        public caseId: string,
        public lastPhotoDate: Date
    ) { }
}

@Injectable()
export class CaseIdWithDateAdapter implements Adapter<CaseIdWithDate>{
    adapt(item: any): CaseIdWithDate {
        return new CaseIdWithDate(
            item.caseId,
            new Date(item.lastPhotoDate)
        );
    }
}