import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { GenerateExcelTaskSummaryModel } from '../models/generate-excel-task-summary';
import { GenerateReportModel } from '../models/generate-report';
import { ProtocolReportDetail, ProtocolReportDetailAdapter } from '../models/protocol-report-detail';
import { ProtocolsSearchOptions } from '../models/protocols-search-options';
import { SummaryReport, SummaryReportAdapter } from '../models/summary-report';
import { WorkflowPeriodType } from '../models/workflow-date';

@Injectable()
export class ReportService {
    constructor(private http: HttpClient,
        private protocolReportDetailAdapter: ProtocolReportDetailAdapter,
        private summaryReportAdapter: SummaryReportAdapter) { }

    public getProtocolsBySearchOptions(protocolsSearchOptions: ProtocolsSearchOptions) {
        return this.http.post<any>('/api/protocols/GetProtocolsBySearchOptions', protocolsSearchOptions)
            .pipe(map(data => {
                if (data && data.protocols) {
                    var protocols: ProtocolReportDetail[] = [];
                    for (const protocol of data.protocols) {
                        protocols.push(this.protocolReportDetailAdapter.adapt(protocol));
                    }

                    return protocols;
                }
            }));
    }

    public getLinksToGeneratedPDFs(generateReportModel: GenerateReportModel) {
        return this.http.post<any>('/api/protocols/GenerateReport2', generateReportModel)
            .pipe(map(data => {
                return data;
            }));
    }

    public generateExcelTaskSummary(generateExcelTaskSummary: GenerateExcelTaskSummaryModel) {
        return this.http.post<any>('/api/protocols/GenerateExcelTaskSummarry', generateExcelTaskSummary)
            .pipe(map(data => {
                return data;
            }));
    }

    public getWorkflowDayAndMonth(workflowDateType: WorkflowPeriodType) {
        return this.http.get<any>(`/api/protocols/GetWorkflowDayAndMonth/${workflowDateType}`).pipe(map(data => {
            return data;
        }));
    }

    public getSummaryReports(clientShortName: string, clientTaskShortName: string) {
        return this.http.get<any>(`/api/protocols/GetSummaryReports/${clientShortName}/${clientTaskShortName}`)
        .pipe(map(data => {
            if (!data || !data.summaryReportsResult)
                return null;

            var summaryReports: SummaryReport[] = [];

            data.summaryReportsResult.forEach(summaryReport => {
                summaryReports.push(this.summaryReportAdapter.adapt(summaryReport));
            });
            return summaryReports;

        }));
    }
}