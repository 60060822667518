import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';

export class ProtocolForSpecificTask {
    public protocolId: string;
    public caseId: string;
    public createdOrModifiedByUserId: string;
    public engineerNameAndSurname: string;
    public protocolType: string;
    public protocolTypeNumber: number;
    public protocolStatus: string;
    public protocolStatusNumber: number;
    public controlDate: string;
    public protocolCreationDate: string;
    public protocolLastModifiedDate: string;
    public buildingStateRating: string;
    public buildingStateRatingNumber: number;
    public buildingSafetyRating: string;
    public buildingSafetyRatingNumber: string;
    public managerCommentLastDate: string;
    public clientTypeNumber: number;
    public canView: boolean;
    public canEdit: boolean;

    constructor() { }
}

@Injectable()
export class ProtocolForSpecificTaskAdapter implements Adapter<ProtocolForSpecificTask>{
    adapt(item: any): ProtocolForSpecificTask {
        var element = new ProtocolForSpecificTask();

        if(item.controlDate != null) {
            var controlDateString = item.controlDate.toString();
            element.controlDate = controlDateString.slice(0, 10) + " " + controlDateString.slice(11, 16);
        }

        if(item.protocolCreationDate != null) {
            var protocolCreationDateString = item.protocolCreationDate.toString();
            element.protocolCreationDate = protocolCreationDateString.slice(0, 10) + " " + protocolCreationDateString.slice(11, 16);
        }

        if(item.protocolLastModifiedDate != null) {
            var protocolLastModifiedDateString = item.protocolLastModifiedDate.toString();
            element.protocolLastModifiedDate = protocolLastModifiedDateString.slice(0, 10) + " " + protocolLastModifiedDateString.slice(11, 16);
        }

        if(item.managerCommentLastDate != null) {
            var managerCommentLastDateString = item.managerCommentLastDate.toString();
            element.managerCommentLastDate = managerCommentLastDateString.slice(0, 10) + " " + managerCommentLastDateString.slice(11, 16);
        }

        element.protocolId = item.protocolId;
        element.caseId = item.caseId;
        element.createdOrModifiedByUserId = item.createdOrModifiedByUserId;
        element.engineerNameAndSurname = item.engineerNameAndSurname;
        var words = item.protocolType.split(/(?=[A-Z])/);
        element.protocolType = "";
        for (var i = 0; i < words.length; i++) {
            if (words[i])
                element.protocolType += words[i] + " ";
        }
        element.protocolTypeNumber = item.protocolTypeNumber;
        var words = item.protocolStatus.split(/(?=[A-Z])/);
        element.protocolStatus = "";
        for (var i = 0; i < words.length; i++) {
            if (words[i])
                element.protocolStatus += words[i] + " ";
        }
        element.protocolStatusNumber = item.protocolStatusNumber;
        var words = item.buildingStateRating.split(/(?=[A-Z])/);
        element.buildingStateRating = "";
        for (var i = 0; i < words.length; i++) {
            if (words[i])
                element.buildingStateRating += words[i] + " ";
        }
        element.buildingStateRatingNumber = item.buildingStateRatingNumber;
        var words = item.buildingSafetyRating.split(/(?=[A-Z])/);
        element.buildingSafetyRating = "";
        for (var i = 0; i < words.length; i++) {
            if (words[i])
                element.buildingSafetyRating += words[i] + " ";
        }
        if (item.buildingSafetyRatingNumber == '0') {
            element.buildingSafetyRatingNumber = '-';
        } else {
            element.buildingSafetyRatingNumber = item.buildingSafetyRatingNumber;
        }
        element.clientTypeNumber = item.clientTypeNumber;
        element.canView = item.canView;
        element.canEdit = item.canEdit;

        return element;
    }
}