import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class GenerateReportItem {
    constructor(public id: string,
        public protocolTypeNumber: number,
        public caseId: string) {

    }
}

@Injectable()
export class GenerateReportItemAdapter implements Adapter<GenerateReportItem>{
    adapt(item: any): GenerateReportItem {
        var generateReportItem = new GenerateReportItem(
            item.id,
            item.protocolTypeNumber,
            item.caseId
        );

        return generateReportItem;
    }

}