import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { UploaderModule } from "@syncfusion/ej2-angular-inputs";
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ActivateAccountComponent } from './components/account/activate-account/activate-account.component';
import { ChangePasswordComponent } from './components/account/change-password/change-password.component';
import { ProfileEditComponent } from './components/account/profile-edit/profile-edit.component';
import { HomeComponent } from './components/layout/home/home.component';
import { LoginComponent } from './components/layout/login/login.component';
import { MainComponent } from './components/layout/main/main.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { SidemenuComponent } from './components/layout/sidemenu/sidemenu.component';
import { SharedModule } from './components/shared/shared.module';
import { AddWorkObjectAdapter } from './models/add-work-object';
import { BookStatusRatingAdapter } from './models/book-status-rating';
import { BuildingSafetyRatingAdapter } from './models/building-safety-rating';
import { BuildingStateRatingAdapter } from './models/building-state-rating';
import { CaseIdWithCoordinatesAdapter } from './models/case-id-with-coordinates';
import { CaseIdWithDateAdapter } from './models/case-id-with-date';
import { ClientContactPersonCreationAdapter } from './models/client-contact-person-creation';
import { ClientContactPersonDetailsAdapter } from './models/client-contact-person-details';
import { ClientCreationAdapter } from './models/client-creation';
import { ClientDetailsAdapter, ClientDetailsFormManager } from './models/client-details';
import { ClientSimpleDetailsAdapter } from './models/client-simple-details';
import { ClientTaskCreationAdapter, ClientTaskCreationFormManager } from './models/client-task-creation';
import { ClientTaskDashboardAdapter } from './models/client-task-dashboard';
import { ClientTaskDetailsAdapter, ClientTaskDetailsFormManager } from './models/client-task-details';
import { DeletePhotosAdapter } from './models/delete-photos';
import { DictionaryObjectAdapter } from './models/dictionary-object';
import { DocumentationStatusRatingAdapter } from './models/documentation-status-rating';
import { DocumentsForProtocolAdapter } from './models/documents-for-protocol';
import { ElementStateRatingAdapter } from './models/element-state-rating';
import { EngineerSignatureAdapter } from './models/engineer-signature';
import { FullAnnualProtocolAdapter, FullAnnualProtocolFormManager } from './models/full-annual-protocol';
import { FullChimneyProtocolAdapter, FullChimneyProtocolFormManager } from './models/full-chimney-protocol';
import { FullDocumentationControlProtocolAdapter } from './models/full-documentation-control-protocol';
import { FullGasProtocolAdapter, FullGasProtocolFormManager } from './models/full-gas-protocol';
import { FullHalfYearProtocolAdapter, FullHalfYearProtocolFormManager } from './models/full-halfyear-protocol';
import { FullMskAnnualProtocolAdapter, FullMskAnnualProtocolFormManager } from './models/full-msk-annual-protocol';
import { FullMskHalfYearProtocolAdapter, FullMskHalfYearProtocolFormManager } from './models/full-msk-halfyear-protocol';
import { FullMskQuinquennialProtocolAdapter, FullMskQuinquennialProtocolFormManager } from './models/full-msk-quinquennial-protocol';
import { FullQuinquennialProtocolAdapter, FullQuinquennialProtocolFormManager } from './models/full-quinquennial-protocol';
import { GenerateReportItemAdapter } from './models/generate-report-item';
import { MainPhotoAdapter } from './models/main-photo';
import { ManagerChangeStatusAdapter } from './models/manager-change-status';
import { NavMenuItemMainAdapter } from './models/nav-menu-item-main';
import { PostControlRecommendationPriorityAdapter } from './models/post-control-recommendation-priority';
import { PreviousPostControlRecommendationAdapter } from './models/previous-post-control-recommendation';
import { ProtocolBuildingElementAdapter } from './models/protocol-building-element';
import { ProtocolBuildingEquipmentAdapter, ProtocolBuildingEquipmentFormManager } from './models/protocol-building-equipement';
import { ProtocolChimneyElementAdapter } from './models/protocol-chimney-element';
import { ProtocolComplaintAdapter, ProtocolComplaintFormManager } from './models/protocol-complaint';
import { ProtocolDocumentationControlElementAdapter } from './models/protocol-documentation-control-element';
import { ProtocolExtensionAdapter, ProtocolExtensionFormManager } from './models/protocol-extension';
import { ProtocolForSpecificTaskAdapter } from './models/protocol-for-specific-task';
import { ProtocolGasElementAdapter } from './models/protocol-gas-element';
import { ProtocolListItemAdapter } from './models/protocol-list-item';
import { ProtocolLodzElementAdapter, ProtocolLodzElementFormManager } from './models/protocol-lodz-element';
import { ProtocolRecommendationElementAdapter } from './models/protocol-recommendation-element';
import { ProtocolReportDetailAdapter } from './models/protocol-report-detail';
import { ProtocolSignatureAdapter } from './models/protocol-signature';
import { ProtocolSignatureWithCaseIdsAdapter } from './models/protocol-signature-with-case-ids';
import { ProtocolTemplateAdapter } from './models/protocol-template';
import { ProtocolTemplateSimpleAdapter } from './models/protocol-template-simple';
import { ProtocolTypeListItemAdapter } from './models/protocol-type-list-item';
import { ProtocolTypesOfConstructionAdapter, ProtocolTypesOfConstructionFormManager } from './models/protocol-types-of-construction';
import { ProtocolsSearchOptionsAdapter } from './models/protocols-search-options';
import { ReportDetailsAdapter } from './models/report-details';
import { ReportFileDetailsAdapter } from './models/report-file-details';
import { ReportProtocolDocumentAdapter } from "./models/report-protocol-document";
import { ReportProtocolDocumentLinkAdapter } from './models/report-protocol-document-link';
import { SuitabilityForExploitationRatingAdapter } from './models/suitability-for-exploitation-rating';
import { SummaryReportAdapter } from './models/summary-report';
import { TagAdapter } from './models/tag';
import { TaskFromProtocolDashboardAdapter } from './models/task-from-protocol-dashboard';
import { UserCreationAdapter } from './models/user-creation';
import { UserDetailsAdapter } from './models/user-details';
import { UserPickItemAdapter } from './models/user-pick-item';
import { UserProfileAdapter } from './models/user-profile';
import { WorkObjectAdministratorAdapter } from './models/work-object-administrator';
import { WorkObjectDetailsAdapter } from './models/work-object-details';
import { WorkObjectListItemAdapter } from './models/work-object-list-item';
import { WorkObjectPhotoAdapter } from './models/work-object-photo';
import { WorkObjectPhotoExtraAdapter } from './models/work-object-photo-extra';
import { WorkProtocolDetailAdapter } from './models/work-object-protocol-details';
import { LicenseAdapter } from './models/license';
import { AARouteReuseStrategy } from './services/aaroute-reuse-strategy';
import { AccountService } from "./services/account.service";
import { AdminService } from './services/admin.service';
import { appInitializer } from './services/app.initializer';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from "./services/authentication.service";
import { ClientService } from './services/client-service';
import { GlobalNotificationService } from './services/global-notification.service';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { JwtInterceptor } from './services/jwt.interceptor';
import { NavigationService } from './services/navigation.service';
import { NotificationService } from "./services/notification.service";
import { ProtocolsService } from './services/protocols.service';
import { ReportService } from './services/report.service';
import { RoleGuard } from './services/role.guard';
import { WorkObjectService } from './services/work-object-service';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        MainComponent,
        LoginComponent,
        NavbarComponent,
        SidemenuComponent,
        ChangePasswordComponent,
        ActivateAccountComponent,
        ProfileEditComponent
    ],
    bootstrap: [AppComponent], imports: [CommonModule, BrowserModule, AppRoutingModule, ToastModule, UploaderModule, SharedModule], providers: [
            { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService, UserProfileAdapter] },
            { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
            { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
            { provide: RouteReuseStrategy, useClass: AARouteReuseStrategy },
            AuthGuardService,
            RoleGuard,
            UserProfileAdapter,
            NavMenuItemMainAdapter,
            NavigationService,
            UserDetailsAdapter,
            UserCreationAdapter,
            AdminService,
            NotificationService,
            GlobalNotificationService,
            AccountService,
            AuthenticationService,
            ClientCreationAdapter,
            ClientSimpleDetailsAdapter,
            ClientService,
            ClientDetailsAdapter,
            ClientDetailsFormManager,
            ClientContactPersonCreationAdapter,
            ClientContactPersonDetailsAdapter,
            ClientTaskCreationAdapter,
            ClientTaskCreationFormManager,
            AddWorkObjectAdapter,
            ClientTaskDashboardAdapter,
            WorkObjectListItemAdapter,
            ClientTaskDetailsAdapter,
            ClientTaskDetailsFormManager,
            UserPickItemAdapter,
            WorkObjectDetailsAdapter,
            WorkObjectService,
            ProtocolTypeListItemAdapter,
            WorkObjectAdministratorAdapter,
            WorkProtocolDetailAdapter,
            FullAnnualProtocolFormManager,
            FullAnnualProtocolAdapter,
            FullHalfYearProtocolFormManager,
            FullHalfYearProtocolAdapter,
            FullMskAnnualProtocolFormManager,
            FullMskQuinquennialProtocolFormManager,
            FullMskHalfYearProtocolFormManager,
            FullMskAnnualProtocolAdapter,
            FullMskQuinquennialProtocolAdapter,
            FullMskHalfYearProtocolAdapter,
            BuildingStateRatingAdapter,
            BuildingSafetyRatingAdapter,
            SuitabilityForExploitationRatingAdapter,
            PreviousPostControlRecommendationAdapter,
            ElementStateRatingAdapter,
            ProtocolBuildingElementAdapter,
            PostControlRecommendationPriorityAdapter,
            WorkObjectPhotoAdapter,
            WorkObjectPhotoExtraAdapter,
            DeletePhotosAdapter,
            MainPhotoAdapter,
            ProtocolsService,
            FullQuinquennialProtocolAdapter,
            FullQuinquennialProtocolFormManager,
            ProtocolChimneyElementAdapter,
            FullChimneyProtocolAdapter,
            FullChimneyProtocolFormManager,
            ProtocolListItemAdapter,
            DatePipe,
            ProtocolsSearchOptionsAdapter,
            ReportService,
            ProtocolReportDetailAdapter,
            GenerateReportItemAdapter,
            ReportFileDetailsAdapter,
            ReportDetailsAdapter,
            ReportProtocolDocumentAdapter,
            DictionaryObjectAdapter,
            ProtocolTemplateAdapter,
            FullGasProtocolAdapter,
            ProtocolGasElementAdapter,
            FullDocumentationControlProtocolAdapter,
            ProtocolDocumentationControlElementAdapter,
            FullGasProtocolFormManager,
            ProtocolTemplateSimpleAdapter,
            ManagerChangeStatusAdapter,
            TaskFromProtocolDashboardAdapter,
            ProtocolForSpecificTaskAdapter,
            ProtocolRecommendationElementAdapter,
            ProtocolSignatureAdapter,
            ProtocolSignatureWithCaseIdsAdapter,
            EngineerSignatureAdapter,
            ReportProtocolDocumentLinkAdapter,
            SummaryReportAdapter,
            TagAdapter,
            //Lodzkie Kamienice
            ProtocolComplaintAdapter,
            ProtocolComplaintFormManager,
            ProtocolTypesOfConstructionAdapter,
            ProtocolTypesOfConstructionFormManager,
            ProtocolBuildingEquipmentAdapter,
            ProtocolBuildingEquipmentFormManager,
            ProtocolLodzElementAdapter,
            ProtocolLodzElementFormManager,
            ProtocolExtensionAdapter,
            ProtocolExtensionFormManager,
            DocumentationStatusRatingAdapter,
            BookStatusRatingAdapter,
            DocumentsForProtocolAdapter,
            CaseIdWithCoordinatesAdapter,
            CaseIdWithDateAdapter,
            LicenseAdapter,
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule { }
