import { Injectable } from '@angular/core';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms';
import { WorkObjectAdministrator, WorkObjectAdministratorAdapter } from './work-object-administrator';
import { ProtocolBuildingElement, ProtocolBuildingElementAdapter } from './protocol-building-element';
import { PreviousPostControlRecommendation, PreviousPostControlRecommendationAdapter } from './previous-post-control-recommendation';
import { ProtocolRecommendationElementAdapter, ProtocolRecommendationElement } from "./protocol-recommendation-element";
import { Adapter } from './interfaces/adapter.interface';
import { DatePipe } from '@angular/common';
import { ProtocolSignature } from './protocol-signature';

export class FullHalfYearProtocol {
    public id: string;
    public protocolNumber: string;
    public controlDate: Date;
    public previousControlDate: Date;
    public previousControlDateText: string;
    public buildingStateRating: string;
    public buildingStateRatingNumber?: number;
    public buildingSafetyRating: string;
    public buildingSafetyRatingNumber?: number;
    public suitabilityForExploitation: string;
    public suitabilityForExploitationNumber?: number;
    public remainingTime?: number;
    public technicalDescription: string;
    public innerInstallations: string;
    public mainPhoto: string;
    public mainPhotoUrl: string;
    public objectMainPhoto: string;
    public reviewComment: string;
    public previousPostControlRecommendationsCompletionSummary: string;
    public protocolHalfYearElements: ProtocolBuildingElement[] = [];
    public protocolRecommendationElements: ProtocolRecommendationElement[] = [];
    public halfyearTemplate: any;
    public additionalNotes: string;
    public canEditPreviousPostControlRecommendations = false;
    public canAddPreviousPostControlRecommendations = false;
    public methodsOfUse: string;
    public clientTaskId: string;
    public clientTypeNumber: number;
    public protocolSignatureWithCaseIds: ProtocolSignature;

    constructor(public caseId: string,
        public sapId: string,
        public inventoryId: string,
        public jnDesignation: string,
        public administrators: WorkObjectAdministrator[],
        public previousPostControlRecommendations: PreviousPostControlRecommendation[]) { }
}

@Injectable()
export class FullHalfYearProtocolAdapter implements Adapter<FullHalfYearProtocol>{
    constructor(private workObjectAdministratorAdapter: WorkObjectAdministratorAdapter,
        private previousPostControlRecommendationAdapter: PreviousPostControlRecommendationAdapter,
        private protocolHalfYearElementAdapter: ProtocolBuildingElementAdapter,
        private protocolRecommendationElementAdapter: ProtocolRecommendationElementAdapter) { }

    adapt(item: any): FullHalfYearProtocol {
        var administrators = [];

        if (item.administrators) {
            item.administrators.forEach(administrator => {
                administrators.push(this.workObjectAdministratorAdapter.adapt(administrator));
            });
        }

        var previousPostControlRecommendations = [];

        if (item.previousPostControlRecommendations) {
            item.previousPostControlRecommendations.forEach(recommendation => {
                previousPostControlRecommendations.push(this.previousPostControlRecommendationAdapter.adapt(recommendation));
            });
        }

        var protocolHalfYearElements = [];

        if (item.protocolHalfYearElements) {
            item.protocolHalfYearElements.forEach(element => {
                protocolHalfYearElements.push(this.protocolHalfYearElementAdapter.adapt(element));
            });
        }

        var protocolRecommendationElements = [];

        if (item.protocolRecommendationElements) {
            item.protocolRecommendationElements.forEach(element => {
                protocolRecommendationElements.push(this.protocolRecommendationElementAdapter.adapt(element));
            });
        }

        var halfyearProtocol = new FullHalfYearProtocol(
            item.caseId,
            item.sapId,
            item.inventoryId,
            item.jnDesignation,
            administrators,
            previousPostControlRecommendations);

        halfyearProtocol.id = item.id;
        halfyearProtocol.protocolNumber = item.protocolNumber;
        halfyearProtocol.controlDate = item.controlDate;
        halfyearProtocol.previousControlDate = item.previousControlDate;
        halfyearProtocol.previousControlDateText = item.previousControlDateText;
        halfyearProtocol.buildingStateRating = item.buildingStateRating;
        halfyearProtocol.buildingStateRatingNumber = item.buildingStateRatingNumber;
        halfyearProtocol.buildingSafetyRating = item.buildingSafetyRating;
        halfyearProtocol.buildingSafetyRatingNumber = item.buildingSafetyRatingNumber;
        halfyearProtocol.suitabilityForExploitation = item.suitabilityForExploitation;
        halfyearProtocol.suitabilityForExploitationNumber = item.suitabilityForExploitationNumber;
        halfyearProtocol.remainingTime = item.remainingTime;
        halfyearProtocol.technicalDescription = item.technicalDescription;
        halfyearProtocol.innerInstallations = item.innerInstallations;
        halfyearProtocol.mainPhoto = item.mainPhoto;
        halfyearProtocol.objectMainPhoto = item.objectMainPhoto;
        halfyearProtocol.reviewComment = item.reviewComment;
        halfyearProtocol.previousPostControlRecommendationsCompletionSummary = item.previousPostControlRecommendationsCompletionSummary;
        halfyearProtocol.protocolHalfYearElements = protocolHalfYearElements;
        halfyearProtocol.protocolRecommendationElements = protocolRecommendationElements;
        halfyearProtocol.additionalNotes = item.additionalNotes;
        halfyearProtocol.canEditPreviousPostControlRecommendations = item.canEditPreviousPostControlRecommendations;
        halfyearProtocol.canAddPreviousPostControlRecommendations = item.canAddPreviousPostControlRecommendations;
        halfyearProtocol.methodsOfUse = item.methodsOfUse;
        halfyearProtocol.clientTaskId = item.clientTaskId;
        halfyearProtocol.clientTypeNumber = item.clientTypeNumber;

        return halfyearProtocol;
    }
}

@Injectable()
export class FullHalfYearProtocolFormManager implements ModelFormManager<FullHalfYearProtocol>{
    private previousPostRecommendationForm = this.formBuilder.group({
        id: [null],
        priorityNumber: [null],
        recommendation: [null]
    });

    private administratorForm = this.formBuilder.group({
        id: [null],
        name: [null, Validators.required],
        email: [null, Validators.email],
        phone: [null, Validators.pattern('[0-9]*')]
    });

    private elementPhotoForm = this.formBuilder.group({
        id: [null],
        name: [null],
        url: [null]
    });

    private protocolHalfYearElementsForm = this.formBuilder.group({
        id: [null],
        elementType: [null, Validators.required],
        elementCategory: [null, Validators.required],
        material: [null],
        elementStateRating: [null],
        elementStateRatingNumber: [null, Validators.required],
        remarksAndObservations: [null],
        postControlRecommendationPriority: [null],
        postControlRecommendationPriorityNumber: [null],
        requiresMaterial: [null],
        isEstetic: [null],
        elementPhotos: this.formBuilder.array([this.elementPhotoForm])
    });

    private protocolRecommendationElementsForm = this.formBuilder.group({
        id: [null],
        recommendation: [null, Validators.required],
        priorityNumber: [null, Validators.required]
    });

    private halfyearProtocolForm = this.formBuilder.group({
        id: [null],
        caseId: [null],
        sapId: [null],
        inventoryId: [null],
        jnDesignation: [null],
        protocolNumber: [null],
        controlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        previousControlDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
        previousControlDateText: [null],
        buildingStateRating: [null],
        buildingStateRatingNumber: [null, Validators.required],
        buildingSafetyRating: [null],
        buildingSafetyRatingNumber: [null, Validators.required],
        suitabilityForExploitation: [null],
        suitabilityForExploitationNumber: [null, Validators.required],
        remainingTime: [null, Validators.required],
        technicalDescription: [null, Validators.required],
        innerInstallations: [null, Validators.required],
        mainPhoto: [null],
        reviewComment: [null],
        previousPostControlRecommendationsCompletionSummary: [null],
        administrators: this.formBuilder.array([this.administratorForm]),
        protocolHalfYearElements: this.formBuilder.array([this.protocolHalfYearElementsForm]),
        protocolRecommendationElements: this.formBuilder.array([this.protocolRecommendationElementsForm]),
        previousPostControlRecommendations: this.formBuilder.array([this.previousPostRecommendationForm]),
        methodsOfUse: [null],
        clientTaskId: [null],
        clientTypeNumber: [null]
    });

    constructor(private formBuilder: UntypedFormBuilder,
        private datepipe: DatePipe) { }

    buildForm(model: FullHalfYearProtocol): UntypedFormGroup {
        if (!model)
            return this.halfyearProtocolForm;

        this.halfyearProtocolForm = this.formBuilder.group({
            id: [model.id],
            caseId: [model.caseId],
            sapId: [model.sapId],
            inventoryId: [model.inventoryId],
            jnDesignation: [model.jnDesignation],
            protocolNumber: [model.protocolNumber],
            controlDate: [model.controlDate ? this.datepipe.transform(model.controlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            previousControlDate: [model.previousControlDate ? this.datepipe.transform(model.previousControlDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],
            previousControlDateText: [model.previousControlDateText],
            buildingStateRating: [model.buildingStateRating],
            buildingStateRatingNumber: [model.buildingStateRatingNumber, Validators.required],
            buildingSafetyRating: [model.buildingSafetyRating],
            buildingSafetyRatingNumber: [model.buildingSafetyRatingNumber, Validators.required],
            suitabilityForExploitation: [model.suitabilityForExploitation],
            suitabilityForExploitationNumber: [model.suitabilityForExploitationNumber, Validators.required],
            remainingTime: [model.remainingTime, Validators.required],
            technicalDescription: [model.technicalDescription, Validators.required],
            innerInstallations: [model.innerInstallations, Validators.required],
            mainPhoto: [model.mainPhoto],
            reviewComment: [model.reviewComment],
            previousPostControlRecommendationsCompletionSummary: [model.previousPostControlRecommendationsCompletionSummary],
            administrators: this.formBuilder.array([this.administratorForm]),
            protocolHalfYearElements: this.formBuilder.array([this.protocolHalfYearElementsForm]),
            protocolRecommendationElements: this.formBuilder.array([]),
            previousPostControlRecommendations: this.formBuilder.array([this.previousPostRecommendationForm]),
            methodsOfUse: [model.methodsOfUse],
            clientTaskId: [model.clientTaskId],
            clientTypeNumber: [model.clientTypeNumber]
        });

        (this.halfyearProtocolForm.get('administrators') as UntypedFormArray).removeAt(0);

        if (model.administrators && model.administrators.length > 0) {
            model.administrators.forEach(person => {
                (this.halfyearProtocolForm.get('administrators') as UntypedFormArray).push(this.formBuilder.group({
                    id: [person.id],
                    name: [person.name, Validators.required],
                    email: [person.email],
                    phone: [person.phone]
                }));
            });
        }

        (this.halfyearProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray).removeAt(0);

        if (model.previousPostControlRecommendations && model.previousPostControlRecommendations.length > 0) {
            model.previousPostControlRecommendations.forEach(recommendation => {
                (this.halfyearProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray).push(this.formBuilder.group({
                    id: [recommendation.id],
                    priorityNumber: [Number(recommendation.priorityNumber), Validators.required],
                    recommendation: [recommendation.recommendation, Validators.required]
                }));
            });
        }

        (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray).removeAt(0);
        model.protocolHalfYearElements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                elementType: [element.elementType, Validators.required],
                elementCategory: [element.elementCategory, Validators.required],
                material: [element.material],
                elementStateRating: [element.elementStateRating],
                elementStateRatingNumber: [element.elementStateRatingNumber, Validators.required],
                remarksAndObservations: [element.remarksAndObservations],
                postControlRecommendationPriority: [element.postControlRecommendationPriority],
                postControlRecommendationPriorityNumber: [element.postControlRecommendationPriorityNumber],
                requiresMaterial: [element.requiresMaterial],
                isEstetic: [element.isEstetic],
                elementPhotos: this.formBuilder.array([this.elementPhotoForm])
            });

            (elementForm.get('elementPhotos') as UntypedFormArray).removeAt(0);
            element.elementPhotos.forEach(photo => {
                (elementForm.get('elementPhotos') as UntypedFormArray).push(this.formBuilder.group({
                    id: [null],
                    name: [photo.name],
                    url: [photo.url]
                }));
            });

            (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray).push(elementForm);
        });

        if (model.protocolRecommendationElements.length > 0)
            (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray).removeAt(0);

        model.protocolRecommendationElements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                recommendation: [element.recommendation, Validators.required],
                priorityNumber: [element.priorityNumber, Validators.required]
            });

            (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray).push(elementForm);
        });

        return this.halfyearProtocolForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.halfyearProtocolForm;
    }

    public addNewPerson(): UntypedFormGroup {
        (this.halfyearProtocolForm.get('administrators') as UntypedFormArray).push(this.formBuilder.group({
            name: [null, Validators.required],
            email: [null],
            phone: [null]
        }));

        return this.halfyearProtocolForm;
    }

    public removePerson(index: number): UntypedFormGroup {
        (this.halfyearProtocolForm.get('administrators') as UntypedFormArray).removeAt(index);
        return this.halfyearProtocolForm;
    }

    public removeNewRecommendation(index: number): UntypedFormGroup {
        (this.halfyearProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray).removeAt(index);
        return this.halfyearProtocolForm;
    }

    public addNewRecommendation(): UntypedFormGroup {
        var recommendationsFormArray = this.halfyearProtocolForm.get('previousPostControlRecommendations') as UntypedFormArray;
        recommendationsFormArray.push(this.formBuilder.group({
            id: [null],
            priorityNumber: [null, Validators.required],
            recommendation: [null, Validators.required]
        }));

        recommendationsFormArray.updateValueAndValidity();

        return this.halfyearProtocolForm;
    }

    public removeElement(index: number) {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray);
        if (elementsFormArray.length > 1)
            elementsFormArray.removeAt(index);
        return this.halfyearProtocolForm;
    }

    public removeRecommendation(index: number) {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        if (elementsFormArray.length > 0)
            elementsFormArray.removeAt(index);
        return this.halfyearProtocolForm;
    }

    public removeAllElementsButFirst() {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 1; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.halfyearProtocolForm;
    }

    public removeAllRecommendationsButFirst() {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 1; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.halfyearProtocolForm;
    }

    public removeAllRecommendations() {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        for (let index = elementsFormArray.length - 1; index >= 0; index--) {
            elementsFormArray.removeAt(index);
        }
        return this.halfyearProtocolForm;
    }

    public loadRecommendations() {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray);

        this.removeAllRecommendations();

        var recommendationsFormArray = (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        elementsFormArray.controls.forEach(element => {
            if (element.get('postControlRecommendationPriorityNumber').value) {
                var newRecommendationForm = this.formBuilder.group({
                    id: [null],
                    recommendation: [`${element.get('elementCategory').value} - ${element.get('elementType').value}: ${this.getRemarksAndObservationsFromElement(element)}`, Validators.required],
                    priorityNumber: [element.get('postControlRecommendationPriorityNumber').value, Validators.required]
                });
                recommendationsFormArray.push(newRecommendationForm);
                recommendationsFormArray.updateValueAndValidity();
            }
        });

        return this.halfyearProtocolForm;
    }

    public addNewElementBehindSelected(index: number) {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray);
        var selectedElementForm = (elementsFormArray.controls[index] as UntypedFormGroup);
        var newElementForm = this.formBuilder.group({
            id: [null],
            elementType: [selectedElementForm.get('elementType').value, Validators.required],
            elementCategory: [selectedElementForm.get('elementCategory').value, Validators.required],
            material: [''],
            elementStateRating: [''],
            elementStateRatingNumber: [null, Validators.required],
            remarksAndObservations: [''],
            postControlRecommendationPriority: [''],
            postControlRecommendationPriorityNumber: [null],
            requiresMaterial: [selectedElementForm.get('requiresMaterial').value],
            isEstetic: [selectedElementForm.get('isEstetic').value],
            elementPhotos: this.formBuilder.array([this.elementPhotoForm])
        });
        (newElementForm.get('elementPhotos') as UntypedFormArray).removeAt(0);
        elementsFormArray.insert(index + 1, newElementForm);

        elementsFormArray.updateValueAndValidity();

        return this.halfyearProtocolForm;
    }

    public addNewRecommendationBehindSelected(index: number) {
        var recommendationFormArray = (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        var selectedRecommendationForm = (recommendationFormArray.controls[index] as UntypedFormGroup);
        var newRecommendationForm = this.formBuilder.group({
            id: [null],
            recommendation: [selectedRecommendationForm.get('recommendation').value, Validators.required],
            priorityNumber: [selectedRecommendationForm.get('priorityNumber').value, Validators.required]
        });
        recommendationFormArray.insert(index + 1, newRecommendationForm);
        recommendationFormArray.updateValueAndValidity();

        return this.halfyearProtocolForm;
    }

    public removeFirstEmptyRecommendationIfExists() {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);
        if (elementsFormArray.controls.length > 0 && !elementsFormArray.controls[0].value.priorityNumber) {
            elementsFormArray.removeAt(0);
        }
    }

    public addNewRecommendationFromElements(index: number) {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray);
        var recommendationsFormArray = (this.halfyearProtocolForm.get('protocolRecommendationElements') as UntypedFormArray);

        let element = elementsFormArray.controls[index];
        if (element.get('postControlRecommendationPriorityNumber').value) {
            var newRecommendationForm = this.formBuilder.group({
                id: [null],
                recommendation: [`${element.get('elementCategory').value} - ${element.get('elementType').value}: ${this.getRemarksAndObservationsFromElement(element)}`, Validators.required],
                priorityNumber: [element.get('postControlRecommendationPriorityNumber').value, Validators.required]
            });
            recommendationsFormArray.push(newRecommendationForm);
            recommendationsFormArray.updateValueAndValidity();
            this.removeFirstEmptyRecommendationIfExists();
        }

        return this.halfyearProtocolForm;
    }

    public loadElements(elements: ProtocolBuildingElement[]) {
        var elementsFormArray = (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray);
        while (elementsFormArray.length !== 0) {
            elementsFormArray.removeAt(0)
        }
        elements.forEach(element => {
            var elementForm = this.formBuilder.group({
                id: [element.id],
                elementType: [element.elementType, Validators.required],
                elementCategory: [element.elementCategory, Validators.required],
                material: [element.material],
                elementStateRating: [element.elementStateRating],
                elementStateRatingNumber: [element.elementStateRatingNumber, Validators.required],
                remarksAndObservations: [element.remarksAndObservations],
                postControlRecommendationPriority: [element.postControlRecommendationPriority],
                postControlRecommendationPriorityNumber: [element.postControlRecommendationPriorityNumber],
                requiresMaterial: [element.requiresMaterial],
                isEstetic: [element.isEstetic],
                elementPhotos: this.formBuilder.array([this.elementPhotoForm])
            });

            (elementForm.get('elementPhotos') as UntypedFormArray).removeAt(0);
            element.elementPhotos.forEach(photo => {
                (elementForm.get('elementPhotos') as UntypedFormArray).push(this.formBuilder.group({
                    id: [null],
                    name: [photo.name],
                    url: [photo.url]
                }));
            });

            (this.halfyearProtocolForm.get('protocolHalfYearElements') as UntypedFormArray).push(elementForm);
        });
    }

    private getRemarksAndObservationsFromElement(element: AbstractControl)
    {
        var remarksAndObservationsValue = element.get('remarksAndObservations').value;
        return remarksAndObservationsValue == null ? "" : remarksAndObservationsValue;
    }
}