import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class ManagerChangeStatus {

    constructor(
        public protocolId: string,
        public managerComment: string,
        public isAccepted: boolean) { }
}

@Injectable()
export class ManagerChangeStatusAdapter implements Adapter<ManagerChangeStatus>{
    adapt(item: any): ManagerChangeStatus {
        return new ManagerChangeStatus(
            item.protocolId,
            item.managerComment,
            item.isAccepted
        );
    }
}