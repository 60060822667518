import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';
import { Tag, TagAdapter } from './tag';

export class WorkObjectPhoto {
    public isSelected: boolean = false;
    public isInProtocol: boolean = false;
    public isMainPhoto: boolean = false;

    constructor(
        public id: string,
        public name: string,
        public url: string,
        public creationDate: Date,
        public tags: Tag[]) { }
}

@Injectable()
export class WorkObjectPhotoAdapter implements Adapter<WorkObjectPhoto>{
    constructor(
        private tagAdapter: TagAdapter) { }

    adapt(item: any): WorkObjectPhoto {
        if (item.creationDate)
            item.creationDate = new Date(item.creationDate);

        var tagsAdapted: Tag[] = [];
        if (item.tags) {
            item.tags.forEach(tag => {
                tagsAdapted.push(this.tagAdapter.adapt(tag));
            });
        }

        return new WorkObjectPhoto(
            item.id,
            item.name,
            item.url,
            item.creationDate,
            tagsAdapted
        );
    }
}