import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfile } from '../models/user-profile';
import { GlobalNotificationService } from './global-notification.service';
import { CacheService } from './cache.service';

const keyForLocalStorage = 'PrzegladyBudowlaneUser';
@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    private userSubject: BehaviorSubject<UserProfile>;
    public user: Observable<UserProfile>;

    constructor(private router: Router, private http: HttpClient, private notification: GlobalNotificationService, private cacheService: CacheService) {
        this.userSubject = new BehaviorSubject<UserProfile>(null);
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): UserProfile {
        return this.userSubject.value;
    }

    public handleUserData(user: UserProfile, setLocalStorage: boolean): Promise<any> {
        if (!user) this.logout();

        user.userRoles = this.getUserRoles(user.jwtToken);
        this.setAdditionalFieldsForUser(user);
        this.userSubject.next(user);
        this.startJWTTokenTimer();
        let stringifyUser = JSON.stringify(user);
        this.startRefreshTokenTimer(stringifyUser);
        if (!setLocalStorage) return;
        localStorage.setItem(keyForLocalStorage, stringifyUser);
    }

    login(username: string, password: string) {
        return this.http.post<any>('api/account/authenticate', { username, password }, { withCredentials: true })
            .pipe(map<UserProfile, any>(user => {
                this.handleUserData(user, true);
                return user;
            }));
    }

    private setAdditionalFieldsForUser(user: UserProfile) {
        user.hasPicture = user.pictureUrl != null && user.pictureUrl != "";
        user.initials = `${user.firstName[0]}${user.lastName[0]}`;
        user.nameAndSurname = `${user.firstName} ${user.lastName}`;
    }

    logout() {
        this.cacheService.clearCache();
        localStorage.clear();

        this.http.post<any>('api/account/revoketoken', {}, { withCredentials: true }).subscribe(
            data => {
                if (!data.success) {
                    this.notification.showErrorNotification(data.message);
                }
            },
            error => {
                this.notification.showErrorNotificationBasedOnErrorObject(error);
            });

        this.stopJWTTokenTimer();
        this.stopRefreshTokenTimer();
        this.userSubject.next(null);
        this.router.navigate(['/login']);
        localStorage.removeItem(keyForLocalStorage);
    }

    refreshToken() {
        return this.http.post<any>('api/account/refreshtoken', {}, { withCredentials: true })
            .pipe(map((user) => {
                this.handleUserData(user, true);
                return user;
            }));
    }

    public isAdmin() {
        return this.userValue.userRoles.includes("admin_access");
    }

    public isManager() {
        return this.userValue.userRoles.includes("manager_access");
    }

    public isEngineer() {
        return this.userValue.userRoles.includes("engineer_access");
    }

    public isChimneySweep() {
        return this.userValue.userRoles.includes("mobile_access");
    }

    public isClient() {
        return this.userValue.userRoles.includes("client_access");
    }

    private jwtTokenTimeout: number | NodeJS.Timer | null = null;
    private refreshTokenTimeout: number | NodeJS.Timer | null = null;

    private startJWTTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.jwtTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private startRefreshTokenTimer(stringifiedUser: string) {
        let refreshTokenExpires = JSON.parse(stringifiedUser).refreshTokenExpires;
        if (!refreshTokenExpires) {
            return;
        }
        let refreshTokenExpiresDate = new Date(JSON.parse(stringifiedUser).refreshTokenExpires);
        const refreshTokenExpiresTime = refreshTokenExpiresDate.getTime();

        // set a timeout to inform user about refresh token expiration three hours before (60 * 1000) = 1min
        const timeout = refreshTokenExpiresTime - Date.now() - (3 * 60 * 60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.notification.showWarningNotificationWithSpecificTitle("Ostrzeżenie", "Token przypisany do twojego konta niedługo wygaśnie. Proszę wyloguj się i zaloguj ponownie do aplikacji."), timeout);
    }

    private stopJWTTokenTimer() {
        if (this.jwtTokenTimeout != null) {
            clearTimeout(this.jwtTokenTimeout as any);
            this.jwtTokenTimeout = null;
        }
    }

    private stopRefreshTokenTimer() {
        if (this.refreshTokenTimeout != null) {
            clearTimeout(this.refreshTokenTimeout as any);
            this.refreshTokenTimeout = null;
        }
    }

    private getUserRoles(token: string): string[] {
        var decodeToken = jwt_decode(token);
        var roles = decodeToken['rol'];
        return roles;
    }
}