import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';
import { ProtocolTypesOfConstruction, ProtocolTypesOfConstructionAdapter, ProtocolTypesOfConstructionFormManager } from './protocol-types-of-construction';
import { ProtocolBuildingEquipment, ProtocolBuildingEquipmentAdapter, ProtocolBuildingEquipmentFormManager } from './protocol-building-equipement';
import { ProtocolLodzElement, ProtocolLodzElementAdapter, ProtocolLodzElementFormManager } from './protocol-lodz-element';
import { ProtocolComplaint, ProtocolComplaintAdapter, ProtocolComplaintFormManager } from './protocol-complaint';
import { ModelFormManager } from './interfaces/model-form-manager.interface';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';

export class ProtocolExtension {
    public id: string;
    public sumOfWeights: number;
    public weightedAverage: number;
    public yearOfConstruction: string;
    public aboveGroundStoreys: string;
    public undergroundStoreys: string;
    public controlDateWithoutDay: boolean;
    public receptionDate: Date;
    public receptionDateWithoutDay: boolean;
    public creationDateWithoutDay: boolean;
    public unfinishedWorks: string;
    public unfinishedAnnualWorks: string;
    public damageAndShortages: string;
    public finalConclusions: string;

    public buildingDocumentationStatusRating: string;
    public buildingDocumentationStatusRatingNumber: number;
    public operationalDocumentationStatusRating: string;
    public operationalDocumentationStatusRatingNumber: number;
    public constructionBookStatusRating: string;
    public constructionBookStatusRatingNumber: number;

    public typesOfConstruction: ProtocolTypesOfConstruction;
    public buildingEquipment: ProtocolBuildingEquipment;

    public lodzElements: ProtocolLodzElement[];
    public complaints: ProtocolComplaint[];

    public lodzElementsWithWeight: ProtocolLodzElement[];
    public lodzElementsWithStateRatingNumber: ProtocolLodzElement[];

    constructor() { }
}

@Injectable()
export class ProtocolExtensionAdapter implements Adapter<ProtocolExtension>{
    constructor(
        private protocolTypesOfConstructionAdapter: ProtocolTypesOfConstructionAdapter,
        private protocolBuildingEquipmentAdapter: ProtocolBuildingEquipmentAdapter,
        private protocolLodzElementAdapter: ProtocolLodzElementAdapter,
        private protocolComplaintAdapter: ProtocolComplaintAdapter) { }

    adapt(item: any): ProtocolExtension {
        var element = this.adaptGeneric(item);

        var lodzElements = [];
        if (item.lodzElements) {
            item.lodzElements.forEach(element => {
                lodzElements.push(this.protocolLodzElementAdapter.adapt(element));
            });
        }
        element.lodzElements = lodzElements;

        element.lodzElementsWithWeight = element.lodzElements.filter(lElement => lElement.elementTypeNumber == 1);
        element.lodzElementsWithStateRatingNumber = element.lodzElements.filter(lElement => lElement.elementTypeNumber == 2);

        return element;
    }

    adaptBeforeSaving(item: any): ProtocolExtension {
        var element = this.adaptGeneric(item);

        var lodzElementsWithWeight = [];
        if (item.lodzElementsWithWeight) {
            item.lodzElementsWithWeight.forEach(element => {
                lodzElementsWithWeight.push(this.protocolLodzElementAdapter.adapt(element));
            });
        }
        element.lodzElementsWithWeight = lodzElementsWithWeight;

        var lodzElementsWithStateRatingNumber = [];
        if (item.lodzElementsWithStateRatingNumber) {
            item.lodzElementsWithStateRatingNumber.forEach(element => {
                lodzElementsWithStateRatingNumber.push(this.protocolLodzElementAdapter.adapt(element));
            });
        }
        element.lodzElementsWithStateRatingNumber = lodzElementsWithStateRatingNumber;

        element.lodzElements = [...element.lodzElementsWithWeight, ...element.lodzElementsWithStateRatingNumber]

        return element;
    }

    private adaptGeneric(item: any): ProtocolExtension {
        var element = new ProtocolExtension();

        element.id = item.id;
        element.sumOfWeights = item.sumOfWeights;
        element.weightedAverage = item.weightedAverage;
        element.yearOfConstruction = item.yearOfConstruction;
        element.aboveGroundStoreys = item.aboveGroundStoreys;
        element.undergroundStoreys = item.undergroundStoreys;
        element.controlDateWithoutDay = item.controlDateWithoutDay;
        element.receptionDate = item.receptionDate;
        element.receptionDateWithoutDay = item.receptionDateWithoutDay;
        element.creationDateWithoutDay = item.creationDateWithoutDay;
        element.unfinishedWorks = item.unfinishedWorks;
        element.unfinishedAnnualWorks = item.unfinishedAnnualWorks;
        element.damageAndShortages = item.damageAndShortages;
        element.finalConclusions = item.finalConclusions;

        element.buildingDocumentationStatusRating = item.buildingDocumentationStatusRating;
        element.buildingDocumentationStatusRatingNumber = item.buildingDocumentationStatusRatingNumber;
        element.operationalDocumentationStatusRating = item.operationalDocumentationStatusRating;
        element.operationalDocumentationStatusRatingNumber = item.operationalDocumentationStatusRatingNumber;
        element.constructionBookStatusRating = item.constructionBookStatusRating;
        element.constructionBookStatusRatingNumber = item.constructionBookStatusRatingNumber;

        element.typesOfConstruction = this.protocolTypesOfConstructionAdapter.adapt(item.typesOfConstruction);
        element.buildingEquipment = this.protocolBuildingEquipmentAdapter.adapt(item.buildingEquipment);

        var complaints = [];
        if (item.complaints) {
            item.complaints.forEach(element => {
                complaints.push(this.protocolComplaintAdapter.adapt(element));
            });
        }
        element.complaints = complaints;

        return element;
    }
}

@Injectable()
export class ProtocolExtensionFormManager implements ModelFormManager<ProtocolExtension>{
    constructor(
        private formBuilder: UntypedFormBuilder,
        private protocolComplaintFormManager: ProtocolComplaintFormManager,
        private protocolBuildingEquipmentFormManager: ProtocolBuildingEquipmentFormManager,
        private protocolTypesOfConstructionFormManager: ProtocolTypesOfConstructionFormManager,
        private protocolLodzElementFormManager: ProtocolLodzElementFormManager,
        private datepipe: DatePipe) { }

    private protocolExtensionForm = this.formBuilder.group({
        id: [null],
        sumOfWeights: [null],
        weightedAverage: [null],
        yearOfConstruction: [null, Validators.required],
        aboveGroundStoreys: [null, Validators.required],
        undergroundStoreys: [null, Validators.required],
        controlDateWithoutDay: [null],
        receptionDate: [this.datepipe.transform(Date.now(), 'yyyy-MM-dd')],
        receptionDateWithoutDay: [null],
        creationDateWithoutDay: [null],
        unfinishedWorks: [null],
        unfinishedAnnualWorks: [null],
        damageAndShortages: [null],
        finalConclusions: [null],
        buildingDocumentationStatusRating: [null],
        buildingDocumentationStatusRatingNumber: [null],
        operationalDocumentationStatusRating: [null],
        operationalDocumentationStatusRatingNumber: [null],
        constructionBookStatusRating: [null],
        constructionBookStatusRatingNumber: [null],
        typesOfConstruction: this.protocolTypesOfConstructionFormManager.getEmptyForm(),
        buildingEquipment: this.protocolBuildingEquipmentFormManager.getEmptyForm(),
        lodzElementsWithWeight: this.formBuilder.array([this.protocolLodzElementFormManager.getEmptyForm()]),
        lodzElementsWithStateRatingNumber: this.formBuilder.array([this.protocolLodzElementFormManager.getEmptyForm()]),
        complaints: this.formBuilder.array([this.protocolComplaintFormManager.getEmptyForm()])
    });
    
    buildForm(model: ProtocolExtension): UntypedFormGroup {
        if (!model)
        {
            var lodzElementsWithWeight = ((this.protocolExtensionForm.get('lodzElementsWithWeight') as UntypedFormArray).controls[0] as UntypedFormGroup);
            if (lodzElementsWithWeight != null)
            {
                lodzElementsWithWeight.get('durability').setValue(0);
                lodzElementsWithWeight.get('elementCategory').setValue("-");
                lodzElementsWithWeight.get('elementName').setValue("-");
                lodzElementsWithWeight.get('elementWeight').setValue(1);

                var elementPhotos = ((lodzElementsWithWeight.get('elementPhotos')) as UntypedFormArray);
                if (elementPhotos != null)
                    elementPhotos.removeAt(0);
            }
            
            var lodzElementsWithStateRatingNumber = ((this.protocolExtensionForm.get('lodzElementsWithStateRatingNumber') as UntypedFormArray).controls[0] as UntypedFormGroup);
            if (lodzElementsWithStateRatingNumber != null)
            {
                lodzElementsWithStateRatingNumber.get('durability').setValue(0);
                lodzElementsWithStateRatingNumber.get('elementCategory').setValue("-");
                lodzElementsWithStateRatingNumber.get('elementName').setValue("-");
                lodzElementsWithStateRatingNumber.get('elementWeight').setValue(1);

                var elementPhotos = ((lodzElementsWithStateRatingNumber.get('elementPhotos')) as UntypedFormArray);
                if (elementPhotos != null)
                    elementPhotos.removeAt(0);
            }
        
            this.protocolExtensionForm.get('finalConclusions').setValue("Wykonywać remonty według stopnia pilności");
            this.protocolExtensionForm.get('damageAndShortages').setValue("Nie stwierdzono uszkodzeń oraz braków, które mogłyby spowodować zagrożenie życia lub zdrowia ludzi, bezpieczeństwa mienia bądź środowiska, a w szczególności katastrofę budowlaną, wymagające usunięcia w czasie lub bezpośrednio po przeprowadzonej kontroli");

            this.protocolExtensionForm.get('aboveGroundStoreys').setValue("-");
            this.protocolExtensionForm.get('undergroundStoreys').setValue("-");
            this.protocolExtensionForm.get('yearOfConstruction').setValue("-");

            return this.protocolExtensionForm;
        }

        if (!model.finalConclusions)
        {
            model.finalConclusions = "Wykonywać remonty według stopnia pilności";
        }

        if (!model.damageAndShortages)
        {
            model.damageAndShortages = "Nie stwierdzono uszkodzeń oraz braków, które mogłyby spowodować zagrożenie życia lub zdrowia ludzi, bezpieczeństwa mienia bądź środowiska, a w szczególności katastrofę budowlaną, wymagające usunięcia w czasie lub bezpośrednio po przeprowadzonej kontroli";
        }
        
        this.protocolExtensionForm = this.formBuilder.group({
            id: [model.id],
            sumOfWeights: [model.sumOfWeights],
            weightedAverage: [model.weightedAverage],
            yearOfConstruction: [model.yearOfConstruction, Validators.required],
            aboveGroundStoreys: [model.aboveGroundStoreys, Validators.required],
            undergroundStoreys: [model.undergroundStoreys, Validators.required],
            controlDateWithoutDay: [model.controlDateWithoutDay],
            receptionDate: [model.receptionDate ? this.datepipe.transform(model.receptionDate, 'yyyy-MM-dd') : this.datepipe.transform(Date.now(), 'yyyy-MM-dd')],
            receptionDateWithoutDay: [model.receptionDateWithoutDay],
            creationDateWithoutDay: [model.creationDateWithoutDay],
            unfinishedWorks: [model.unfinishedWorks],
            unfinishedAnnualWorks: [model.unfinishedAnnualWorks],
            damageAndShortages: [model.damageAndShortages],
            finalConclusions: [model.finalConclusions],
            buildingDocumentationStatusRating: [model.buildingDocumentationStatusRating],
            buildingDocumentationStatusRatingNumber: [model.buildingDocumentationStatusRatingNumber],
            operationalDocumentationStatusRating: [model.operationalDocumentationStatusRating],
            operationalDocumentationStatusRatingNumber: [model.operationalDocumentationStatusRatingNumber],
            constructionBookStatusRating: [model.constructionBookStatusRating],
            constructionBookStatusRatingNumber: [model.constructionBookStatusRatingNumber],
            typesOfConstruction: this.protocolTypesOfConstructionFormManager.buildForm(model.typesOfConstruction),
            buildingEquipment: this.protocolBuildingEquipmentFormManager.buildForm(model.buildingEquipment),
            lodzElementsWithWeight: this.formBuilder.array([this.protocolLodzElementFormManager.getEmptyForm()]),
            lodzElementsWithStateRatingNumber: this.formBuilder.array([this.protocolLodzElementFormManager.getEmptyForm()]),
            complaints: this.formBuilder.array([this.protocolComplaintFormManager.getEmptyForm()])
        });

        if (model.lodzElementsWithWeight.length > 0)
        {
            (this.protocolExtensionForm.get('lodzElementsWithWeight') as UntypedFormArray).removeAt(0);
        }
        model.lodzElementsWithWeight.forEach(elementLodz => {
            (this.protocolExtensionForm.get('lodzElementsWithWeight') as UntypedFormArray).push(this.protocolLodzElementFormManager.buildForm(elementLodz));
        });

        if (model.lodzElementsWithStateRatingNumber.length > 0)
        {
            (this.protocolExtensionForm.get('lodzElementsWithStateRatingNumber') as UntypedFormArray).removeAt(0);
        }
        model.lodzElementsWithStateRatingNumber.forEach(elementLodz => {
            (this.protocolExtensionForm.get('lodzElementsWithStateRatingNumber') as UntypedFormArray).push(this.protocolLodzElementFormManager.buildForm(elementLodz));
        });

        if (model.complaints.length > 0)
        {
            (this.protocolExtensionForm.get('complaints') as UntypedFormArray).removeAt(0);
        }
        model.complaints.forEach(protocolComplaint => {
            (this.protocolExtensionForm.get('complaints') as UntypedFormArray).push(this.protocolComplaintFormManager.buildForm(protocolComplaint));
        });

        return this.protocolExtensionForm;
    }

    getEmptyForm(): UntypedFormGroup {
        return this.protocolExtensionForm;
    }

}