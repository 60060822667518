import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalNotificationService } from 'src/app/services/global-notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  returnUrl: string;

  submitted = false;
  loginInProgress = false;
  error = "";

  public get loginData() {
    return this.loginForm.controls;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: GlobalNotificationService) {
      if (this.authenticationService.userValue) {
        this.router.navigate(['/']);
      }
     }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onLogin() {
    this.submitted = true;
    this.loginInProgress = true;

    if (this.loginForm.invalid) {
      this.loginInProgress = false;
      return;
    }

    this.notificationService.showInfoNotificationBasedOnMessage("Sprawdzam dane logowania")
    this.authenticationService.login(this.loginData.username.value, this.loginData.password.value)
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.notificationService.showSuccessNotification('Zalogowano poprawnie');
          this.router.navigate([this.returnUrl])
        }
        else {
          this.loginInProgress = false;
          this.notificationService.showErrorNotification(data.message);
        }
      },
        error => {
          this.loginInProgress = false;
          this.notificationService.showErrorNotificationBasedOnErrorObject(error);
        })
  }
}
