import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class UserProfile {
    public userRoles: string[];
    public nameAndSurname: string;
    public hasPicture: boolean;
    public initials: string;

    constructor(
        public userName: string,
        public firstName: string,
        public lastName: string,
        public email: string,
        public licenseNumbers: string,
        public membershipNumbers: string,
        public signatureUrl: string,
        public signatureName: string,
        public jwtToken: string,
        public pictureUrl?: string        
    ) {
        this.hasPicture = pictureUrl != null && pictureUrl != "";
        this.initials = `${firstName[0]}${lastName[0]}`;
        this.nameAndSurname = `${firstName} ${lastName}`;
    }
}

@Injectable()
export class UserProfileAdapter implements Adapter<UserProfile> {
    adapt(item: any): UserProfile {
        if (!item)
            return null;

        return new UserProfile(
            item.userName,
            item.firstName,
            item.lastName,
            item.email,
            item.licenseNumbers,
            item.membershipNumbers,
            item.signatureUrl,
            item.signatureName,
            item.jwtToken,
            item.pictureUrl            
        );
    }
}