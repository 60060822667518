<div class="card bg-light">
  <div class="card-body">
    <h1>Edytowanie profilu</h1>
    <form #newUserForm="ngForm">

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="userName">Login</label>
            <input type="text" class="form-control" id="userName" pattern="[A-Za-z0-9\\_\@\-\.\+]{1,20}" required
              [(ngModel)]="newUserModel.userName" name="userName" #userName="ngModel">
            <div [hidden]="userName.valid || userName.pristine" class="alert alert-danger">
              Login jest wymagany.<br />
              Dozwolone znaki: a-z A-Z 0-9 -._&#64;+<br />
              Max długość loginu to 20 znaków.<br />
              Używanie spacji jest zabronione.
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" required [(ngModel)]="newUserModel.email" name="email"
              #email="ngModel" ngModel email>
            <div [hidden]="email.valid || email.pristine || !email.errors.required" class="alert alert-danger">
              Email jest wymagany
            </div>

            <div [hidden]="email.valid || email.pristine || email.errors.required" class="alert alert-danger">
              Email jest błędny
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="firstName">Imię</label>
            <input type="text" class="form-control" id="firstName" required [(ngModel)]="newUserModel.firstName"
              name="firstName" #firstName="ngModel">
            <div [hidden]="firstName.valid || firstName.pristine" class="alert alert-danger">
              Imię jest wymagane
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="lastName">Nazwisko</label>
            <input type="text" class="form-control" id="lastName" required [(ngModel)]="newUserModel.lastName"
              name="lastName" #lastName="ngModel">
            <div [hidden]="lastName.valid || lastName.pristine" class="alert alert-danger">
              Nazwisko jest wymagane
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="licenseNumbers">Numery pozwoleń (każdy numer powinien zostać oddzielony enterem)</label>
            <textarea rows="3" type="text" class="form-control" id="licenseNumbers"
              [(ngModel)]="newUserModel.licenseNumbers" name="licenseNumbers" #licenseNumbers="ngModel"></textarea>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="pictureUrl">Adres URL do zdjęcia profilowego</label>
            <input type="text" class="form-control" id="pictureUrl" [(ngModel)]="newUserModel.pictureUrl"
              name="pictureUrl" #pictureUrl="ngModel">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="membershipNumbers">Numer członkowski POIIB</label>
            <textarea rows="1" type="text" class="form-control" id="membershipNumbers"
              [(ngModel)]="newUserModel.membershipNumbers" name="membershipNumbers"
              #membershipNumbers="ngModel"></textarea>
          </div>
        </div>
      </div>

      <div *ngIf="canSetAdditionalFields()">
        <ejs-accordion>
          <e-accordionitems>
            <e-accordionitem>
              <ng-template #header>
                <div>Podpis i uprawnienia (budowlane oraz gazowe)</div>
              </ng-template>
              <ng-template #content>

                <div class="row">
                  <div class="col-4">
        
                    <div tabindex="0" class="e-card" id="basic">
                      <div class="e-card-header">
                        <div class="e-card-header-caption">
                          <div class="e-card-title text-center">
                            @if(signatureLinkIsSet)
                            {
                            <h3>Podgląd podpisu</h3>
                            }
                            @else {
                            <h3> Brak podpisu.</h3>
                            }
                          </div>
                        </div>
                      </div>
                      @if(!signatureLinkIsSet)
                      {
                      <div class="e-card-content">
                        <div class="row">
                          <div class="col text-center">
                            <input type="file" #file placeholder="Choose file" (change)="onSignatureSet(file.files)"
                              style="display: none;" multiple accept="image/*" />
                            <button type="button" class="btn btn-success" (click)="file.click()">
                              <i class="far fa-folder-open"></i>
                              Dodaj podpis
                            </button>
                          </div>
                        </div>
                      </div>
                      }
                      @else {
                      <div class="e-card-content">
                        <div class="row">
                          <div class="col text-center">
                            <img src='{{newUserModel.signatureUrl}}' style="width: 100%; height: auto; object-fit: contain;">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            <button type="button" class="btn btn-danger" (click)="onSignatureRemove()">
                              <i class="fas fa-trash-alt"></i>
                              Usuń podpis
                            </button>
                          </div>
                        </div>
                      </div>
                      }
                    </div>
                  </div>
        
                  @if(dataLoaded)
                  {
                  <div class="col-4">
                    <app-construction-license [title]="titleForBuildingLicense" [userId]="newUserModel.userId"
                      [type]="buildingType" [editFromProfile]="editFromProfile"></app-construction-license>
                  </div>
                  <div class="col-4">
                    <app-construction-license [title]="titleForGasLicense" [userId]="newUserModel.userId"
                      [type]="gasType" [editFromProfile]="editFromProfile"></app-construction-license>
                  </div>
                  }
                </div>

              </ng-template>
            </e-accordionitem>
          </e-accordionitems>
        </ejs-accordion>
        <br>
      </div>

      <div class="row">
        <div class="col-auto">
          <button class="btn btn-success" [disabled]="submitInProgress || !newUserForm.form.valid"
            (click)="onEditProfile()">Zapisz zmiany</button>
        </div>
        <div class="col-auto">
          <button class="btn btn-warning" [disabled]="submitInProgress" (click)="onChangePassword()">Zmień
            hasło</button>
        </div>
        <div class="col-auto">
          <a [hidden]="submitInProgress" routerLink="/zadania" class="float-right"><button
              class="btn btn-primary">Wróć</button></a>
        </div>
      </div>
    </form>
  </div>
</div>