import { Adapter } from './interfaces/adapter.interface';
import { Injectable } from '@angular/core';

export class ProtocolDocumentationControlElement {
    public id: string;
    public elementType: string;
    public elementRating: string;
    public creationDate: Date;
    public expirationDate: Date;
    public remarksAndObservations: string;

    constructor() { }
}

@Injectable()
export class ProtocolDocumentationControlElementAdapter implements Adapter<ProtocolDocumentationControlElement>{
    adapt(item: any): ProtocolDocumentationControlElement {
        var element = new ProtocolDocumentationControlElement();

        element.id = item.id;
        element.elementType = item.elementType;
        element.elementRating = item.elementRating;
        element.creationDate = item.creationDate;
        element.expirationDate = item.expirationDate;
        element.remarksAndObservations = item.remarksAndObservations;

        return element;
    }
}