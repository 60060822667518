import { Injectable } from '@angular/core';
import { Adapter } from './interfaces/adapter.interface';

export class UserDetails {

    public hasPicture: boolean;
    public initials: string;
    public nameAndSurname: string;
    public roles: string;

    constructor(
        public id: string,
        public userName: string,
        public firstName: string,
        public lastName: string,
        public email: string,
        public role: string,
        public membershipNumbers: string,
        public licenseNumbers: string,
        public pictureUrl?: string,
        public isActive?: boolean
    ) {
        this.hasPicture = pictureUrl != null && pictureUrl != "";
        this.initials = `${firstName[0]}${lastName[0]}`;
        this.nameAndSurname = `${firstName} ${lastName}`;
        this.roles = role;
    }
}

@Injectable()
export class UserDetailsAdapter implements Adapter<UserDetails> {
    adapt(item: any): UserDetails {
        if (!item)
            return;
        return new UserDetails(
            item.id,
            item.userName,
            item.firstName,
            item.lastName,
            item.email,
            item.role,
            item.membershipNumbers,
            item.licenseNumbers,
            item.pictureUrl,
            item.isActive
        )
    }
}